import React from 'react';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { getFirstUppercase } from '../../../js/PrintUtility';
import { IconChoosePrint } from './IconChoosePrint.js';
import { useTranslation } from 'react-i18next';
import { PrintStepHeader } from './PrintStepHeader.js';


export const PrintCancelPolicy = ({ cancelPolicyArr, cultureName, onHandleIsPrint }) => {
    const { t } = useTranslation();
    
    return (
        <>
            {cancelPolicyArr && cancelPolicyArr.length > 0 &&
             <>
                <PrintStepHeader
                    title={t(`Template:CancelPolicyDetailPenalties`)}
                    PNR=''
                    totaleViaggio=''
                    iconName=''
                    cultureName={cultureName}
                    onHandleIsPrint={onHandleIsPrint}
                />

                <div className="p-2 border shadow">
                    <table className="w-100">
                        <thead className="h6">
                            <tr className="text-right h6 customBackground" >
                                <td className="text-left">
                                    {t(`CancelPolicy:Service`)}
                                </td>
                                <td className="text-left w-20">
                                    {t(`CancelPolicy:Date`)}
                                </td>
                                <td className="text-right w-20">
                                    {t(`CancelPolicy:Percentage`)}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {cancelPolicyArr.map((policy, index) =>
                                policy.cancelPolicy.map((cancPolicy, idx) =>
                                    <tr className={idx === 0 ? "bg-gray-200" : ""}  key={idx}>
                                        <td className="">
                                            {idx === 0 ? getFirstUppercase(policy.name) : ""}
                                        </td>
                                        <td className="text-left w-20">
                                            {formatDateTime(cancPolicy.date, cultureName)}
                                        </td>
                                        <td className="text-right w-20">
                                            {cancPolicy.percent}%
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            </>
            }
        </>
    );
}