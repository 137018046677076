export const manualRangeParameter = {
    hotel: {
        defaultAdults: 2,
        defaultChildren: 0,
        minRoom: 1,
        maxRoom: 5,
        minAdults: 1,
        maxAdults: 1000,
        minChildren: 0,
        maxChildren: 1000,
        minChildAge: 0,
        maxChildAge: 17
    },
    activity: {
        defaultAdults: 2,
        defaultChildren: 0,
        minAdults: 1,
        maxAdults: 1000,
        minChildren: 0,
        maxChildren: 1000,
        minChildAge: 0,
        maxChildAge: 17
    },
    transfer: {
        defaultAdults: 2,
        defaultChildren: 0,
        minAdults: 1,
        maxAdults: 1000,
        minChildren: 0,
        maxChildren: 1000,
        minChildAge: 0,
        maxChildAge: 17
    },
    cruise: {
        maxPax: 1000
    }
}

export const searchRangeParameter = {
    activity: {
        defaultAdults: 2,
        defaultChildren: 0,
        minAdults: 1,
        maxAdults: 9,
        minChildren: 0,
        maxChildren: 4,
        minChildAge: 0,
        maxChildAge: 17,
        defaultChildAge: 7,
        totalPaxes: 15
    },
    cruise: {
        defaultAdults: 2,
        defaultChildren: 0,
        minAdults: 1,
        maxAdults: 9,
        minChildren: 0,
        maxChildren: 4,
        minChildAge: 0,
        maxChildAge: 17,
        defaultChildAge: 7,
        totalPaxes: 15
    },
    flight: {
        defaultAdults: 2,
        defaultChildren: 0,
        minAdults: 1,
        maxAdults: 9,
        minChildren: 0,
        maxChildren: 4,
        minChildAge: 2,
        maxChildAge: 13,
        defaultChildAge: 7,
        minInfant: 0,
        maxInfant: 1,
        defaultChildInfantAge: 1,
        minInfantAge: 0,
        maxInfantAge: 1,
        totalPaxes: 15
    },
    hotel: {
        defaultAdults: 2,
        defaultChildren: 0,
        maxRoom: 5,
        minAdults: 1,
        maxAdults: 9,
        minChildren: 0,
        maxChildren: 4,
        minChildAge: 0,
        maxChildAge: 17,
        defaultChildAge: 7,
        totalPaxes: 15
    },
    train: {
        defaultAdults: 2,
        defaultChildren: 0,
        minAdults: 1,
        maxAdults: 9,
        minChildren: 0,
        maxChildren: 4,
        minChildAge: 0,
        maxChildAge: 17,
        defaultChildAge: 7,
        totalPaxes: 9
    },
    transfer: {
        defaultAdults: 2,
        defaultChildren: 0,
        minAdults: 1,
        maxAdults: 9,
        minChildren: 0,
        maxChildren: 4,
        minChildAge: 0,
        maxChildAge: 17,
        defaultChildAge: 7,
        totalPaxes: 15
    },
    tour: {
        defaultAdults: 2,
        defaultChildren: 0,
        maxRoom: 5,
        minAdults: 1,
        maxAdults: 9,
        minChildren: 0,
        maxChildren: 4,
        minChildAge: 0,
        maxChildAge: 17,
        defaultChildAge: 7,
        totalPaxes: 15
    }
}

export const searchCarpetParameter = {
    activity: {
        carpetPingMs: 5000,
        carpetMaxTimeMs: 90000,
        listMaxItem: 12,
        detailMaxItem: 10,
        orderBy: 4,
        orderByValues: [
            { value: 1, label: 'General:Order:PriceLower' },
            { value: 0, label: 'General:Order:PriceHigher' },
            { value: 2, label: 'General:Order:NameAZ' }
        ]
    },
    cruise: {
        carpetPingMs: 2000,
        carpetMaxTimeMs: 90000,
        listMaxItem: 12,
        detailMaxItem: 10,
        orderBy: 4,
        orderByValues: [
            { value: 0, label: 'General:Order:PriceLower', descendingValue: null },
            { value: 1, label: 'General:Order:PriceHigher', descendingValue: null },
            { value: 4, label: 'Template:DepartureDate', descendingValue: null }
        ]
    },
    flight: {
        carpetPingMs: 5000,
        carpetMaxTimeMs: 90000,
        listMaxItem: 12,
        detailMaxItem: 10,
        orderBy: 0,
        orderByValues: [
            { value: 0, label: 'General:Order:PriceLower', descendingValue: null },
            { value: 1, label: 'General:Order:PriceHigher', descendingValue: null },
            { value: 2, label: 'General:Order:DurationLower', descendingValue: null },
            { value: 3, label: 'General:Order:DurationHigher', descendingValue: null },
            { value: 4, label: 'General:Order:NumStopsLower', descendingValue: null },
            { value: 5, label: 'General:Order:NumStopsHigher', descendingValue: null },
        ]
    },
    hotel: {
        carpetPingMs: 5000,
        carpetMaxTimeMs: 90000,
        listMaxItem: 12,
        detaMaxilItem: 100,
        orderBy: 6,
        orderByValues: [
            { value: 0, label: 'General:Order:PriceLower', descendingValue: null },
            { value: 1, label: 'General:Order:PriceHigher', descendingValue: null },
            { value: 6, label: 'General:Order:Favourite', descendingValue: null },
            { value: 2, label: 'General:Order:NameAZ', descendingValue: 3 },
            { value: 8, label: 'General:Order:Distance', descendingValue: 4 },
        ]
    },
    tour: {
        carpetPingMs: 5000,
        carpetMaxTimeMs: 90000,
        listMaxItem: 12,
        detailMaxItem: 10,
        orderBy: 0,
        orderByValues: [
            { value: 0, label: 'General:Order:PriceLower' },
            { value: 1, label: 'General:Order:PriceHigher' },
            { value: 2, label: 'General:Order:NameAZ', descendingValue: 3 }
        ]
    },
    train: {
        carpetPingMs: 5000,
        carpetMaxTimeMs: 90000,
        listMaxItem: 12,
        detailMaxItem: 10,
        orderBy: 4,
        orderByValues: [
            { value: 0, label: 'General:Order:PriceLower', descendingValue: null },
            { value: 1, label: 'General:Order:PriceHigher', descendingValue: null },
            { value: 2, label: 'General:Order:DurationLower', descendingValue: null },
            { value: 4, label: 'General:Order:DepartureTimeLower', descendingValue: null }
        ]
    },
    transfer: {
        carpetPingMs: 5000,
        carpetMaxTimeMs: 90000,
        listMaxItem: 12,
        detailMaxItem: 10,
        orderBy: 0,
        orderByValues: [
            { value: 0, label: 'General:Order:PriceLower' },
            { value: 1, label: 'General:Order:PriceHigher' },
            { value: 2, label: 'General:Order:NameAZ' }
        ]
    },
    car: {
        carpetPingMs: 5000,
        carpetMaxTimeMs: 90000,
        listMaxItem: 12,
        detaMaxilItem: 10,
        orderBy: 0,
        orderByValues: [
            { value: 0, label: 'General:Order:PriceLower', descendingValue: null },
            { value: 1, label: 'General:Order:PriceHigher', descendingValue: null },
            { value: 2, label: 'General:Order:ClassLower', descendingValue: null },
            { value: 3, label: 'General:Order:ClassHigher', descendingValue: null },
            { value: 4, label: 'General:Order:CategoryLower', descendingValue: null },
            { value: 5, label: 'General:Order:CategoryHigher', descendingValue: null },
            { value: 6, label: 'General:Order:Relevance', descendingValue: null },
        ]
    }
}

export const renderWebsite = {
    cancelPolicy: {
        time: '17:00'
    }
}

export const BookParameterId = {
    Personal: {
        FirstName: "GST-01",
        MiddleName: "GST-02",
        LastName: "GST-03",
        BirthDate: "GST-04",
        Gender: "GST-05",
        Citizenship: "GST-06",
        Title: "GST-07",
        FiscalCode: "GST-36",
    },
    Contact: {
        HomePhone: "GST-10",
        MobilePhone: "GST-11",
        WorkPhone: "GST-12",
        Fax: "GST-13",
        Email: "GST-14",
    },
    Address: {
        Address1: "GST-21",
        Address2: "GST-22",
        Zip: "GST-23",
        City: "GST-24",
        Province: "GST-25",
        Country: "GST-26",
    },
    Document: {
        Number: "GST-30",
        ExpirationDate: "GST-31",
        CountryOfIssue: "GST-32",
        Type: "GST-33",
    }
}

export const BookParameterMandatoryCssClass = {
    Mandatory: "bg-gray-200 h075 p-1",
    NotMandatory: "h075"
}

export const staticBookParams = [
    { Id: BookParameterId.Personal.FirstName, OnlyFirstPax: false },
    { Id: BookParameterId.Personal.MiddleName, OnlyFirstPax: false },
    { Id: BookParameterId.Personal.LastName, OnlyFirstPax: false },
    { Id: BookParameterId.Personal.BirthDate, OnlyFirstPax: false },
    { Id: BookParameterId.Personal.Gender, OnlyFirstPax: false },
    { Id: BookParameterId.Personal.Citizenship, OnlyFirstPax: false },
    { Id: BookParameterId.Contact.HomePhone, OnlyFirstPax: true },
    { Id: BookParameterId.Contact.Email, OnlyFirstPax: true },
    { Id: BookParameterId.Address.Address1, OnlyFirstPax: false },
    { Id: BookParameterId.Address.Zip, OnlyFirstPax: false },
    { Id: BookParameterId.Address.City, OnlyFirstPax: false },
    { Id: BookParameterId.Document.Number, OnlyFirstPax: false },
    { Id: BookParameterId.Document.Type, OnlyFirstPax: false },
    { Id: BookParameterId.Personal.FiscalCode, OnlyFirstPax: false }
];

export const staticMandatoryBookParams = [
    { Id: BookParameterId.Personal.FirstName, PaxIndexes: [], OnlyFirstPax: false },
    { Id: BookParameterId.Personal.LastName, PaxIndexes: [], OnlyFirstPax: false },
    { Id: BookParameterId.Contact.HomePhone, PaxIndexes: [1], OnlyFirstPax: true },
    { Id: BookParameterId.Contact.Email, PaxIndexes: [1], OnlyFirstPax: true }
];
