import React, { useState, useEffect } from 'react';
import configData from "../../../appsettings.json";
import { getWebsiteId } from '../../../js/Utils.js';

export const ProductHeader = ({ text, imageUrl, pageCode, height }) => {
    const [dynamicImage, setDynamicImage] = useState('');
    const [gallery, setGallery] = useState([]);
    const [isVideo, setIsVideo] = useState(false);
    const [imageTitle, setImageTitle] = useState('');
    const [imageDescription, setImageDescription] = useState('');

    const galleryTimeout = 5000;

    useEffect(() => {
        if (pageCode) {
            async function getData() {
                const response = await fetch(configData.Settings.CommonApi_BaseUrl + `webcontent/getphotogallery/${pageCode}/` + getWebsiteId()).then(data => data.json());
                if (response) {

                    if (response.length === 1) {
                        setDynamicImage(response[0].url);

                        let isVideoUrl = response[0].url.includes('.mp4');
                        setIsVideo(isVideoUrl);

                        if (!text) {
                            setImageTitle(response[0].imageTitle);
                            setImageDescription(response[0].imageDescription);
                        }

                    } else {
                        let images = response.map(x => x.url);
                        setGallery(images);
                        setIsVideo(false);

                        // Aspetta 7 secondi e simula il click sul pulsante "Next"
                        setTimeout(() => {
                            try {
                                const carousel = document.getElementById('carousel-control-next');
                                carousel.click();
                            } catch (ex) { console.log(ex) }

                        }, galleryTimeout);
                    }

                }
            }
            getData();
        } else {
            setDynamicImage(imageUrl);
        }


    }, [])

    return (
        <>
            {dynamicImage &&
                <>
                    {
                        !isVideo ? <div className="d-none d-sm-block" style={{ backgroundImage: `url("${dynamicImage}")`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: (height ? height : "400px") }}>
                            <div className="w-100 text-center">{text}</div>

                            {
                                (imageTitle || imageDescription) && <div className="container h-100">
                                    <div className="row text-center h-100" style={{ alignItems: 'center' }}>
                                        <div className="col-12">
                                            {imageTitle && <div className="h2 text-white w-100 text-center" dangerouslySetInnerHTML={{ __html: imageTitle }}></div>}
                                            {imageDescription && <div className="h5 text-white w-100 text-center" dangerouslySetInnerHTML={{ __html: imageDescription }}></div>}
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                            : <div className="d-none d-sm-block">
                                <video id="videoBG" className="video-home" autoPlay={true} muted loop style={{ height: (height ? height : "400px"), overflow: 'hidden' }}>
                                    <source src={dynamicImage} type="video/mp4" />
                                </video>
                            </div>
                    }
                </>
            }
            {
                gallery && gallery.length > 0 ? <div className="d-none d-sm-block">
                    <div id="carouselProductHeader" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            {
                                gallery.map((img, key) => (
                                    <div key={key} className={`carousel-item ${key === 0 ? 'active' : ''}`} data-bs-interval="false" style={{ height: height ? height : '400px' }}>
                                        <img className="d-block w-100" src={img} alt={`Slide ${key + 1}`} />
                                    </div>
                                ))
                            }
                        </div>
                        <button className="carousel-control-prev  visually-hidden bg-primary" type="button" data-bs-target="#carouselProductHeader" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Precedente</span>
                        </button>
                        <button id="carousel-control-next" className="carousel-control-next  bg-primary visually-hidden" type="button" data-bs-target="#carouselProductHeader" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Successivo</span>
                        </button>
                    </div>
                </div> : <></>
            }
        </>
    );
}