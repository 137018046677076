import ReactDOMServer from "react-dom/server";
import { generateComponentInBody, replaceAllStyleElements, dynamicNotesDistincts } from "../../../js/PrintUtility";
import { formatPrice } from '../../../js/Utils.js';
import { PrintStepHeader } from "../components/PrintStepHeader";
import { PrintStepItem } from "../components/PrintStepItem";
import { TourStaticPrint } from "../components/TourStaticPrint";
import { PrintAncillaries } from "../components/PrintAncillaries";
import { PrintCommissionsDetail } from "../components/PrintCommissionsDetail";
import { PrintCommissionsDetailCompact } from "../components/PrintCommissionsDetailCompact";
import { PrintPaxes } from "../components/PrintPaxes";
import { PrintDynamicNotes } from "../components/PrintDynamicNotes";
import { PrintTitle } from "../components/PrintTitle";
import { TemplatePrintInclusion } from "../template/TemplatePrintInclusion";
import { TemplatePrintStepHeader } from "../template/TemplatePrintStepHeader";
import { PrintItineraryResume } from "../components/PrintItineraryResume";
import { TemplatePrintImageHeader } from "../template/TemplatePrintImageHeader";
import { TemplatePrintHeaderDescription } from "../template/TemplatePrintHeaderDescription";
import { PrintProductsNotes } from "../components/PrintProductsNotes";
import { PrintTitleSeparator } from "../components/PrintTitleSeparator";
import { PrintScadenziario } from "../components/PrintScadenziario";
import { PrintCancelPolicy } from "../components/PrintCancelPolicy";


export function generateQuotationHTMLConfirm(itemStepPrint, bookDate, t, paxes, dynamicNotes, confirmMarginItems, template, scadenziario, cancelPolicyArr, noteProdottoBigArr, taxesFlightArr, cultureName, isPrintCliente = false,
    isPrintHeaderDesc,
    isPrintItineraryResume,
    isPrintPaxes,
    isPrintAncillaries,
    isPrintCommissions,
    isPrintInclusions,
    isPrintScadenziario,
    isPrintCancelPolicy,
    isPrintNoteBig
) {
    let myArr = [];

    let rowTitle = ReactDOMServer.renderToString(<PrintTitle createdDate={bookDate} isPrintCliente={isPrintCliente} title={t("Print:ConfirmBookingTitle")} titleCliente={t("Print:ConfirmBookingClientTitle")} />);

    myArr.push({ html: generateComponentInBody(rowTitle), break: false, wrap: true });

    if (template && template.photoGallery && template.photoGallery.items && template.photoGallery.items[0]) {
        let rowImageHeader = ReactDOMServer.renderToString(<TemplatePrintImageHeader
            image={template.photoGallery.items[0].url}
        />);

        //myArr.push({ html: generateComponentInBody(rowImageHeader), break: false, wrap: true });
    }

    if (template) {
        let rTitleDescription = ReactDOMServer.renderToString(<TemplatePrintHeaderDescription
            title={template.name}
            description={template.description}
        />);

        myArr.push({ html: generateComponentInBody(rTitleDescription), break: false, wrap: true });
    }


    /* passeggeri */
    if (isPrintPaxes && paxes && paxes.length > 0) {
        let rPaxes = ReactDOMServer.renderToString(<PrintPaxes paxes={paxes} isConfirmPrint={true} />);

        myArr.push({ html: generateComponentInBody(rPaxes), break: false, wrap: true });
    }
    /* itinerario */
    if (isPrintItineraryResume) {
        let itineraryResume_ = ReactDOMServer.renderToString(<PrintItineraryResume
            paxes={paxes}
            isConfirmPrint={true}
            isNotStep={template ? false : true}
        />);

        myArr.push({ html: generateComponentInBody(itineraryResume_), break: false, wrap: true });
    }
    
    var finalArr = itemStepPrint;

    /* template */
    if (template && template.steps && template.steps.length > 0 && template.steps.length > 0) {
        template.steps.forEach(function (step, stepKey) {
            let rowTemplateStepHeader = ReactDOMServer.renderToString(<TemplatePrintStepHeader
                key={stepKey}
                title={step.webDestinationName}
                isStep={true}
                stepIndex={step.step}
                itemSteps={finalArr}
            />);

            myArr.push({ html: generateComponentInBody(rowTemplateStepHeader), break: false, wrap: true });
           
            finalArr.filter(x => x.productInfo.stepId === step.step).map((item, itemKey) => {
                let qInfo = item;
                
                let rowQInfo = ReactDOMServer.renderToString(<PrintStepItem
                    qInfo={qInfo}
                    isPrintCliente={isPrintCliente}
                    isTemplate={true}
                    isPrintPdf={false}
                    isNotStep={template ? false : true}
                />);

                myArr.push({ html: generateComponentInBody(rowQInfo), break: false, wrap: true });
            })

            let rowBorderBottom = ReactDOMServer.renderToString(<div className="borderBottomColorCustom"></div>);
            myArr.push({ html: generateComponentInBody(rowBorderBottom), break: false, wrap: true });
        });
    }

    if (!template) {
        /* Elenco servizi */
        finalArr.filter(x => x.productType !== "Generic").map((qInfo, itemKey) => {
       
            let rowQInfo = ReactDOMServer.renderToString(<PrintStepItem
                qInfo={qInfo}
                isPrintCliente={isPrintCliente}
            />);

            myArr.push({ html: generateComponentInBody(rowQInfo), break: false, wrap: true });
        })

        let rowBorderBottom = ReactDOMServer.renderToString(<div className="borderBottomColorCustom"></div>);
        myArr.push({ html: generateComponentInBody(rowBorderBottom), break: false, wrap: true });
    }

    let rowTitleSeparator = ReactDOMServer.renderToString(<PrintTitleSeparator
        title={t(`Template:Quotation`)}
    />);

    myArr.push({ html: generateComponentInBody(rowTitleSeparator), break: true, wrap: true });

    /* Quote accessorie */
    if (isPrintAncillaries) {
        let rAncillaries = ReactDOMServer.renderToString(<PrintAncillaries
            recapItems={itemStepPrint}
            isConfirmPrint={true}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rAncillaries), break: false, wrap: false });
    }
    
    /* Totale viaggio - dettaglio commissioni */
    if (isPrintCommissions) {
        let rowCommDetail = ReactDOMServer.renderToString(<PrintCommissionsDetail
            marginItems={confirmMarginItems}
            taxesFlightArr={taxesFlightArr}
            cultureName={cultureName}
            paxes={paxes}
            isConfirmPrint={true}
            isPrintCliente={isPrintCliente}
        />);

        myArr.push({ html: generateComponentInBody(rowCommDetail), break: false, wrap: false });
    }

    /* scadenziario */
    if (isPrintScadenziario) {
        let rowScadenziario = ReactDOMServer.renderToString(<PrintScadenziario
            scadenziario={scadenziario}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowScadenziario), break: false, wrap: false });
    }

    /* Cancel Policy */
    if (isPrintCancelPolicy && !isPrintCliente) {
        let rowCancelPolicy = ReactDOMServer.renderToString(<PrintCancelPolicy
            cancelPolicyArr={cancelPolicyArr}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowCancelPolicy), break: false, wrap: false });
    }

    /* Note dinamiche */
    if (dynamicNotes && dynamicNotes.length > 0) {
        let notePagamenti = dynamicNotesDistincts(dynamicNotes).filter(x => x.categoryName === "Pagamenti");
        if (notePagamenti) {
            let rowDynamicNotes = ReactDOMServer.renderToString(<PrintDynamicNotes
                notes={[notePagamenti]}
                cultureName={cultureName}
            />);

            myArr.push({ html: generateComponentInBody(rowDynamicNotes), break: false, wrap: true });
        }

        myArr.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });


        let note = dynamicNotesDistincts(dynamicNotes).filter(x => x.categoryName !== "Pagamenti");
        let rowDynamicNotes = ReactDOMServer.renderToString(<PrintDynamicNotes
            notes={[note]}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotes), break: false, wrap: true });
    }

    /* quota comprende, non comprende */
    if (template && template.inclusions && template.inclusions.length > 0 && isPrintInclusions) {
        template.inclusions.map(inclusion => {
            let rowInclusion = ReactDOMServer.renderToString(<TemplatePrintInclusion
                inclusion={inclusion}
            />);

            myArr.push({ html: generateComponentInBody(rowInclusion), break: false, wrap: true });
        })
    }

    let isFirstTour = true;

    if (isPrintNoteBig && noteProdottoBigArr.length > 0) {
        isFirstTour = false;

        myArr.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });

        let rowPorductsNotes = ReactDOMServer.renderToString(<PrintProductsNotes
            arrNotesProdcuts={noteProdottoBigArr}
        />);

        myArr.push({ html: generateComponentInBody(rowPorductsNotes), break: false, wrap: true });
    }
    let schedeStatiche = [];
    let nameActivityTourArr = [];
    let nameStructureArr = [];

    for (var j = 0; j < finalArr.length; j++) {
        let qInfo = finalArr[j];
        /* Programma di viaggio, note extra */
        if (qInfo.productType === 'Activity' && qInfo.productInfo.option.descriptions.length > 0) {

            if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                continue;

            if (nameActivityTourArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato di Tour 
                nameActivityTourArr.push(qInfo.productInfo.name);
            else
                continue;

            if (isFirstTour) {
                schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });
                isFirstTour = false;
            }

            let rowSchedaSt;
            let tourName;
            let descToPrint = qInfo.productInfo.option.descriptions.filter(x => x.isPrint);

            let rowSchedaTitle = ReactDOMServer.renderToString(<PrintStepHeader
                title={qInfo.productInfo.name}
                PNR=''
                totaleViaggio=''
                iconName='directions_walk'
                cultureName={cultureName}
            />);
            schedeStatiche.push({ html: generateComponentInBody(rowSchedaTitle), break: false, wrap: true });

            for (var k = 0; k < descToPrint.length; k++) {
                let newDescription = replaceAllStyleElements(descToPrint[k].description);

                if (k > 0)
                    tourName = "";
                else
                    tourName = qInfo.productInfo.name;

                rowSchedaSt = ReactDOMServer.renderToString(<TourStaticPrint
                    descriptions={newDescription}
                    title={descToPrint[k].title}
                    tourName={tourName}
                />);

                schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
            }
        }
        else if (qInfo.productType === 'Structure' && qInfo.productInfo.descriptions.length > 0) {
            if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                continue;
            if (nameStructureArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato
                nameStructureArr.push(qInfo.productInfo.name);
            else
                continue;

            if (isFirstTour) {
                schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });
                isFirstTour = false;
            }

            let rowSchedaSt;
            let tourName;
            let descToPrint = qInfo.productInfo.descriptions.filter(x => x.isPrint);

            let rowSchedaTitle = ReactDOMServer.renderToString(<PrintStepHeader
                title={qInfo.productInfo.name}
                PNR=''
                totaleViaggio=''
                iconName='info_hotel'
                cultureName={cultureName}
            />);
            schedeStatiche.push({ html: generateComponentInBody(rowSchedaTitle), break: false, wrap: true });

            for (var k = 0; k < descToPrint.length; k++) {
                let newDescription = replaceAllStyleElements(descToPrint[k].description);

                if (k > 0)
                    tourName = "";
                else
                    tourName = qInfo.productInfo.name;

                rowSchedaSt = ReactDOMServer.renderToString(<TourStaticPrint
                    descriptions={newDescription}
                    title={descToPrint[k].title}
                    tourName={tourName}
                />);
                schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
            }
        }
    }

    myArr.push(...schedeStatiche);

    return myArr;
}