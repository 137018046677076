import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListBox } from 'primereact/listbox';
import { M3Icon } from "./M3Icon";

export const M3SelectCustom = ({ idModal, iconClass, type, items, modeView, closeExternal, onSelectItems, onOpenPanelItems, defaultSelected }) => {
    const { t } = useTranslation();

    const [placeholder, setPlaceholder] = useState("");
    const [iconName, setIconName] = useState("");
    const [selectItems, setSelectItems] = useState([]);
    const [selectItemsLabel, setSelectItemsLabel] = useState('');
    const [showPanelItems, setShowPanelItems] = useState(false);

    const [inputWidth, setInputWidth] = useState('w-80');
    const [iconWidth, setIconWidth] = useState('w-10');

    useEffect(() => {
        if (iconClass && iconClass === 'w-30') {
            setInputWidth('w-70');
            setIconWidth('w-30');
        } else {
            setInputWidth('w-80');
            setIconWidth('w-10');
        }
    }, [])

    useEffect(() => {
        setDefaults();
    }, [type]);

    function setDefaults() {
        if (type) {
            switch (type.toUpperCase()) {
                case 'MACROAREE': {
                    setPlaceholder(t('Product:Cruise:MacroareaDestination'));
                    setSelectItemsLabel(t('Product:Cruise:MacroareaPlaceholder'));
                    setIconName("Place");
                    break;
                }

                case 'SHIPS': {
                    setPlaceholder(t('Product:Cruise:Ship'));
                    setSelectItemsLabel(t('Product:Cruise:ShipPlaceholder'));
                    setIconName("Ship");
                    break;
                }

                case 'DURATION': {
                    setPlaceholder(t('Product:Cruise:DurationDays'));
                    setSelectItemsLabel(t('MyBook:All'));
                    setIconName("Time");
                    break;
                }

                case 'CABINS': {
                    setPlaceholder(t('Product:Cruise:CabinType'));
                    setSelectItemsLabel(t('Product:Cruise:CabinTypePlaceholder'));
                    setIconName("Cabin");
                    break;
                }

                case 'PORTS': {
                    setPlaceholder(t('Product:Cruise:DeparturePort'));
                    setSelectItemsLabel(t('Product:Cruise:DeparturePortPlaceholder'));
                    setIconName("Port");
                    break;
                }

                case 'TEMPLATEDURATIONS': {
                    setPlaceholder(t('Product:Tour:Filter:Nights'));
                    setSelectItemsLabel(t('MyBook:All'));
                    setIconName("Time");
                    break;
                }

                case 'PAXCOMPOSITION': {
                    setPlaceholder(t('SearchEngine:Travelers'));
                    setSelectItemsLabel(t('SearchEngine:Travelers'));
                    setIconName("Groups");
                    break;
                }

                default:
                    break;
            }
        }
    }

    useEffect(() => {
        setShowPanelItems(closeExternal);
    }, [closeExternal]);

    useEffect(() => {
        if (!defaultSelected) {
            setSelectItems(null);
            setDefaults();
        }

        try {
            let label = items.filter(x => x.value === defaultSelected.value || x.value === defaultSelected + "")[0];
            setSelectItemsLabel(label.label);

            setSelectItems(label.value);
        } catch (ex) {

        }


    }, [defaultSelected, items]);


    function openPanelItems() {
        setShowPanelItems(!showPanelItems);
        onOpenPanelItems(!showPanelItems);
    }

    function onChangeListBox(item) {
        let label = items.filter(x => x.value === item)[0];
        setSelectItemsLabel(label.label);
        setShowPanelItems(false);
        onSelectItems(item);
    }

    function onClosePanelItems() {
        setShowPanelItems(false);

        try {

            let myModal = document.getElementById(idModal);
            myModal.style.setProperty("height", "0px");

        } catch (ex) { }

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalDestination_" + idModal);
            btn.click();
        }
    }


    return (
        <>
            <div className="w-100">
                {
                    !showPanelItems && <div className="input-group rounded pr-1 bg-white" onClick={_ => openPanelItems()}>
                        <span className={"input-group-text px-2 bg-white " + iconWidth} id="basic-addon1">
                            <M3Icon iconName={iconName} />
                        </span>
                        <div className={"pl-1 " + inputWidth} >
                            <label htmlFor="ddlRay" className="form-label h065">
                                <data m3lab="SearchEngine.Ray">
                                    {placeholder}
                                </data>
                            </label>
                            <input className="form-control h075 w-100 border-0 shadow-none"
                                id={"ddl_" + type}
                                value={selectItemsLabel}
                                readOnly />
                        </div>
                    </div>
                }
            </div>

            <a id={"btnModalComposition_" + idModal} data-bs-toggle="modal" data-bs-target={"#" + idModal}></a>
            <div className={"modal tp-search-input position-relative " + (modeView === 'MODAL' ? "d-block" : "wm-250 fade")}
                style={{ overflow: 'unset', height: '0px', zIndex: '200', pointerEvents: 'none' }}
                id={idModal}
                tabIndex="-1"
                aria-labelledby={"#CompositionLabel_" + type}
                aria-hidden="true">
                <div className="modal-dialog">
                    {
                        showPanelItems && <div className="modal-content">
                            <div className="modal-header p-0">
                                <span className="input-group-text px-2 w-20 bg-white" id="basic-addon1">
                                    <M3Icon iconName={iconName} />
                                </span>
                                <div className="pl-1 w-80">
                                    <label htmlFor="ddlRay" className="form-label h065">
                                        <data m3lab="SearchEngine.Ray">
                                            {placeholder}
                                        </data>
                                    </label>

                                    <input className="form-control h075 w-100 border-0 shadow-none"
                                        id={"ddl_" + type}
                                        value={selectItemsLabel}
                                        readOnly />
                                </div>
                                <button type="button" className="btn-close mt-2 mr-1 m-auto" onClick={e => onClosePanelItems()}></button>
                            </div>
                            <div className="modal-body p-0">
                                <ListBox filter
                                    value={selectItems}
                                    onChange={(e) => onChangeListBox(e.value)}
                                    options={items}
                                    optionLabel="label"
                                    listStyle={{ height: '250px' }}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
