import ReactDOMServer from "react-dom/server";
import { generateComponentInBody, replaceAllStyleElements, dynamicNotesDistincts } from "../../../js/PrintUtility";
import { formatPrice } from '../../../js/Utils.js';
import { ConfirmPrintStepHeader } from "../printConfirm/ConfirmPrintStepHeader";
import { ConfirmPrintStepItem } from "../printConfirm/ConfirmPrintStepItem";
import { ConfirmTourStaticPrint } from "../printConfirm/ConfirmTourStaticPrint";
import { ConfirmPrintDiscount } from "../printConfirm/ConfirmPrintDiscount";
import { ConfirmPrintAncillaries } from "../printConfirm/ConfirmPrintAncillaries";
import { ConfirmPrintCommissionsDetail } from "../printConfirm/ConfirmPrintCommissionsDetail";
import { ConfirmPrintPaxes } from "../printConfirm/ConfirmPrintPaxes";
import { ConfirmPrintDynamicNotes } from "../printConfirm/ConfirmPrintDynamicNotes";
import { ConfirmPrintTitle } from "../printConfirm/ConfirmPrintTitle";
import { ConfirmPrintProductsNotes } from "../printConfirm/ConfirmPrintProductsNotes";
import { ConfirmPrintScadenziario } from "../printConfirm/ConfirmPrintScadenziario";
import { ConfirmPrintCancelPolicy } from "../printConfirm/ConfirmPrintCancelPolicy";


// crea un array ordinato con i voli nei primi posti
function getFinalArr(itemStepPrint) {
    var tempArrFlights = [];
    var tempArrOthers = [];

    for (var i = 0; i < itemStepPrint.length; i++) {
        let qInfo = itemStepPrint[i];

        if (qInfo.productSubType === 'Flight') {
            tempArrFlights.push(qInfo);
        }
        else {
            tempArrOthers.push(qInfo);
        }
    }

    return tempArrFlights.concat(tempArrOthers);
}

/* verifica se le note superano il massimo numero di righe, circa 10 */
function checkIfNotesTooBig(noteArr) {
    let isTooBig = false;
    let numberChar = 0;
    
    if (noteArr.length > 0 && noteArr[0] === null)
        return isTooBig;

    noteArr.forEach(function (note) {
        if (note) {

            if ((numberChar + note.length) > 800) {
                isTooBig = true;
            }
            else {
                numberChar += note.length;
            }
        }
    });

    return isTooBig;
}

/* calcola se le note di un prodotto sono lunghe e le salvain un array, che poi verrà mostrato in fondo alla pagina */
function getProductsNotesArray(noteArr, name, roomName="") {
    let arrNotesProdcuts = [];

    arrNotesProdcuts.push({
        name: name + roomName,
        notes: noteArr,
    });

    return arrNotesProdcuts;
}

export function generateQuotationHTMLConfirm(orderNumber, startDate, itemStepPrint, quotationPaxes, dynamicNotes, dynamicNotesTop, dynamicNotesStep, confirmMarginItems, template, scadenziarioPrint, cancelPolicyArr, notesPratica, cultureName, isPrintCliente=false) {
    let myArr = [];
    let isFirstFlight = true;
    let isFirstService = true;

    localStorage.removeItem("oldIdFoundImage");

    let dynamicNotesDistinct = dynamicNotesDistincts(dynamicNotes);
    
    let rowTitleQuotation = ReactDOMServer.renderToString(<ConfirmPrintTitle
        date={startDate}
        orderNumber={orderNumber }
        cultureName={cultureName}
    />);

    myArr.push({ html: generateComponentInBody(rowTitleQuotation), break: false, wrap: true });

    /* passeggeri */
    if (quotationPaxes && quotationPaxes.length > 0) {
        let rowPaxes = ReactDOMServer.renderToString(<ConfirmPrintPaxes
            paxes={quotationPaxes}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowPaxes), break: false, wrap: true });
    }

    if (dynamicNotesTop && dynamicNotesTop.length > 0) {
        let rowDynamicNotesTop = ReactDOMServer.renderToString(<ConfirmPrintDynamicNotes
            notes={dynamicNotesDistincts(dynamicNotesTop)}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotesTop), break: false, wrap: true });
    }

    var finalArr = getFinalArr(itemStepPrint);
    
    var arrNotesProdcuts = [];

    /* Elenco servizi */
    for (var j = 0; j < finalArr.length; j++) {
        let qInfo = finalArr[j];

        // crea intestazione/titolo tipo di servizio, lo crea una volta sola
        if (qInfo.productSubType === 'Flight' && isFirstFlight) {
            let rowStep = ReactDOMServer.renderToString(<ConfirmPrintStepHeader
                title="I VOSTRI VOLI"
                PNR={qInfo.productInfo.priceBar.supplierPNR}
                totaleViaggio=''
                iconName='voli'
                cultureName={cultureName}
            />);

            myArr.push({ html: generateComponentInBody(rowStep), break: false, wrap: true });

            isFirstFlight = false;
        }
        else if (isFirstService && qInfo.productSubType !== 'Flight') {
            let rowStep = ReactDOMServer.renderToString(<ConfirmPrintStepHeader
                title="ELENCO SERVIZI"
                PNR=''
                totaleViaggio=''
                iconName='elenco_servizi'
                cultureName={cultureName}
            />);

            myArr.push({ html: generateComponentInBody(rowStep), break: true, wrap: true });

            isFirstService = false;
        }

        let isNotesHide = false;
        let resProductArr = [];

        if ((qInfo.productType === 'Activity') && qInfo.productInfo.option.notes && qInfo.productInfo.option.notes.length > 0) {
            if (qInfo.productInfo.option.notes)
                isNotesHide = checkIfNotesTooBig(qInfo.productInfo.option.notes);

            if (isNotesHide) {
                resProductArr = getProductsNotesArray(qInfo.productInfo.option.notes, qInfo.productInfo.name);
                if (resProductArr.length > 0) {
                    arrNotesProdcuts.push(resProductArr);
                }
            }
        }
        else if ((qInfo.productType === 'Tour' || qInfo.productSubType === 'Hotel') && qInfo.productInfo) {
            if (qInfo.productInfo.rooms && qInfo.productInfo.rooms.length > 0) {
                let finalRooms = qInfo.productInfo.rooms.filter(x =>
                    x.priceBar.status === "CONFIRMED" ||
                    x.priceBar.status === "ONREQUEST" ||
                    x.priceBar.status === "PRINTED" ||
                    x.priceBar.status === "REFUND" ||
                    x.priceBar.status === "PENALTY"
                );
                /* controlla che tutte le note dell'hotel siano contenute nel massimo numero di righe */
                finalRooms.forEach(function (room) {
                    if (room.notes.length > 0 && room.notes[0] != "")
                        isNotesHide = checkIfNotesTooBig(room.notes);
                });

                /* se le note eccedono il max, allora le inserisce nell'array */
                if (isNotesHide) {
                    finalRooms.forEach(function (room) {
                        if (room.notes.length > 0 && room.notes[0] != "") {
                            resProductArr = getProductsNotesArray(room.notes, qInfo.productInfo.name, " (Camera " + room.sequence + ")");
                            if (resProductArr.length > 0) {
                                arrNotesProdcuts.push(resProductArr);
                            }
                        }
                    });
                }
            }
        }
        else if (qInfo.productSubType === 'Cruise') {
            if (qInfo.productInfo.note && qInfo.productInfo.note != "")
                isNotesHide = checkIfNotesTooBig([qInfo.productInfo.note]);

            /* se le note eccedono il max, allora le inserisce nell'array */
            if (isNotesHide) {
                if (qInfo.productInfo.note != "") {
                    resProductArr = getProductsNotesArray([qInfo.productInfo.note], qInfo.productInfo.name);
                    if (resProductArr.length > 0) {
                        arrNotesProdcuts.push(resProductArr);
                    }
                }
            }
        }
        else if (qInfo.productSubType === 'CarRental') {
            if (qInfo.productInfo.option.notes)
                isNotesHide = checkIfNotesTooBig(qInfo.productInfo.option.notes);

            if (isNotesHide) {
                resProductArr = getProductsNotesArray(qInfo.productInfo.option.notes, qInfo.productInfo.name);
                if (resProductArr.length > 0) {
                    arrNotesProdcuts.push(resProductArr);
                }
            }
        }

        if ((qInfo.productSubType === 'Flight' && template) || (!template)) {
            /* Prodotti  */
            let rowQInfo = ReactDOMServer.renderToString(<ConfirmPrintStepItem
                qInfo={qInfo}
                isPrintCliente={isPrintCliente}
                isNotesHide={isNotesHide}
                dynamicNotesDistinct={dynamicNotesDistinct}
            />);

            myArr.push({ html: generateComponentInBody(rowQInfo), break: false, wrap: true });
        }
    }

    var idArrNote = [];
    if (template && template.steps && template.steps.length > 0) {
        template.steps.map((step, stepKey) => {
            itemStepPrint.filter(x => x.productInfo.stepId === step.step && x.productType !== "Flight").map((item, itemKey) => {
                if (itemKey === 0) {
                    let rowStepheader = ReactDOMServer.renderToString(<ConfirmPrintStepHeader
                        key={stepKey}
                        title={step.webDestinationName}
                        iconName=''
                        stepIndex={step.step}
                        minDate={step.dateFrom}
                        maxDate={step.dateTo}
                    />);
                    myArr.push({ html: generateComponentInBody(rowStepheader), break: false, wrap: true });

                    if (dynamicNotesStep && dynamicNotesStep.length > 0) {
                        var showImage = true;
                        var hideText = false;

                        dynamicNotesStep.map((note, idx) => {
                            if (note.step === step.step) {
                                if (!idArrNote.find(x => x.id === note.noteId && note.step === x.step)) {
                                    idArrNote.push({ id: note.noteId, step: note.step });
                                }
                                else {
                                    hideText = true;
                                }

                                let rowNoteStep = ReactDOMServer.renderToString(<ConfirmPrintDynamicNotes
                                    notes={[note]}
                                    cultureName={cultureName}
                                    isShowImage={showImage}
                                    hideText={hideText}
                                />);

                                myArr.push({ html: generateComponentInBody(rowNoteStep), break: false, wrap: true });

                                showImage = false;
                            }
                        });
                    }
                }

                let rowItem = ReactDOMServer.renderToString(<ConfirmPrintStepItem
                    key={itemKey}
                    qInfo={item}
                    isPrintCliente={isPrintCliente}
                    isPrintPdf={false}
                    isNotStep={false}
                    dynamicNotesDistinct={dynamicNotesDistincts(dynamicNotes)}
                />);

                myArr.push({ html: generateComponentInBody(rowItem), break: false, wrap: true });

            });
        })
    } 

    /* Sconto commerciale */
    let rowDiscount = ReactDOMServer.renderToString(<ConfirmPrintDiscount
        recapItems={itemStepPrint}
        cultureName={cultureName}
    />);

    myArr.push({ html: generateComponentInBody(rowDiscount), break: false, wrap: true });

    /* Quote accessorie */
    let rowAncillaries = ReactDOMServer.renderToString(<ConfirmPrintAncillaries
        recapItems={itemStepPrint}
        cultureName={cultureName}
        isPrintCliente={isPrintCliente}
    />);

    myArr.push({ html: generateComponentInBody(rowAncillaries), break: false, wrap: true });
    
    
    /* Totale viaggio - dettaglio commissioni */
    function getTotaleViaggio(marginItems, isPrintCliente) {
        let total = 0;
        let currency = 0;

        marginItems.forEach(function (item) {
            currency = item.currency;
            if (isPrintCliente) {
                total = total + item.prezzoCliente;
            } else {
                total = total + item.invoicePrice;
            }
        });

        return formatPrice(total, currency, cultureName);
    }
     
    
    let rowStepViaggio = ReactDOMServer.renderToString(<ConfirmPrintStepHeader
        title={(isPrintCliente ? "TOTALE VIAGGIO" : "TOTALE VIAGGIO - DETTAGLIO COMMISSIONI")}
        PNR=''
        totaleViaggio={(isPrintCliente ? getTotaleViaggio(confirmMarginItems, isPrintCliente) : "")}
        iconName='dettaglio_commissioni'
        cultureName={cultureName}
        isPrintCliente={isPrintCliente}
    />);

    myArr.push({ html: generateComponentInBody(rowStepViaggio), break: false, wrap: true });

    let rowCommDetail = ReactDOMServer.renderToString(<ConfirmPrintCommissionsDetail
        marginItems={confirmMarginItems}
        totaleViaggio={getTotaleViaggio(confirmMarginItems, isPrintCliente)}
        cultureName={cultureName}
        isPrintCliente={isPrintCliente}
    />);

    myArr.push({ html: generateComponentInBody(rowCommDetail), break: false, wrap: true });

    if (!isPrintCliente) {
        /* Sacadenzirio */
        let rowScadenziarioTitle = ReactDOMServer.renderToString(<ConfirmPrintStepHeader
            title="SCADENZE DI PAGAMENTO"
            PNR=''
            totaleViaggio=''
            iconName='cronologia'
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowScadenziarioTitle), break: false, wrap: true });

        let rowScadenziario = ReactDOMServer.renderToString(<ConfirmPrintScadenziario
            scadenziario={scadenziarioPrint}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowScadenziario), break: false, wrap: true });
    }

    if (!isPrintCliente) {
        /* Cancel Policy */
        let rowCancelPolicyTitle = ReactDOMServer.renderToString(<ConfirmPrintStepHeader
            title="DETTAGLIO PENALI PRODOTTO"
            PNR=''
            totaleViaggio=''
            iconName='elenco_servizi'
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowCancelPolicyTitle), break: false, wrap: true });

        let rowCancelPolicy = ReactDOMServer.renderToString(<ConfirmPrintCancelPolicy
            cancelPolicyArr={cancelPolicyArr}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowCancelPolicy), break: false, wrap: true });
    }

    if (dynamicNotesDistinct && dynamicNotesDistinct.length > 0) {
        /* Note dinamiche */
        let rowNote = ReactDOMServer.renderToString(<ConfirmPrintStepHeader
            title="INFO UTILI"
            PNR=''
            totaleViaggio=''
            iconName='info_utili'
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowNote), break: false, wrap: true });

        let rowDynamicNotes = ReactDOMServer.renderToString(<ConfirmPrintDynamicNotes
            notes={dynamicNotesDistinct}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotes), break: false, wrap: true });
    }

    let schedeStatiche = [];
    let nameActivityTourArr = [];
    let nameStructureArr = [];
    let nameTourArr = [];

    for (var j = 0; j < finalArr.length; j++) {
        let qInfo = finalArr[j];
        /* Programma di viaggio, note extra */
        if (qInfo.productType === 'Activity' && qInfo.productInfo.option.descriptions.length > 0) {
            if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                continue;

            if (nameActivityTourArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato di Tour 
                nameActivityTourArr.push(qInfo.productInfo.name);
            else
                continue;

            let rowSchedaSt;
            let tourName;
            let descToPrint = qInfo.productInfo.option.descriptions.filter(x => x.isPrint);

            /*if (descToPrint.length > 0)
                schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });*/

            for (var k = 0; k < descToPrint.length; k++) {
                let newDescription = replaceAllStyleElements(descToPrint[k].description);

                if (k > 0)
                    tourName = "";
                else
                    tourName = qInfo.productInfo.name;

                rowSchedaSt = ReactDOMServer.renderToString(<ConfirmTourStaticPrint
                    descriptions={newDescription}
                    title={descToPrint[k].title}
                    tourName={tourName}
                />);

                schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
            }
        }
        else if (qInfo.productType === 'Structure' && qInfo.productInfo.descriptions.length > 0) {
            if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                continue;
            if (nameStructureArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato
                nameStructureArr.push(qInfo.productInfo.name);
            else
                continue;

            let rowSchedaSt;
            let tourName;
            let descToPrint = qInfo.productInfo.descriptions.filter(x => x.isPrint);

            /*if (descToPrint.length > 0)
                schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });*/

            for (var k = 0; k < descToPrint.length; k++) {
                let newDescription = replaceAllStyleElements(descToPrint[k].description);

                if (k > 0)
                    tourName = "";
                else
                    tourName = qInfo.productInfo.name;

                rowSchedaSt = ReactDOMServer.renderToString(<ConfirmTourStaticPrint
                    descriptions={newDescription}
                    title={descToPrint[k].title}
                    tourName={tourName}
                />);
                schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
            }
        }
        else if (qInfo.productType === 'Tour' && qInfo.productInfo.descriptions.length > 0) {
            if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                continue;
            if (nameTourArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato
                nameTourArr.push(qInfo.productInfo.name);
            else
                continue;

            let rowSchedaSt;
            let tourName;
            let descToPrint = qInfo.productInfo.descriptions.filter(x => x.isPrint);

            /*if (descToPrint.length > 0)
                schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });*/

            for (var k = 0; k < descToPrint.length; k++) {
                let newDescription = replaceAllStyleElements(descToPrint[k].description);

                if (k > 0)
                    tourName = "";
                else
                    tourName = qInfo.productInfo.name;

                rowSchedaSt = ReactDOMServer.renderToString(<ConfirmTourStaticPrint
                    descriptions={newDescription}
                    title={descToPrint[k].title}
                    tourName={tourName}
                />);
                schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
            }
        }

        /* note pratica */
        if (notesPratica && notesPratica.length > 0) {
            notesPratica.forEach(function (note) {

                if (note.idPratica === qInfo.productInfo.idPratica) {
                    let notePratica = ReactDOMServer.renderToString(<ConfirmTourStaticPrint
                        titleStep={qInfo.productInfo.name}
                        descriptions={replaceAllStyleElements(note.nota)}
                        title={note.title}
                    />);

                    schedeStatiche.push({ html: generateComponentInBody(notePratica), break: false, wrap: true });
                }
            });
        }
    }


    //myArr.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });
    myArr.push(...schedeStatiche);

    /* Note prodotti (lunghe) */
    if (arrNotesProdcuts.length > 0) {
        myArr.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });

        let rowNoteProduct = ReactDOMServer.renderToString(<ConfirmPrintStepHeader
            title="NOTE PRODOTTI"
            PNR=''
            totaleViaggio=''
            iconName=''
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowNoteProduct), break: false, wrap: true });


        var nameProduct = "";
        let rowPorductsNotes;
        arrNotesProdcuts.forEach(function (product) {
            for (var k = 0; k < product[0].notes.length; k++) {
                let note = replaceAllStyleElements(product[0].notes[k]);

                if (k > 0)
                    nameProduct = "";
                else
                    nameProduct = product[0].name;

                rowPorductsNotes = ReactDOMServer.renderToString(<ConfirmPrintProductsNotes
                    name={nameProduct}
                    note={note}
                />);

                myArr.push({ html: generateComponentInBody(rowPorductsNotes), break: false, wrap: true });
            }
        });
    }

    return myArr;
}