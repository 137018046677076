import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const HomeVille = () => {
    const { t } = useTranslation();

    const [principalImage, setPrincipalImage] = useState("https://ikh.villas/wp-content/uploads/2023/03/20_Years_IKH.png");
    const [selectComposition, setSelectComposition] = useState(null);
    const [selectDates, setSelectDates] = useState(null);
    const [isValidForm, setIsValidForm] = useState({
        isValidDestination: false,
        isValidDates: false,
        isValidComposition: false,
        isAllFieldValid: false
    });

    useEffect(() => {

    }, []);


    function onClickDestination(e) {
        let id = e.currentTarget.id;
        let titolo = e.currentTarget.dataset.title;
        let subtitle = e.currentTarget.dataset.subtitle;

        window.location.href = '/HomeVilleLista/?area=' + id + "&title=" + encodeURIComponent(titolo) + "&subtitle=" + encodeURIComponent(subtitle);
    }

    return (
        <>
            <div className="container">
                <div style={{ margin: "2rem" }} >
                    <div style={{ textAlign: "center", width: "100%" }}> <img src={ principalImage } /></div>
                    <div className="row" style={{ marginTop: "40px", marginBottom: "40px" }} >
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 h1">
                            <div>Una collezione esclusiva di case di lusso</div>

                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 h6" style={{ lineHeight: "1.5rem" }}>
                            Trovare la villa perfetta per te e per l'occasione del tuo soggiorno può essere una sfida che richiede tempo. Offriamo una collezione eccezionale e accuratamente selezionata di bellissime ville di lusso di prima classe per te in località esclusive come Mykonos, Francia meridionale, Isole Baleari o Italia.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12  cursor-pointer" style={{ marginBottom: "30px" }}>
                            <div id="formentera" className="card cardDestination" onClick={(e) => { onClickDestination(e) }} data-title="AFFITTO DI VILLE DI LUSSO A FORMENTERA" data-subtitle="Gli affitti di ville di lusso a FORMENTERA sono la chiave per una vacanza rilassante. Le migliori ville in affitto in Spagna si trovano a FORMENTERA. Scopri con IKH il posto migliore dove trascorrere le tue vacanze sotto il sole della Spagna.">
                                <div style={{ overflow: "hidden" }}>
                                    <div className="zoom-image">
                                        <div style={{ backgroundImage: `url("")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "258px"}}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h2 className="card-title">Formentera</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12  cursor-pointer" style={{ marginBottom: "30px" }} >
                            <div id="ibiza" className="card cardDestination" onClick={(e) => { onClickDestination(e) }} data-title="AFFITTO DI VILLE DI LUSSO A IBIZA" data-subtitle="Gli affitti di ville di lusso a FORMENTERA sono la chiave per una vacanza rilassante. Le migliori ville in affitto in Spagna si trovano a IBIZA. Scopri con IKH il posto migliore dove trascorrere le tue vacanze sotto il sole della Spagna.">
                                <div style={{ overflow: "hidden" }}>
                                    <div className="zoom-image">
                                        <div style={{ backgroundImage: `url("")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "258px" }}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h2 className="card-title">Ibiza</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12  cursor-pointer" style={{ marginBottom: "30px" }} >
                            <div id="France" className="card cardDestination" onClick={(e) => { onClickDestination(e) }}  data-title="AFFITTO DI VILLE DI LUSSO IN FRANCIA" data-subtitle="Gli affitti di ville di lusso in FRANCIA sono la chiave per una vacanza rilassante. Le migliori ville in affitto in Italia si trovano in FRANCIA. Scopri con IKH il posto migliore dove trascorrere le tue vacanze sotto il sole dell'Italia.">
                                <div style={{ overflow: "hidden" }}>
                                    <div className="zoom-image">
                                        <div style={{ backgroundImage: `url("https://ikhbackend.evinapp.it/Files/Images/157_002.jpg")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "258px" }}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h2 className="card-title">Francia</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12  cursor-pointer" style={{ marginBottom: "30px" }} >
                            <div id="alps" className="card cardDestination" onClick={(e) => { onClickDestination(e) }} data-title="AFFITTO DI VILLE DI LUSSO SULLE ALPI" data-subtitle="Gli affitti di ville di lusso sulle ALPI sono la chiave per una vacanza rilassante. Le migliori ville in affitto in Italia si trovano sulle ALPI. Scopri con IKH il posto migliore dove trascorrere le tue vacanze sotto il sole dell' Italia.">
                                <div style={{ overflow: "hidden" }}>
                                    <div className="zoom-image">
                                        <div style={{ backgroundImage: `url("https://ikhbackend.evinapp.it/Files/Images/2_001.jpg")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "258px" }}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h2 className="card-title">Alpi</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12  cursor-pointer" style={{ marginBottom: "30px" }} >
                            <div id="Lazio" className="card cardDestination" onClick={(e) => { onClickDestination(e) }}  data-title="AFFITTO DI VILLE DI LUSSO IN LAZIO" data-subtitle="Gli affitti di ville di lusso in LAZIO sono la chiave per una vacanza rilassante. Le migliori ville in affitto in Italia si trovano in LAZIO. Scopri con IKH il posto migliore dove trascorrere le tue vacanze sotto il sole dell'Italia.">
                                <div style={{ overflow: "hidden" }}>
                                    <div className="zoom-image">
                                        <div style={{ backgroundImage: `url("https://ikhbackend.evinapp.it/Files/Images/157_002.jpg")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "258px" }}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h2 className="card-title">Lazio</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 cursor-pointer" style={{ marginBottom: "30px" }} >
                            <div id="Lombardia" className="card cardDestination" onClick={(e) => { onClickDestination(e) }} data-title="AFFITTO DI VILLE DI LUSSO IN LOMBARDIA" data-subtitle="Gli affitti di ville di lusso in LOMBARDIA sono la chiave per una vacanza rilassante. Le migliori ville in affitto in Italia si trovano in LOMBARDIA. Scopri con IKH il posto migliore dove trascorrere le tue vacanze sotto il sole dell'Italia.">
                                <div style={{ overflow: "hidden" }}>
                                    <div className="zoom-image">
                                        <div style={{ backgroundImage: `url("https://ikhbackend.evinapp.it/Files/Images/90_001.jpg")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "258px" }}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h2 className="card-title">Lombardia</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 cursor-pointer" style={{ marginBottom: "30px" }} >
                            <div id="Sardinia" className="card cardDestination" onClick={(e) => { onClickDestination(e) }} data-title="AFFITTO DI VILLE DI LUSSO IN SARDEGNA" data-subtitle="Gli affitti di ville di lusso in SARDEGNA sono la chiave per una vacanza rilassante. Le migliori ville in affitto in Italia si trovano in SARDEGNA. Scopri con IKH il posto migliore dove trascorrere le tue vacanze sotto il sole dell'Italia.">
                                <div style={{ overflow: "hidden" }}>
                                    <div className="zoom-image">
                                        <div style={{ backgroundImage: `url("https://ikh.villas/wp-content/uploads/2022/03/shutterstock_1813145050.jpg.webp")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "258px" }}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h2 className="card-title">Sardegna</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 cursor-pointer" style={{ marginBottom: "30px" }} >
                            <div id="Sicily" className="card cardDestination" onClick={(e) => { onClickDestination(e) }} data-title="AFFITTO DI VILLE DI LUSSO IN SICILIA" data-subtitle="Gli affitti di ville di lusso in SICILIA sono la chiave per una vacanza rilassante. Le migliori ville in affitto in Italia si trovano in SICILIA. Scopri con IKH il posto migliore dove trascorrere le tue vacanze sotto il sole dell'Italia.">
                                <div style={{ overflow: "hidden" }}>
                                    <div className="zoom-image">
                                        <div style={{ backgroundImage: `url("https://ikh.villas/wp-content/uploads/2022/03/shutterstock_267597023.jpg.webp")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "258px" }}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h2 className="card-title">Sicilia</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 cursor-pointer" style={{ marginBottom: "30px" }} >
                            <div id="tuscany" className="card cardDestination" onClick={(e) => { onClickDestination(e) }} data-title="AFFITTO DI VILLE DI LUSSO IN TOSCANA" data-subtitle="Gli affitti di ville di lusso in TOSCANA sono la chiave per una vacanza rilassante. Le migliori ville in affitto in Italia si trovano in TOSCANA. Scopri con IKH il posto migliore dove trascorrere le tue vacanze sotto il sole dell'Italia.">
                                <div style={{ overflow: "hidden" }}>
                                    <div className="zoom-image">
                                        <div style={{ backgroundImage: `url("https://ikhbackend.evinapp.it/Files/Images/186_001.jpg")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "258px" }}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h2 className="card-title">Toscana</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 cursor-pointer" style={{ marginBottom: "30px" }} >
                            <div id="Trentino Alto Adige" className="card cardDestination" onClick={(e) => { onClickDestination(e) }} data-title="AFFITTO DI VILLE DI LUSSO IN TRENTINO ALTO ADIGE" data-subtitle="Gli affitti di ville di lusso in TRENTINO ALTO ADIGE sono la chiave per una vacanza rilassante. Le migliori ville in affitto in Italia si trovano in TRENITNO ALTO ADIGE. Scopri con IKH il posto migliore dove trascorrere le tue vacanze sotto il sole dell'Italia.">
                                <div style={{ overflow: "hidden" }}>
                                    <div className="zoom-image">
                                        <div style={{ backgroundImage: `url("https://ikhbackend.evinapp.it/Files/Images/81_001.jpg")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "258px" }}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h2 className="card-title">Trentino Alto Adige</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 cursor-pointer" style={{ marginBottom: "30px" }} >
                            <div id="veneto" className="card cardDestination" onClick={(e) => { onClickDestination(e) }} data-title="AFFITTO DI VILLE DI LUSSO IN VENETO" data-subtitle="Gli affitti di ville di lusso in VENETO sono la chiave per una vacanza rilassante. Le migliori ville in affitto in Italia si trovano in VENETO. Scopri con IKH il posto migliore dove trascorrere le tue vacanze sotto il sole dell' Italia.">
                                <div style={{ overflow: "hidden" }}>
                                    <div className="zoom-image">
                                        <div style={{ backgroundImage: `url("https://ikhbackend.evinapp.it/Files/Images/1_001.jpg")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "258px" }}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h2 className="card-title">Veneto</h2>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="h3">
                        Le migliori proprietà selezionate per te.
                    </div>
                    <div className="h6" style={{ lineHeight: "1.5rem" }} >
                        Sia che il tuo stile preferito sia un'autentica finca con carattere e fascino o una villa di design chic e moderna con piscina, sia che il tuo soggiorno sia una vacanza in famiglia, un viaggio rinfrescante con gli amici, una vacanza all'insegna del golf, un ritiro, il tuo matrimonio luna di miele, un evento aziendale o una grande festa di famiglia troveremo la proprietà perfetta per soddisfare ogni tua esigenza e desiderio.
                    </div>
                </div>
            </div>
        </>
    );
}