import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../Common/Loading';
import { Passenger } from './Passenger';
import { PriceBar } from './PriceBar';
import { CancelReservationModal } from './OperationModals/CancelReservationModal';
import { PayWithCreditCardModal } from './OperationModals/PayWithCreditCardModal';
import { OperationButtons } from './OperationButtons';
import { QuotationOperationButtons } from '../QuotationTO/components/QuotationOperationButtons';
import { formatPrice, formatDateTime } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { imageOnError, imageOnLoad } from '../../js/CDNUtility';
import { renderWebsite } from '../../js/Constant';
import { Error } from '../Common/Error';
import { useTranslation } from 'react-i18next';
import { DMCExtras } from '../Product/Common/DMC/DMCExtras';
import { ServiceNote } from './ServiceNote';
import { UpdateCarData } from '../MyBook/ProductData/components/UpdateCarData';


export const CarRentalDetail = ({ id, type, showNetPrices, showCost, customFunctions, forceRender, saveProductData }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [carRentalDetail, setCarRentalDetail] = useState([]);

    const [modalId, setModalId] = useState("");
    const [resetValue, setResetValue] = useState(1);
    const [isEnabledUpdateStaticData, setIsEnabledUpdateStaticData] = useState(false);

    useEffect(() => {
        setResetValue(1);
        loadItem();

        setModalId("modalUpdateStaticDAta_" + id)
    }, []);

    useEffect(() => {
        if (forceRender && forceRender > 0) {
            loadItem();
            setResetValue(1);
        }
    }, [forceRender]);

    function loadItem() {
        setIsLoading(true);
        switch (type) {
            case 'MyBook':
            case 'QuotationTO_MyBook':
                const getCarRentalDetail = async (inputData) => {
                    const response = callGetCarRentalDetail(inputData);
                }
                getCarRentalDetail({ PNR: id, ProductType: configData.Settings.Products.CarRental.IdTipoProdotto, Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl } });
                break;
            case 'QuotationTO':
                const getQuotationItem = async (inputData) => {
                    const response = callGetQuotationItem(inputData);
                }
                getQuotationItem(id);
                break;
            default:
                setIsLoading(false);
        }
    }

    async function callGetCarRentalDetail(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (type === 'QuotationTO_MyBook' || type === 'MyBook')
                customFunctions.onCallSaveProductInfo(response, type, id);

            setCarRentalDetail(response);
            setIsError(false);
        } else {
            setErrorMsg(`La pratica (id: ${id}) non è momentaneamente disponibile.`);
            setIsError(true);
        }

        setIsLoading(false);
    }

    async function callGetQuotationItem(id) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationitem/${id}`, requestOption);


        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            customFunctions.onCallSaveProductInfo(response, type, id);

            setCarRentalDetail(response);
            setIsError(false);

            if (response.allowedOperations && response.allowedOperations.EnabledUpdateStaticData)
                setIsEnabledUpdateStaticData(response.allowedOperations.EnabledUpdateStaticData.enabled && (type !== 'QuotationTOEndUser' && type !== 'QuotationEndUser'));
        } else {
            setErrorMsg(t("MyBook:QuotaionProductNotLoadMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    function onClickOpenUpdateStaticData() {
        try {
            setResetValue((resetValue + 1));
        } catch (ex) { }
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : isError ? <Error textMsg={errorMsg} />
                    : (
                        <>
                            <div id={'aDetail_' + id}></div>

                            {/*Vertical Left Icon Bar*/}
                            {
                                type === 'MyBook'
                                    ? <div className="col-12 col-sm-10 col-md-6 col-lg-1 mb-4">
                                        <div className="timeline timeline-one timeline-mybook h-100">
                                            <div className="timeline-item h-100">
                                                <span className="icon icon-success"><i className="ph-car-bold h2"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }

                            {/*CarRental*/}
                            <div className={type === 'MyBook' ? "col-12 col-sm-10 col-md-6 col-lg-11 mb-4" : ""}>
                                <div className="col-12 col-sm-10 col-md-6 col-lg-12 mb-4">
                                    <div className={type === 'MyBook' ? "card border-light animate-up-5" : "card border-light mb-4 animate-up-5"}>
                                        <div className="row no-gutters">
                                            <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                                                <div className="row  no-gutters ">
                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <img src={carRentalDetail.imageURL ? carRentalDetail.imageURL : imageOnLoad(configData.Settings.Products.CarRental.IdTipoProdotto)} alt="Thumb" className="card-img p-2 rounded-xl" onError={e => imageOnError(e, configData.Settings.Products.CarRental.IdTipoProdotto)} />
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                        <div className="myBook-card-body">
                                                            <div className="mybook-rentCar">
                                                                <h5>
                                                                    {
                                                                        (type === 'QuotationTO' || type === 'QuotationTO_MyBook')
                                                                        && (customFunctions && !customFunctions.isAgencyMode)
                                                                        && (carRentalDetail.quotationDetail && carRentalDetail.priceBar.isManualLoading)
                                                                        && <span className="material-icons-outlined mx-1" title="Prodotto manuale" style={{ fontSize: '16px' }}>front_hand</span>
                                                                    }
                                                                    {
                                                                        type === 'QuotationTO' && <span className="badge bg-gray-300 text-dark mr-2">{id}</span>
                                                                    }
                                                                    {carRentalDetail.name}
                                                                </h5>
                                                                <p className="m-2">
                                                                    {
                                                                        carRentalDetail.rentalCompany !== null
                                                                            ? (<span><label>Company</label> {carRentalDetail.rentalCompany}</span>)
                                                                            : (<></>)
                                                                    }
                                                                </p>
                                                                <p className="m-2"><span><label>{t("Product:Activity:Option")}</label> {carRentalDetail.option.description}</span></p>
                                                                {
                                                                    (type === 'MyBook' || type === 'QuotationTO_MyBook')
                                                                        ? <p className="m-2"><span><label>Rif. Pax</label> {carRentalDetail.option.refPax}</span></p>
                                                                        : <></>
                                                                }
                                                            </div>
                                                            <div className="float-left">
                                                                {type === 'MyBook' && <OperationButtons pnr={id} productType={configData.Settings.Products.CarRental.IdTipoProdotto} status={carRentalDetail.priceBar.status} allowedOps={carRentalDetail.allowedOperations} />}
                                                                {
                                                                    type === 'QuotationTO' && <QuotationOperationButtons
                                                                        id={id}
                                                                        callOnChangeStatusQuotationItem={customFunctions.callOnChangeStatusQuotationItem}
                                                                        onCallRequoteQuotationItem={customFunctions.onCallRequoteQuotationItem}
                                                                        onCallUpdateQuotationItem={customFunctions.onCallUpdateQuotationItem}
                                                                        isCartQuotation={customFunctions.isCartQuotation}
                                                                        quotationMode={customFunctions.quotationMode}
                                                                        reservationDetail={carRentalDetail.reservationDetail}
                                                                        quotationDetail={carRentalDetail.quotationDetail} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mybook-rentCar-option">
                                                    <div className="row no-gutters">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                                                                    <h6>Pick Up:</h6>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                                                    {
                                                                        carRentalDetail.option.cancelPolicy && <span className={carRentalDetail.option.cancelPolicy.isFree ? "penalty free" : "penalty"}>
                                                                            <i className="ph-money-light"></i>
                                                                            {carRentalDetail.option.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(carRentalDetail.option.cancelPolicy.expirationDate, { twoDigits: true }) + " " + renderWebsite.cancelPolicy.time : "Cancellazione con penale"}
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-10 col-md-12 col-lg-12 col-xl-12">
                                                                <div>
                                                                    <span className="fas fa-map-marker-alt mr-2"></span><span><label>Pick Up Office:</label><span>{carRentalDetail.pickUpInfo.officeName}</span></span>
                                                                </div>
                                                                <div>
                                                                    <span className="fas fa-map-marker-alt mr-2"></span><span><label>{t("MyBook:Phone")}&nbsp; Office:</label><span>{carRentalDetail.pickUpInfo.officePhone}</span></span>
                                                                </div>
                                                                <div>
                                                                    <span className="fas fa-map-marker-alt mr-2"></span><span><label>{t("MyBook:Hour")}&nbsp; Pick Up:</label><span>{formatDateTime(carRentalDetail.pickUpInfo.pickupDateTime, cultureName, { withTime: true })}</span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-10 col-md-12 col-lg-12 col-xl-12">
                                                            <h6>Drop Off:</h6>
                                                            <div>
                                                                <span className="fas fa-map-marker-alt mr-2"></span><span><label>Drop Off Office:</label><span>{carRentalDetail.dropOffInfo.officeName}</span></span>
                                                            </div>
                                                            <div>
                                                                <span className="fas fa-map-marker-alt mr-2"></span><span><label>{t("MyBook:Phone")} Office:</label><span>{carRentalDetail.dropOffInfo.officePhone}</span></span>
                                                            </div>
                                                            <div>
                                                                <span className="fas fa-map-marker-alt mr-2"></span><span><label>{t('MyBook:Hour')}  Drop Off:</label><span>{formatDateTime(carRentalDetail.dropOffInfo.dropOffDateTime, cultureName, { withTime: true })}</span></span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 right">
                                                            <h2 className="accordion-header " id="heading1c">
                                                                <a className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={'#pannelCar' + id} aria-expanded="true" aria-controls={'pannelCar' + id}>
                                                                    <span className="icon-title h6 mb-0 font-weight-bold text-right w-100">{t("MyBook:CarAccordionTitle")} </span>
                                                                </a>
                                                            </h2>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <div id={'pannelCar' + id} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                                                <div className="accordion-body">
                                                                    <div className="pt-3">
                                                                        <h6>{t("MyBook:GeneralInformation")}</h6>
                                                                        <div>
                                                                            <span><label>SIPP Code:</label> <span>{carRentalDetail.sippCode}</span></span>
                                                                            <span><label>Luggages:</label> <span>{carRentalDetail.luggages}</span></span>
                                                                            <span><label>{t("MyBook:Paxes")}:</label> <span>{carRentalDetail.passengers}</span></span>
                                                                            <span><label>{t("MyBook:Door")}</label> <span>{carRentalDetail.doors}</span></span>
                                                                            <span><label>Excess:</label> <span>{carRentalDetail.information !== null && carRentalDetail.information.length > 0 ? carRentalDetail.information[0] : "--"}</span></span>
                                                                            <span><label>Fuel:</label> <span>{carRentalDetail.information !== null && carRentalDetail.information.length > 1 ? carRentalDetail.information[1] : "--"}</span></span>
                                                                            {carRentalDetail.flightInfo !== null ? (<span><label>Flight:</label> <span>{carRentalDetail.flightInfo}</span></span>) : (<></>)}
                                                                        </div>
                                                                        {
                                                                            (type === 'MyBook' || type === 'QuotationTO_MyBook')
                                                                                ? <>
                                                                                    <h6>{t("MyBook:RefPaxData")}</h6>
                                                                                    <div>
                                                                                        {<Passenger passenger={carRentalDetail.option.passenger} />}
                                                                                    </div>
                                                                                </>
                                                                                : <></>
                                                                        }
                                                                    </div>

                                                                    {/*Extras*/}
                                                                    {
                                                                        carRentalDetail.extras && carRentalDetail.extras.length > 0 && <div className="row pt-3">
                                                                            <div className="mb-2"><data m3lab="Product.RentCar.PayOnPickup">{t('Product:RentCar:PayOnPickup')}:</data></div>
                                                                            {
                                                                                carRentalDetail.extras.map((extra) => {
                                                                                    return <DMCExtras extra={extra} readOnly={true} payOnPickup={true} />;
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }

                                                                    {/*Extras OnSpot*/}
                                                                    {
                                                                        carRentalDetail.extrasOnSpot && carRentalDetail.extrasOnSpot.length > 0 && <div className="row pt-3">
                                                                            <div className="mb-2"><data m3lab="Product.RentCar.ExtraOnPickup">{t('Product:RentCar:ExtraOnPickup')}:</data></div>
                                                                            {
                                                                                carRentalDetail.extrasOnSpot.map((extra) => {
                                                                                    return <DMCExtras extra={extra} readOnly={true} payOnPickup={true} />;
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }

                                                                    {
                                                                        carRentalDetail.option.inclusions !== null
                                                                            ? (
                                                                                <div className="pt-3">
                                                                                    <h6>{t("Product:RentCar:Include")}</h6>
                                                                                    <div className="accordion border-light">
                                                                                        <h2 className="accordion-header " id="heading1c">
                                                                                            <a className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={'#pannelCarInclusions' + id} aria-expanded="true" aria-controls={'pannelCarInclusions' + id}>
                                                                                                <span className="icon-title h6 mb-0 font-weight-bold"></span>
                                                                                                <span className="icon"></span>
                                                                                            </a>
                                                                                        </h2>
                                                                                        <div id={'pannelCarInclusions' + id} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                                                                            <div className="accordion-body">
                                                                                                <div className="pt-3">
                                                                                                    {carRentalDetail.option.inclusions.map((inclusion, index) =>
                                                                                                        <p key={index}>{inclusion}</p>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            : (<></>)
                                                                    }

                                                                    {
                                                                        carRentalDetail.assurance !== null
                                                                            ? (
                                                                                <div className="pt-3">
                                                                                    <h6>Assicurazione:</h6>
                                                                                    <div className="accordion border-light">
                                                                                        <h2 className="accordion-header " id="heading1c">
                                                                                            <a className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={'#pannelCarAssicurazione' + id} aria-expanded="true" aria-controls={'pannelCarAssicurazione' + id}>
                                                                                                <span className="icon-title h6 mb-0 font-weight-bold">Informazioni Assicurazione</span>
                                                                                                <span className="icon"></span>
                                                                                            </a>
                                                                                        </h2>
                                                                                        <div id={'pannelCarAssicurazione' + id} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                                                                            <div className="accordion-body">
                                                                                                <div className="pt-3">
                                                                                                    <span><label>Prezzo:</label> <span>{formatPrice(carRentalDetail.assurance.amount, carRentalDetail.assurance.currency, cultureName)}</span></span>
                                                                                                    <p><span><label>Note:</label></span></p>
                                                                                                    <p dangerouslySetInnerHTML={{ __html: carRentalDetail.assurance.notes }} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            : (<></>)
                                                                    }

                                                                    {
                                                                        carRentalDetail.supplierTerms !== null
                                                                            ? (
                                                                                <div className="pt-3">
                                                                                    <h6>Termini e Condizioni:</h6>
                                                                                    <div className="accordion border-light">
                                                                                        <h2 className="accordion-header " id="heading1c">
                                                                                            <a className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={'#pannelCarConsegna' + id} aria-expanded="true" aria-controls={'pannelCarConsegna' + id}>
                                                                                                <span className="icon-title h6 mb-0 font-weight-bold">CONSEGNA E RITIRO</span>
                                                                                                <span className="icon"></span>
                                                                                            </a>
                                                                                        </h2>
                                                                                        <div id={'pannelCarConsegna' + id} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                                                                            <div className="accordion-body">
                                                                                                <div className="pt-3">
                                                                                                    {carRentalDetail.supplierTerms.map((term, index) =>
                                                                                                        <p key={index}>{term}</p>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            : (<></>)
                                                                    }

                                                                    {
                                                                        carRentalDetail.option.note && <div className="pt-3">
                                                                            <h6>Note</h6>
                                                                            <div className="accordion border-light">
                                                                                <span style={{ marginLeft: '20px', marginRight: '20px' }} dangerouslySetInnerHTML={{ __html: carRentalDetail.option.note }} />
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {
                                                                        <ServiceNote notes={carRentalDetail.notesService} noteTypeToShow={[12]} />
                                                                    }
                                                                    {
                                                                        <ServiceNote notes={carRentalDetail.notesService} noteTypeToShow={[13]} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/*Price Bar*/}
                                            <PriceBar
                                                oPriceBar={carRentalDetail.priceBar}
                                                showNetPrices={showNetPrices}
                                                showCost={showCost}
                                                reservationDetail={carRentalDetail.reservationDetail}
                                                quotationDetail={carRentalDetail.quotationDetail}
                                                quotationMode={customFunctions.quotationMode}
                                                customView={{ notShowPaymentAvailable: (type === 'QuotationTO' || type === 'QuotationTO_MyBook') }}
                                                isEnabledUpdateStaticData={isEnabledUpdateStaticData}
                                                onClickOpenUpdateStaticData={onClickOpenUpdateStaticData} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Modals*/}
                            {type === 'MyBook' && <CancelReservationModal pnr={id} productType={configData.Settings.Products.CarRental.IdTipoProdotto} />}
                            {type === 'MyBook' && carRentalDetail.priceBar.paymentData !== null && <PayWithCreditCardModal pnr={id} productType={configData.Settings.Products.CarRental.IdTipoProdotto} paymentData={carRentalDetail.priceBar.paymentData} />}
                            {isEnabledUpdateStaticData && <UpdateCarData
                                modalId={modalId}
                                quotationItemId={id}
                                saveProductData={saveProductData}
                                resetValue={resetValue} />}
                        </>
                    )
            }
        </>
    );
}