import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime, getStatusClass } from '../../../js/Utils.js';
import { imageOnError, imageOnLoad } from '../../../js/CDNUtility';
import { replaceAllStyleElements, getIconUrlToPrint, isNotesTooBig, getAllFirstUppercase, getFirstUppercase } from '../../../js/PrintUtility';
import {ServiceNotePrint} from "../service/ServiceNotePrint.js"

export const ActivityDetailPrint = ({ productSubType, activityDetail, isNotStep, isDocViaggio = false, isShowNote = false, isConfirmPrint }) => {
    const { t } = useTranslation();

    function getTitle(subType) {
        if (subType === "Activity")
            return t(`Product:Activity:Item`)
        else if (subType === "Transfer")
            return t(`Product:Transfer:Item`)
        else if (subType === "Flight")
            return t(`Product:Flight:Item`)
    }
    
    return (
        <>
            {/*Activity*/}
            {activityDetail &&
                <table className="w-100 border shadow">
                    <tbody>
                        <tr className="shadow">
                            <td className="widthTdImage">
                                <img src={`data:image;base64, ` + activityDetail.thumbUrlB} alt="Thumb" className="imgProducts" onError={imageOnError} />
                            </td>
                            <td className="w-80  px-3">
                                <table className="w-100">
                                    <tbody>
                                        <tr className="h6">
                                            <td colspan="2" className="fontBold">
                                                {getTitle(productSubType)}
                                            </td>
                                            <td className="text-right">
                                                {isConfirmPrint && activityDetail.priceBar && activityDetail.priceBar.wsBookingId &&
                                                    <>
                                                    N. PNR: {activityDetail.priceBar.wsBookingId}
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="h5">
                                            <td colspan="4" >
                                                {getAllFirstUppercase(activityDetail.name)}
                                            </td>
                                            <td className="align-top  text-right w-30">
                                                {isConfirmPrint &&
                                                    <span className={"h6 status " + getStatusClass(activityDetail.priceBar.status)}>{t(`statiPrenotazione:` + activityDetail.priceBar.status)}</span>
                                                }
                                            </td>
                                        </tr>
                                        {activityDetail.option && 
                                        <>
                                            <tr>
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('activity')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    <label>
                                                        {getFirstUppercase(activityDetail.option.optionName)}
                                                    </label>
                                                </td>
                                                <td className="w-30">
                                                    <td className="w-imagePdf">
                                                        <img src={getIconUrlToPrint('person')} className="ico-image mr-1" alt="" />
                                                    </td>
                                                    <td>
                                                        {activityDetail.option.refPax ?
                                                            <>
                                                                <label className="mr-1">Pax Rif.: </label>
                                                                <label>{activityDetail.option.refPax}</label>
                                                                <label>&nbsp;({activityDetail.option.adults + activityDetail.option.children} pax)</label>
                                                            </>
                                                                :
                                                            <>
                                                                <label>{activityDetail.option.adults}&nbsp;{t(`Template:Adults`)}&nbsp;</label>
                                                                <label>{activityDetail.option.children}&nbsp;{t(`Template:Children`)}&nbsp;</label>
                                                            </>
                                                        }
                                                    </td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    {activityDetail.option.notes && activityDetail.priceBar && activityDetail.priceBar.isDMC && isShowNote &&
                                                    <>
                                                        <div class="h075 text-left">{t(`General:Note`) }:</div>
                                                        {!isNotesTooBig(activityDetail.option.notes) ?
                                                            activityDetail.option.notes.map((note, index) => {
                                                                <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(note) }} /></p>
                                                            })
                                                        :
                                                            <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(activityDetail.option.notes[0].slice(0, 300)) }} /></p>
                                                        }
                                                    </>
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                        }
                                        {!isDocViaggio && activityDetail.option && activityDetail.option.cancelPolicy && 
                                            <tr>
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('localAtm')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    {activityDetail.option.cancelPolicy.isFree ?
                                                        <label className="text-success">{t(`CancelPolicy:FreeCancellation`)} {formatDateTime(activityDetail.option.cancelPolicy.expirationDate, { twoDigits: true })}</label>
                                                    :
                                                        <label className="penalty">{t(`CancelPolicy:PenaltyCancellation`)}</label>
                                                    }
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                       
                    </tbody>
                </table>
            }
            <div style={{ padding: "5px" }}>

                <ServiceNotePrint notes={activityDetail.notesService} noteTypeToShow={[isConfirmPrint ? 11 : 13]} />
            </div>
        </>
    );
}