import React from 'react';
import { formatDateTime } from '../../../../../js/Utils.js';
import { replaceAllStyleElements, getPaxGenderVoucher } from '../../../../../js/PrintUtility';
import { BookParameterId } from '../../../../../js/Constant';

export const PrintVoucherActivity = ({ productDetail, cultureName, t }) => {
    return (
        <>
            {productDetail &&
                <>
                    <div className="stepHeader text-center mt-2" style={{ borderRadius: "5px", padding: "7px" }}>
                        <h5 className="color-text-voucher">{t("Print:Voucher:Activity")} &nbsp;- &nbsp;{t("Print:Voucher:BookingNumber")}: {productDetail.option.providerRecordLocatorId}</h5>
                    </div>
                    {/*spazio*/}
                    <div style={{ padding: "7px" }}></div>

                    <div style={{ border: "1px solid grey", borderRadius: "5px" }}>
                        <table>
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <span><label>{t("Print:Voucher:Service")}:</label> {productDetail.name}</span>
                                </td>
                                <td style={{ textAlign: "left" }}>
                                    <span><label>{t("Print:Voucher:Date")}:</label> {formatDateTime(productDetail.priceBar.startDate, { twoDigits: true })}</span>
                                </td>
                                <td style={{ textAlign: "left" }}>
                                    <span><label>Total Pax:</label> {productDetail.option.passengers.length}</span>
                                </td>
                            </tr>
                            {productDetail.option.passengers.map(pax =>
                                <tr key={pax.index} style={{ textAlign: "left" }}>
                                    <span><label> {t("Print:Voucher:Pax")} {pax.index}: </label> {pax.fullName}</span> {getPaxGenderVoucher(pax, BookParameterId)} {pax.paxType !== "Adult" && pax.paxType}
                                </tr>
                            )}
                            {productDetail.option.providerReservationId &&
                                <tr>
                                    <td><span><label>{t("Print:Voucher:BookingNumber")}:</label> {productDetail.option.providerReservationId}</span></td>
                                </tr>
                            }
                        </table>
                    </div>

                    {/*spazio*/}
                    <div style={{ padding: "7px" }}></div>

                    <div className="stepHeader color-text-voucher" style={{ borderRadius: "5px", padding: "7px" }}>
                        <h5>{t("Print:Voucher:BookingDetailReservationNumber")}:&nbsp; {productDetail.priceBar.wsBookingId}</h5>
                    </div>

                    {productDetail.option.notes !== null && productDetail.option.notes !== undefined && productDetail.option.notes.length > 0 &&
                        <>
                            {/*spazio*/}
                            <div style={{ padding: "7px" }}></div>
                            <div style={{ border: "1px solid grey", borderRadius: "5px", }}>
                                <table>
                                    <tr>
                                        <td style={{ textAlign: "left" }}>
                                            <h5>Note</h5>
                                        </td>
                                    </tr>
                                </table>
                                <div style={{ maxWidth: "90%" }}>
                                    <ul>
                                        {productDetail.option.notes.map((note, index) =>
                                            <li key={index}>
                                                <span className="document-txt-note" dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(note) }} style={{ fontSize: '11px' }}></span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    );
}