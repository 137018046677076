import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { StructureTemplate } from "../product/StructureTemplate";
import { ActivityTemplate } from "../product/ActivityTemplate";
import { TourTemplate } from "../product/TourTemplate";
import { CarRentalTemplate } from "../product/CarRentalTemplate";
import { FlightTemplate } from "../product/FlightTemplate";
import { TransferTemplate } from "../product/TransferTemplate";
import { CruiseTemplate } from "../product/CruiseTemplate";
import { formatDateTime, createRenderHtml } from '../../../js/Utils';
import { TemplateInclusion, updateInfoSelectedProduct, updateTemplateDataLinked } from '../TemplateUtils';
import { FlightModal } from '../../Product/Flight/components/FlightModal';
import { FlightModalFareDetail } from '../../Product/Flight/components/FlightModalFareDetail';

export const TemplateStepProduct = ({ template, products, selectedProducts, setSelectedProducts, searchParameters }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [mapAlternativeProductsLoading, setMapAlternativeProductsLoading] = useState([]);
    const [isLoadingAlternative, setIsLoadingAlternative] = useState(true);

    const [modalBody, setModalBody] = useState("");
    const [modalOpened, setModalOpened] = useState(false);

    const [modalOpenFlightFare, setModalOpenFlightFare] = useState(null);
    const [modalOpenFareDetail, setModalOpenFareDetail] = useState(null);

    function getTemplateDataRangeDates(selProduct, altProducts) {
        let tData = selProduct && selProduct.length > 0 ? selProduct[0] : altProducts[0];

        let sRange = "";

        if (tData && tData.daysRange && tData.daysRange.length > 0) {
            let minDateRange = tData.daysRange[0] - 1;
            let maxDateRange = tData.daysRange[tData.daysRange.length - 1] - 1;

            let checkIn = searchParameters.checkIn;
            let newDate = new Date(checkIn);
            newDate = new Date(newDate.setDate(newDate.getDate() + minDateRange));

            sRange = `${formatDateTime(newDate, cultureName, { noYear: true })}`;

            if (minDateRange !== maxDateRange) {
                checkIn = searchParameters.checkIn;
                newDate = new Date(checkIn);
                newDate = new Date(newDate.setDate(newDate.getDate() + maxDateRange));

                sRange += ` - ${formatDateTime(newDate, cultureName, { noYear: true })}`;
            }
        }

        return sRange;
    }

    function onFinishSearch(tId, startSearch) {
        let altProducts = products.filter(x => selectedProducts.filter(y => y.TemplateDataId === x.templateDataId && y.Selected === false).length > 0);
        if (altProducts.length === 0) {
            // if there aren't alternatives, set the loading to false
            setIsLoadingAlternative(false);
        }

        // check if all alternatives finished to load
        let stepSelectedAltProducts = selectedProducts.filter(x => altProducts.filter(y => y.templateDataId === x.TemplateDataId).length > 0);
        if (stepSelectedAltProducts && stepSelectedAltProducts.length > 0 && stepSelectedAltProducts.map(x => x.HasFinished || x.IsError).every(x => x)) {
            setIsLoadingAlternative(false);
        }
    }
    const onSelectAlternative = (templateDataIdToAdd, templateDataIdToRemove, productSelection, productPriceDetail, carpetId, srcCarpetId) => {
        let selProduct = products.filter(x => selectedProducts.filter(y => y.TemplateDataId === x.templateDataId && y.Selected === true).length > 0);
        if (selProduct && selProduct.length > 0) {
            // sostituisco (=> setto selected=false su quello attualmente selezionato e selected=true su quello che mi arriva)
            let updSelectedProducts = updateInfoSelectedProduct(selectedProducts, templateDataIdToAdd, true, true, true, productSelection, true, productPriceDetail, true, carpetId, true, srcCarpetId, false, null, false, null);
            updSelectedProducts = updateInfoSelectedProduct(updSelectedProducts, templateDataIdToRemove, true, false, false, null, false, null, false, null, false, null, false, null, false, null);
            setSelectedProducts(updSelectedProducts);

            let updTemplateDataLinked = updateTemplateDataLinked(template, templateDataIdToAdd, true, updSelectedProducts);
            if (updTemplateDataLinked) setSelectedProducts(updTemplateDataLinked);
        }
        else {
            // aggiorno soltanto quello che ho selezionato
            let updSelectedProducts = updateInfoSelectedProduct(selectedProducts, templateDataIdToAdd, true, true, true, productSelection, true, productPriceDetail, true, carpetId, true, srcCarpetId, false, null, false, null);
            setSelectedProducts(updSelectedProducts);
        }
    }
    const onUnselectOptional = (templateDataId) => {
        // aggiorno soltanto quello che ho selezionato
        let updSelectedProducts = updateInfoSelectedProduct(selectedProducts, templateDataId, true, false, false, null, false, null, false, null, false, null, false, null, false, null);
        setSelectedProducts(updSelectedProducts);

        let updTemplateDataLinked = updateTemplateDataLinked(template, templateDataId, false, selectedProducts);
        if (updTemplateDataLinked) setSelectedProducts(updTemplateDataLinked);
    }
    const onUpdateSelectedInfoData = (templateDataId, carpetId, srcCarpetId, productSelection, productPriceDetail, hasFinished, isError) => {
        let updSelectedProducts = updateInfoSelectedProduct(selectedProducts, templateDataId, false, null, true, productSelection, true, productPriceDetail, true, carpetId, true, srcCarpetId, true, hasFinished, true, isError);
        setSelectedProducts(updSelectedProducts);
        checkAlternativeErrors();
    }

    const checkAlternativeErrors = () => {
        // group by ProductType
        let stepSelectedProducts = selectedProducts.filter(x => products.some(y => y.templateDataId === x.TemplateDataId));

        let grpProducts = Map.groupBy(stepSelectedProducts, product => { return product.ProductType; });
        if (grpProducts.size === 0)
            return;

        grpProducts.forEach((grpProduct, key) => {
            // 1. check if all products HasFinished and if there is at least one in error
            if (!grpProduct.every(x => x.HasFinished) || !grpProduct.some(x => x.IsError))
                return;

            // 2. check if products have Inclusion="AtLeastOne" 
            let tmpProducts = products.filter(x => grpProduct.some(y => y.TemplateDataId === x.templateDataId));
            if (tmpProducts[0].inclusion !== TemplateInclusion.AtLeastOne)
                return;

            // 3. if the product selected is in error, select the first of the alternatives
            let selProd = grpProduct.filter(x => x.Selected)[0];
            if (selProd.IsError) {
                let alternativesToBeSelected = grpProduct.filter(x => !x.Selected && !x.IsError);
                if (alternativesToBeSelected && alternativesToBeSelected.length > 0) {
                    let alternative = alternativesToBeSelected[0];

                    if (!modalOpened) {
                        setModalOpened(true);
                        try {
                            let oldProduct = products.filter(x => x.templateDataId === selProd.TemplateDataId)[0].product;
                            let newProduct = products.filter(x => x.templateDataId === alternative.TemplateDataId)[0].product;

                            let oldProductName = "";
                            let newProductName = "";
                            let msgBody = "";

                            if (oldProduct.productType === configData.Settings.Products.Flight.IdTipoProdotto) {
                                oldProductName = oldProduct.itineraryName;
                                newProductName = newProduct.itineraryName;

                                msgBody = `Il volo ${oldProductName} non è piu disponibile, abbiamo quotato un volo alternativo per te.`;
                            } else {
                                oldProductName = oldProduct.name;
                                newProductName = newProduct.name;

                                msgBody = `Il prodotto ${oldProductName} non è piu disponibile, abbiamo quotato un prodotto alternativo per te.`;
                            }

                            setModalBody(msgBody);

                            let myBtn = document.getElementById("open_TemplateStepProductAlert_" + products[0].templateDataId);
                            myBtn.click();
                        }
                        catch (ex) { }
                    }

                    onSelectAlternative(alternative.TemplateDataId, selProd.TemplateDataId, alternative.ProductSelection, alternative.ProductPriceDetail, alternative.CarpetId);
                }
            }
        });
    }

    const getSelectedProductData = (selProduct, altProducts, templateDataId) => {
        // ********************************************************************************************************
        // Generazione oggetti "selectedProductData" in base al tipo di prodotto che sto renderizzando:
        //
        // 1. se siamo su un prodotto "selezionato", l'oggetto contiene questi dati:
        //          SelectedInfo    = informazioni su se stesso (carpetId, etc.) utili per renderizzare ed effettuare le varie logiche
        //          AlternativeIds  = TemplateDataIds ai suoi prodotti in alternativa (per il momento non usato, puo tornare utile)
        //
        // 2. se siamo su un prodotto "in alternativa", l'oggetto contiene questi dati:
        //          AlternativeInfo     = informazioni su se stesso (carpetId, etc.) utili per renderizzare ed effettuare le varie logiche
        //          SelectedId          = TemplateDataId del prodotto selezionato
        //          SelectedTotalPrice  = prezzo del prodotto selezionato
        //
        // NOTA: è stato necessario differenziare SelectedInfo con AlternativeInfo per poter effettuare le logiche di selezione, chiamata di avl, etc.
        // ********************************************************************************************************
        let isSelProduct = selProduct && selProduct.length > 0 && selProduct.filter(x => x.templateDataId === templateDataId).length > 0;
        let isAltProduct = altProducts && altProducts.length > 0 && altProducts.filter(x => x.templateDataId === templateDataId).length > 0

        if (isSelProduct) {
            let selectedProductData = { SelectedInfo: null, AlternativeIds: [] };
            selectedProductData.SelectedInfo = selectedProducts.filter(y => y.TemplateDataId === selProduct[0].templateDataId)[0];

            if (altProducts && altProducts.length > 0)
                selectedProductData.AlternativeIds = altProducts.map((ap, idx) => { return ap.templateDataId });

            return selectedProductData;
        }
        else {
            let matchAltProduct = altProducts.filter(x => x.templateDataId === templateDataId)[0];

            let selectedProductData = {
                AlternativeInfo: null,
                SelectedId: selProduct && selProduct.length > 0 ? selProduct[0].templateDataId : null,
                SelectedTotalPrice: selProduct && selProduct.length > 0 ? selectedProducts.filter(x => x.TemplateDataId === selProduct[0].templateDataId)[0].ProductPriceDetail : null,
            };
            selectedProductData.AlternativeInfo = selectedProducts.filter(y => y.TemplateDataId === matchAltProduct.templateDataId)[0];

            return selectedProductData;
        }
    }

    const renderProducts = () => {
        let selProduct = products.filter(x => selectedProducts.filter(y => y.TemplateDataId === x.templateDataId && y.Selected === true).length > 0);
        let altProducts = products.filter(x => selectedProducts.filter(y => y.TemplateDataId === x.templateDataId && y.Selected === false && y.IsError === false).length > 0);

        if (altProducts && altProducts.length > 0) {
            let canOrderByPrice = false;
            for (let i = 0; i < altProducts.length; i++) {
                let matchAltProd = selectedProducts.filter(x => x.TemplateDataId === altProducts[i].templateDataId)[0];
                if (matchAltProd.ProductPriceDetail) {
                    // se arrivo qui, posso ordinare per prezzo
                    canOrderByPrice = true;
                    break;
                }
            }

            if (canOrderByPrice) {
                altProducts = altProducts.sort((a, b) => {
                    let selectedA = selectedProducts.filter(x => x.TemplateDataId === a.templateDataId)[0];
                    let selectedB = selectedProducts.filter(x => x.TemplateDataId === b.templateDataId)[0];
                    if (!selectedA.ProductPriceDetail && !selectedB.ProductPriceDetail) return 0;
                    if (!selectedA.ProductPriceDetail && selectedB.ProductPriceDetail) return selectedB.ProductPriceDetail.DisplayPrice;
                    if (!selectedB.ProductPriceDetail && selectedA.ProductPriceDetail) return selectedA.ProductPriceDetail.DisplayPrice;
                    return selectedA.ProductPriceDetail.DisplayPrice - selectedB.ProductPriceDetail.DisplayPrice;
                });
            }
        }

        return (
            <>
                {/*Date prodotto*/}
                <label className="mt-4 ml-2">

                    <var>
                        {altProducts && altProducts.length > 0
                            ? t(`Template:OptionalProducts`)
                            : (<>
                                <data m3lab="template.DayStep">{t(`Template:DayStep`)}</data>&nbsp;
                                {getTemplateDataRangeDates(selProduct, altProducts)}
                            </>)
                        }
                    </var>
                </label>

                <div className="card border-gray-600 my-4 animate-up-5 shadow rounded-0">
                    <div className="row no-gutters">

                        {/*Prodotto principale*/}
                        {selProduct && selProduct.length > 0 &&
                            selProduct.map((sp, idx) =>
                                <React.Fragment key={idx}>
                                    {sp.productType === configData.Settings.Products.Structure.IdTipoProdotto &&
                                        <StructureTemplate
                                            templateData={sp}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, sp.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={false}
                                            isOptional={false}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                        />
                                    }
                                    {sp.productType === configData.Settings.Products.Tour.IdTipoProdotto &&
                                        <TourTemplate
                                            templateData={sp}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, sp.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={false}
                                            isOptional={false}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                        />
                                    }
                                    {sp.productType === configData.Settings.Products.Activity.IdTipoProdotto &&
                                        <ActivityTemplate
                                            templateData={sp}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, sp.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={false}
                                            isOptional={false}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                        />
                                    }
                                    {sp.productType === configData.Settings.Products.Flight.IdTipoProdotto &&
                                        <FlightTemplate
                                            templateData={sp}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, sp.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={false}
                                            isOptional={false}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                            setModalOpenFlightFare={setModalOpenFlightFare}
                                            setModalOpenFareDetail={setModalOpenFareDetail}
                                        />
                                    }
                                    {sp.productType === configData.Settings.Products.CarRental.IdTipoProdotto &&
                                        <CarRentalTemplate
                                            templateData={sp}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, sp.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={false}
                                            isOptional={false}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                        />
                                    }
                                    {sp.productType === configData.Settings.Products.Transfer.IdTipoProdotto &&
                                        <TransferTemplate
                                            templateData={sp}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, sp.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={false}
                                            isOptional={false}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                        />
                                    }
                                    {sp.productType === configData.Settings.Products.Cruise.IdTipoProdotto &&
                                        <CruiseTemplate
                                            templateData={sp}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, sp.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={false}
                                            isOptional={false}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                        />
                                    }
                                    {
                                        isLoadingAlternative && <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="px-2 py-3 my-2 bg-gray-200 rounded">
                                                <div className="spinner-border spinner-border-sm text-primary mr-2" role="status" style={{ 'fontSize': '12px' }}></div>
                                                Stiamo cercando delle alternative...
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            )
                        }

                        {/*Alternative*/}
                        {altProducts && altProducts.length > 0 &&
                            altProducts.map((ap, idx) =>
                                <React.Fragment key={idx}>
                                    {ap.productType === configData.Settings.Products.Structure.IdTipoProdotto &&
                                        <StructureTemplate
                                            templateData={ap}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, ap.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={ap.inclusion === TemplateInclusion.AtLeastOne}
                                            isOptional={ap.inclusion === TemplateInclusion.Optional}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                        />
                                    }
                                    {ap.productType === configData.Settings.Products.Tour.IdTipoProdotto &&
                                        <TourTemplate
                                            templateData={ap}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, ap.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={ap.inclusion === TemplateInclusion.AtLeastOne}
                                            isOptional={ap.inclusion === TemplateInclusion.Optional}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                        />
                                    }
                                    {ap.productType === configData.Settings.Products.Activity.IdTipoProdotto &&
                                        <ActivityTemplate
                                            templateData={ap}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, ap.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={ap.inclusion === TemplateInclusion.AtLeastOne}
                                            isOptional={ap.inclusion === TemplateInclusion.Optional}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                        />
                                    }
                                    {ap.productType === configData.Settings.Products.Flight.IdTipoProdotto &&
                                        <FlightTemplate
                                            templateData={ap}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, ap.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={ap.inclusion === TemplateInclusion.AtLeastOne}
                                            isOptional={ap.inclusion === TemplateInclusion.Optional}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                            setModalOpenFlightFare={setModalOpenFlightFare}
                                            setModalOpenFareDetail={setModalOpenFareDetail}
                                        />
                                    }
                                    {ap.productType === configData.Settings.Products.CarRental.IdTipoProdotto &&
                                        <CarRentalTemplate
                                            templateData={ap}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, ap.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={ap.inclusion === TemplateInclusion.AtLeastOne}
                                            isOptional={ap.inclusion === TemplateInclusion.Optional}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                        />
                                    }
                                    {ap.productType === configData.Settings.Products.Transfer.IdTipoProdotto &&
                                        <TransferTemplate
                                            templateData={ap}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, ap.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={ap.inclusion === TemplateInclusion.AtLeastOne}
                                            isOptional={ap.inclusion === TemplateInclusion.Optional}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                        />
                                    }
                                    {ap.productType === configData.Settings.Products.Cruise.IdTipoProdotto &&
                                        <CruiseTemplate
                                            templateData={ap}
                                            products={products}
                                            selectedProducts={selectedProducts}
                                            selectedProductData={getSelectedProductData(selProduct, altProducts, ap.templateDataId)}
                                            searchParameters={searchParameters}
                                            isAlternative={false}
                                            isOptional={false}
                                            onSelectAlternative={onSelectAlternative}
                                            onUpdateSelectedInfoData={onUpdateSelectedInfoData}
                                            onUnselectOptional={onUnselectOptional}
                                            onFinishSearch={onFinishSearch}
                                        />
                                    }
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
            </>
        );
    }


    return (
        <>
            {products && products.length > 0 && renderProducts()}

            {
                modalOpenFlightFare && <FlightModal flightFare={modalOpenFlightFare} modalCloseProductId={setModalOpenFlightFare} />
            }
            {
                modalOpenFareDetail && <FlightModalFareDetail flightFare={modalOpenFareDetail} modalCloseProductId={setModalOpenFareDetail} />
            }

            <a id={"open_TemplateStepProductAlert_" + products[0].templateDataId} data-bs-toggle="modal" data-bs-target={"#TemplateStepProductAlert_" + products[0].templateDataId} className="hidden"></a>
            <div className="modal fade" id={"TemplateStepProductAlert_" + products[0].templateDataId} tabIndex="-1" aria-labelledby={"TemplateStepProductAlert_" + products[0].templateDataId} aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal_SelectDiscountLabel">Cambio selezione prodotto</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row m-2">
                                <div className="col-12">
                                    {modalBody}
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}