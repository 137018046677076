
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { formatDateTime, formatPrice, getCurrentUserFromJwtToken } from '../../js/Utils.js';
import { replaceAllStyleElements, dynamicNotesDistincts, setCancelPolicy } from "../../js/PrintUtility";
import configData from "../../appsettings.json";
import { HeaderPDFPrint } from '../Custom/Print/HeaderPDFPrint.js';
import { ConfirmPrintTitle } from './../Documents/printConfirm/ConfirmPrintTitle.js';
import '../Custom/Print/PrintCssForHtml.css';
import { ConfirmPrintStepHeader } from '../Documents/printConfirm/ConfirmPrintStepHeader.js';
import { ConfirmPrintPaxes } from '../Documents/printConfirm/ConfirmPrintPaxes.js';
import { ConfirmPrintStepItem } from '../Documents/printConfirm/ConfirmPrintStepItem.js';
import { ConfirmPrintDiscount } from '../Documents/printConfirm/ConfirmPrintDiscount.js';
import { ConfirmPrintAncillaries } from '../Documents/printConfirm/ConfirmPrintAncillaries.js';
import { ConfirmPrintCommissionsDetail } from '../Documents/printConfirm/ConfirmPrintCommissionsDetail.js';
import { ConfirmPrintDynamicNotes } from '../Documents/printConfirm/ConfirmPrintDynamicNotes.js';
import { ConfirmTourStaticPrint } from '../Documents/printConfirm/ConfirmTourStaticPrint.js';
import { ConfirmPrintScadenziario } from '../Documents/printConfirm/ConfirmPrintScadenziario.js';
import { ConfirmPrintCancelPolicy } from './../Documents/printConfirm/ConfirmPrintCancelPolicy.js';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PrintPdfDocuments } from '../Common/PrintPdfDocuments';
import { printCssStyle } from './../Custom/Print/PrintCss';
import { downloadDocumentPDF } from "../../js/PrintUtility";
import { Loading } from '../Common/Loading';
import { Error } from '../Common/Error';
import { generateQuotationHTMLConfirm } from "./../Documents/service/ConfirmPrintService.js";


export const ConfirmBooking = () => {
    let { orderNumber } = useParams();
    let { cultureName } = useParams()
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [myHtml, setMyHtml] = useState([]);
    const [websiteId, setWebsiteId] = useState();
    const pdfFileName = t("Print:ConfirmBooking") + orderNumber;

    const [orders, setOrders] = useState([]);
    const [prodottiEnabled, setProdottiEnabled] = useState([]);


    const [extraInfoPrint, setExtraInfoPrint] = useState({});
    const [isFinishPrintPDF, setIsFinishPrintPDF] = useState(0);
    const [isDownloadPDF, setIsDownloadPDF] = useState(true);
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [itemStepPrint, setItemStepPrint] = useState([]);
    const [dynamicNotes, setDynamicNotes] = useState([]);
    const [scadenziario, setScadenziario] = useState({});
    const [dynamicNotesTop, setDynamicNotesTop] = useState([]);
    const [dynamicNotesStep, setDynamicNotesStep] = useState([]);
    const [isLoadingPrintCliente, setIsLoadingPrintCliente] = useState(false);

    const [template, setTemplate] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [notesPratica, setNotesPratica] = useState([]);

    // stati di stampa componenti
    const [isPrintHeaderImage, setIsPrintHeaderImage] = useState(true);
    const [isPrintHeaderDesc, setIsPrintHeaderDesc] = useState(true);
    const [isPrintItineraryResume, setIsPrintItineraryResume] = useState(true);
    const [isPrintDynamicNotesTop, setIsPrintDynamicNotesTop] = useState(true);
    const [isPrintPaxes, setIsPrintPaxes] = useState(true);
    const [isPrintAncillaries, setIsPrintAncillaries] = useState(true);
    const [isPrintInclusions, setIsPrintInclusions] = useState(true);
    const [isPrintCommissions, setIsPrintCommissions] = useState(true);
    const [isPrintDynamicNotes, setIsPrintDynamicNotes] = useState(true);
    const [isPrintStructureNotes, setIsPrintStructureNotes] = useState(true);
    const [isPrintScadenziario, setIsPrintScadenziario] = useState(true);
    const [isPrintCancelPolicy, setIsPrintCancelPolicy] = useState(true);
    const [isPrintNoteBig, setIsPrintNoteBig] = useState(true);
    const [isShowAfterAllCall, setIsShowAfterAllCall] = useState(true); // serve per ricaricare in caso di async lunghe

    // net prices
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);

    const [userAddToOrder, setUserAddToOrder] = useState(false);
    const [paxes, setPaxes] = useState([]);

    let ordersTmp = [];
    let arrProduct = [];
    let totPratiche = 0; // usato per contare il totale pratiche e proseguire dopo le chiamate asincrone
    var tmpDynamicNotesStep = [];

    useEffect(() => {
        localStorage.removeItem("oldIdFoundImage");

        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
        setWebsiteId(currentUser.websiteId);

        const getOrders = async (inputData) => {
            const response = callGetOrders(inputData);
        }

        const getScadenziario = async (inputData) => {
            const response = callGetScadenziario(inputData);
        }

        getScadenziario(orderNumber);

        if (currentUser.roles !== undefined) {
            if (!currentUser.roles.includes("FE_NonVisualizzaNetto")) setEnableShowNetPrices(true);
            if (currentUser.roles.includes("MB_AddToOrder")) setUserAddToOrder(true);
        }

        const getPaxes = async (inputData) => {
            const response = callGetPaxes(inputData);
        }

        getPaxes(orderNumber);

        setIsLoading(true);
        getOrders({ NumeroOrdine: orderNumber, pageNum: 1 });
    }, []);

    useEffect(() => {
        setIsLoadingPrint(false);
        setIsLoadingPrintCliente(false);
    }, [isFinishPrintPDF]);


    useEffect(() => {
        if (!isShowAfterAllCall)
            setIsShowAfterAllCall(true);
    }, [isShowAfterAllCall]);

    async function callGetTemplate(inputData) {
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Template/GetTemplate', requestOption);
        const response = await fetchedRes.json();

        if (response && response.success) {
            setTemplate(response.template);
            
            // set default selected products
            let tmpSelectedProducts = [];
            if (response.template.steps && response.template.steps.length > 0) {
                for (let i = 0; i < response.template.steps.length; i++) {
                    if (response.template.steps[i].templateData && response.template.steps[i].templateData.length > 0) {
                        for (let j = 0; j < response.template.steps[i].templateData.length; j++) {
                            if (response.template.steps[i].templateData[j].inclusion === 1 ||
                                (response.template.steps[i].templateData[j].inclusion === 2 && response.template.steps[i].templateData[j].isDefault === true)
                            ) {
                                tmpSelectedProducts.push(response.template.steps[i].templateData[j].templateDataId);
                            }
                        }
                    }
                }
            }
            setSelectedProducts(tmpSelectedProducts);
        }
        else {
            setIsError(true);
            console.error(response.errorMsg);
        }
    }

    async function callGetReservationDetail(inputData, step) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        try {
            const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);

            if (fetchedRes.ok) {
                const response = await fetchedRes.json();

                let productSubType = "";
                let idTipoProdotto = null;
                let productType = "";

                if (response.productType === configData.Settings.Products.Structure.IdTipoProdotto) {
                    productType = "Structure";
                    productSubType = "Hotel";
                    idTipoProdotto = configData.Settings.Products.Structure.IdTipoProdotto;
                }
                else if (response.productType === configData.Settings.Products.Tour.IdTipoProdotto) {
                    productSubType = "Tour";
                    productType = "Tour";
                    idTipoProdotto = configData.Settings.Products.Tour.IdTipoProdotto;
                }
                else if (response.productType === configData.Settings.Products.Activity.IdTipoProdotto) {
                    productType = "Activity";
                    if (response.productSubType === 12)
                        productSubType = "Tour";
                    else
                        productSubType = "Activity";

                    idTipoProdotto = configData.Settings.Products.Activity.IdTipoProdotto;
                }
                else if (response.productType === configData.Settings.Products.CarRental.IdTipoProdotto) {
                    productSubType = "CarRental";
                    productType = "CarRental";
                    idTipoProdotto = configData.Settings.Products.CarRental.IdTipoProdotto;
                }
                else if (response.productType === configData.Settings.Products.Transfer.IdTipoProdotto) {
                    productSubType = "Transfer";
                    productType = "Transfer";
                    idTipoProdotto = configData.Settings.Products.Transfer.IdTipoProdotto;
                }
                else if (response.productType === configData.Settings.Products.Flight.IdTipoProdotto) {
                    productSubType = "Flight";
                    productType = "Flight";
                    idTipoProdotto = configData.Settings.Products.Flight.IdTipoProdotto;
                }
                else if (response.productType === configData.Settings.Products.Train.IdTipoProdotto) {
                    productSubType = "Train";
                    productType = "Train";
                    idTipoProdotto = configData.Settings.Products.Train.IdTipoProdotto;
                }
                else if (response.productType === configData.Settings.Products.Cruise.IdTipoProdotto) {
                    productSubType = "Cruise";
                    productType = "Cruise";
                    idTipoProdotto = configData.Settings.Products.Cruise.IdTipoProdotto;
                }
                else {
                    productSubType = "Generic";
                    productType = "Generic";
                    idTipoProdotto = configData.Settings.Products.Generic.IdTipoProdotto;
                }

                if (response.priceBar &&
                    (
                        response.priceBar.status === "CONFIRMED" ||
                        response.priceBar.status === "ONREQUEST" ||
                        response.priceBar.status === "PRINTED" ||
                        response.priceBar.status === "REFUND" ||
                        response.priceBar.status === "PENALTY" ||
                        (response.priceBar.status === "MIX" && response.productType === configData.Settings.Products.Structure.IdTipoProdotto)
                    )
                ) {
                    arrProduct.push({
                        productSubType: productSubType,
                        productInfo: response,
                        productType: productType,
                    });
                }
                else {
                    totPratiche--;
                }

                if (totPratiche === arrProduct.length) {
                    if (arrProduct.length > 0) {
                        let orderArr = [];

                        // ordina pratica
                        ordersTmp[0].pratiche.map((pratica, index) => {
                            for (let i = 0; i < arrProduct.length; i++) {
                                if (arrProduct[i].productInfo.priceBar && arrProduct[i].productInfo.priceBar.wsBookingId === pratica.wsBookingId) {
                                    orderArr.push(arrProduct[i]);
                                    break;
                                }
                            }
                        });

                        let finalArr = getFinalArr(orderArr);
                        setItemStepPrint(finalArr);
                        
                    }
                    else {
                        setIsError(true);
                    }
                }


                if (arrProduct.length === 0) {
                    setIsError(true);
                }

                const getDynamicNote = async (inputData) => {
                    const response = callGetDynamicNote(inputData);
                }

                getDynamicNote({
                    cultureCode: cultureName,
                    websiteId: websiteId,
                    pageId: 13, // conferma
                    styleId: 4, // footer
                    productTypes: [idTipoProdotto],
                    productCode: response.productId,
                    checkInFrom: response.priceBar ? response.priceBar.startDate : null,
                    checkInTo: response.priceBar ? response.priceBar.endDate : null,
                    webDestinationIds: response.webDestinationIds ? response.webDestinationIds : null,
                    supplierId: response.supplierId ? response.supplierId : null,
                });

                const getDynamicNoteTop = async (inputData) => {
                    const response = callGetDynamicNoteTop(inputData);
                }

                getDynamicNoteTop({
                    cultureCode: cultureName,
                    websiteId: websiteId,
                    pageId: 13, // conferma
                    styleId: 3, // top
                    productTypes: [idTipoProdotto],
                    productCode: response.productId,
                    checkInFrom: response.priceBar ? response.priceBar.startDate : null,
                    checkInTo: response.priceBar ? response.priceBar.endDate : null,
                    webDestinationIds: response.webDestinationIds ? response.webDestinationIds : null,
                    supplierId: response.supplierId ? response.supplierId : null,
                });


                const getDynamicNoteStep = async (inputData, step) => {
                    const response = callGetDynamicNoteStep(inputData, step);
                }

                getDynamicNoteStep({
                    cultureCode: cultureName,
                    websiteId: websiteId,
                    pageId: 13,
                    styleId: 5,
                    productTypes: [idTipoProdotto],
                    productCode: response.productId,
                    checkInFrom: response.priceBar ? response.priceBar.startDate : null,
                    checkInTo: response.priceBar ? response.priceBar.endDate : null,
                    webDestinationIds: response.webDestinationIds ? response.webDestinationIds : null,
                    supplierId: response.supplierId ? response.supplierId : null,
                }, step);

                // note settate nella pratica dettaglio back office
                if (response.idPratica) {
                    var data = [];
                    data.push({ fieldName: "IdPratica", Value: response.idPratica.toString() });
                    data.push({ fieldName: "IdTipoNota", Value: "10" });

                    callGetNotesPratica(data, response.idPratica, "Nota Conferma"); // Note confirm

                    data = [];
                    data.push({ fieldName: "IdPratica", Value: response.idPratica.toString() });
                    data.push({ fieldName: "IdTipoNota", Value: "12" });

                    callGetNotesPratica(data, response.idPratica, "Nota Descrizione Prodotto"); // Descritpion product
                }
            }
            else { // se resituisce errore elimare pratica dal totale
                totPratiche--;
            }
        }
        catch (ex) {
            setIsError(true);
            console.log(ex);
        }

        setIsLoading(false);
    }

    async function callGetDynamicNote(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();
    
        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotes(arr => [...arr, response.dynamicNotes]);
        }
    }

    async function callGetNotesPratica(input, idPratica, nomeNota) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Document/GetNotes`, requestOption);
        const response = await fetchedRes.json();

        if (response != null && response.length > 0) {
            response.forEach(function (note) {
                note.idPratica = idPratica;
                note.title = nomeNota;
                setNotesPratica(arr => [...arr, note]);
            });
        }
    }


    async function callGetDynamicNoteTop(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotesTop(arr => [...arr, response.dynamicNotes]);
        }
    }

    async function callGetDynamicNoteStep(inputData, step) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {

            for (var i = 0; i < response.dynamicNotes.length; i++) {
                response.dynamicNotes[i]["step"] = step;
            
                if (tmpDynamicNotesStep.length > 0 && tmpDynamicNotesStep.find(x => x.noteId === response.dynamicNotes[i].noteId && x.step === step)) {
                }
                else {
                    tmpDynamicNotesStep.push(response.dynamicNotes[i]);
                }
            }

            // order by priority
            tmpDynamicNotesStep.sort(function (a, b) {
                return a.priority - b.priority;
            });

            setDynamicNotesStep(tmpDynamicNotesStep);
            setIsShowAfterAllCall(false);
        }
    }

    async function callGetOrders(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };
       
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/getorders', requestOption);
        const response = await fetchedRes.json();
        
        var arrayPratiche = [];
        
        if (response.orders.length === 0) {
            setIsError(true);
            return;
        }
        if (response.orders[0].templateId) {
            callGetTemplate({
                templateId: response.orders[0].templateId,
                cultureCode: cultureName
            });
        }
       
        response.orders[0].pratiche.map((pratica, index) => {
            if (pratica.isActive){
                arrayPratiche.push(pratica);
            }
        });
        response.orders[0].pratiche = arrayPratiche;

        setOrders(response.orders);
        ordersTmp = response.orders;

        setProdottiEnabled(response.idModuliEnabled);
        
        if (response.orders.length === 0)
            return;
        
        if (response.orders[0].pratiche.length === 0) {
            setIsError(true);
            return;
        }

        const getReservation = async (inputData, step) => {
            const response = callGetReservationDetail(inputData, step);
        }

        response.orders[0].pratiche.map(pratica => {
            totPratiche = response.orders[0].pratiche.length;
            let tipoPratica;

            if (pratica.tipoPratica === configData.Settings.Products.Structure.Code)
                tipoPratica = configData.Settings.Products.Structure.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Activity.Code)
                tipoPratica = configData.Settings.Products.Activity.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.CarRental.Code)
                tipoPratica = configData.Settings.Products.CarRental.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Transfer.Code)
                tipoPratica = configData.Settings.Products.Transfer.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Flight.Code)
                tipoPratica = configData.Settings.Products.Flight.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Train.Code)
                tipoPratica = configData.Settings.Products.Train.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Cruise.Code)
                tipoPratica = configData.Settings.Products.Cruise.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Generic.Code)
                tipoPratica = configData.Settings.Products.Generic.IdTipoProdotto;
            getReservation({
                PNR: pratica.wsBookingId,
                ProductType: tipoPratica,
                Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl },
            }, pratica.stepId);
        });

        setIsLoading(false);
    }

    async function callGetPaxes() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetOrderPaxes/' + orderNumber, requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setPaxes(response);
        };
    }

    async function callGetScadenziario(orderNumber) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}reservation/GetScadenziarioFromOrder/${orderNumber}`, requestOption);
        const response = await fetchedRes.json();

        if (response.rate && response.rate.length > 0) {
            let resultObj = response.rate.reduce(function (r, a) {
                let data = formatDateTime(a.expirePaymentDate, cultureName);
                r[data] = r[data] || [];
                r[data].push(a);

                return r;
            }, Object.create(null));

            var resultArr = Object.entries(resultObj);

            setScadenziario(resultArr);
        }

        setIsLoading(false);
    }

    function getFinalArr(quotationItemStepPrint) {
        var tempArrFlights = [];
        var tempArrOthers = [];

        for (var i = 0; i < quotationItemStepPrint.length; i++) {
            let qInfo = quotationItemStepPrint[i];
            
            if (qInfo.productSubType === 'Flight') {
                tempArrFlights.push(qInfo);
            }
            else {
                tempArrOthers.push(qInfo);
            }
        }

        return tempArrFlights.concat(tempArrOthers);
    }

    let isFirstService = true;
    function setFirstService(isFirst) {
        isFirstService = isFirst;
    }


    let isFirstFlight = true;
    function setFirstFlight(isFirst) {
        isFirstFlight = isFirst;
    }

    /* Totale viaggio - dettaglio commissioni */
    function getTotaleViaggio(marginItems) {
        let total = 0;
        let currency = 0;

        marginItems.forEach(function (item) {
            currency = item.currency;
            total = total + item.invoicePrice;
        });

        return formatPrice(total, currency, cultureName);
    }

    function dowloadPDFAfterRender(filename, url) {
        downloadDocumentPDF(filename, url);

        setIsFinishPrintPDF(isFinishPrintPDF + 1);
        setIsDownloadPDF(true);
    }

    function onClickPrintPDF() {
        setIsLoadingPrint(true);
        callPrintPDF();
    }

    function onClickPrintPDFCliente() {
        setIsLoadingPrintCliente(true);
        callPrintPDFCliente();
    }

    function callPrintPDF() {
        let myArr = generateQuotationHTMLConfirm(
            orderNumber,
            orders[0].bookDate,
            itemStepPrint,
            paxes,
            dynamicNotes,
            dynamicNotesTop,
            dynamicNotesStep,
            orders[0].pratiche,
            template,
            scadenziario,
            getCancelPolicyArr(),
            notesPratica,
            cultureName
        );

        setIsDownloadPDF(false);
        setMyHtml(myArr);
        setExtraInfoPrint({
            quotationId: orderNumber,
            paxes: paxes,
            startDate: orders[0].startDate,
            endDate: orders[0].endDate,
            titleFirstPage: 'CONFERMA DI PRENOTAZIONE',
        });
    }

    function callPrintPDFCliente() {
        let myArr = generateQuotationHTMLConfirm(
            orderNumber,
            orders[0].bookDate,
            itemStepPrint,
            paxes,
            dynamicNotes,
            dynamicNotesTop,
            dynamicNotesStep,
            orders[0].pratiche,
            template,
            scadenziario,
            getCancelPolicyArr(),
            notesPratica,
            cultureName,
            true
        );

        setIsDownloadPDF(false);
        setMyHtml(myArr);
        setExtraInfoPrint({
            quotationId: orderNumber,
            paxes: paxes,
            startDate: orders[0].startDate,
            endDate: orders[0].endDate,
            titleFirstPage: 'CONFERMA DI PRENOTAZIONE CLIENTE',
        });
    }

    function getCancelPolicyArr() {
        let cancelPolicyArr = [];

        if (!template) { // non è tempalte
            itemStepPrint.map(qInfo => {
                setCancelPolicy(qInfo, cancelPolicyArr, t);

            });
        }
        else if (template.steps && template.steps.length > 0) { // è tempalte
            template.steps.map((step, stepKey) => {
                itemStepPrint.filter(x => x.productInfo.stepId === step.step).map((item, itemKey) => {
                    setCancelPolicy(item, cancelPolicyArr, t);
                });
            });
        }

        return cancelPolicyArr;
    }

    var idArrNote = [];
    function showTextStep(note) {
        let hideText = false;

        if (!idArrNote.find(x => x.id === note.noteId && note.step === x.step)) {
            idArrNote.push({ id: note.noteId, step: note.step });
        }
        else {
            hideText = true;
        }

        return hideText;
    }

    var showImage = true;
    function getShowImageStep() {
        return showImage;
    }

    function setShowImageStep(isShow) {
        showImage = isShow;
    }
    
    return (
        <>
            <section className="confirmBookingSection bg-gray section section-lg pt-5">
                {itemStepPrint !== undefined && itemStepPrint !== null && itemStepPrint.length !== 0 ? 
                    <div className="container">
                        <div className="row document mb-3">
                            <div className="col-3">
                                <h4>Conferma Prenotazione</h4>
                            </div>
                            <div className="col-3 text-right pr-6">
                                {/* button download pdf */}
                                <a className="btn btn-primary text-white ml-3" onClick={onClickPrintPDF} >
                                    {!isLoadingPrint && <>
                                        <span className="far fa-file-pdf mr-2"></span>Stampa
                                    </>}
                                    {
                                        isLoadingPrint && <><div className="spinner-border spinner-border-sm text-white h7" role="status">
                                        </div>
                                        <span className="sr-only">Loading...</span>
                                        </>
                                    }
                                </a>
                            </div>
                            <div className="col-3 text-left">
                                <a className="btn btn-primary text-white ml-3" onClick={onClickPrintPDFCliente} >
                                    {!isLoadingPrintCliente && <>
                                        <span className="far fa-file-pdf mr-2"></span>Stampa Cliente
                                    </>}
                                    {
                                        isLoadingPrintCliente && <><div className="spinner-border spinner-border-sm text-white h7" role="status">
                                        </div>
                                        <span className="sr-only">Loading...</span>
                                        </>
                                    }
                                </a>
                            </div>
                            {!isDownloadPDF && <PDFDownloadLink className="lnkPDFDownload" document={<PrintPdfDocuments html={myHtml} title={""} stylesheet={printCssStyle} extraInfo={extraInfoPrint} />} fileName={pdfFileName} >
                                {({ blob, url, loading, error }) => {
                                    if (!loading && blob) {
                                        dowloadPDFAfterRender(pdfFileName, URL.createObjectURL(blob));
                                    }
                                }}
                            </PDFDownloadLink>
                            }
                        </div>
                        <div className="card border-light p-4 p-md-5 position-relative">
                            <HeaderPDFPrint />
                            <div className="mt-6 pr-6 pl-6" style={{display: 'grid'}}>
                                <ConfirmPrintTitle orderNumber={orderNumber} cultureName={cultureName} date={orders[0].bookDate} />
                            </div>
                            {/* passeggeri */}
                            <div className="mt-6" style={{ display: 'grid' }}>
                                <ConfirmPrintPaxes paxes={paxes} />
                            </div>
                            {/* note dinamiche top */}
                            <div className="mt-2" style={{ display: 'grid' }}>
                                {dynamicNotesTop && dynamicNotesTop.length > 0 &&
                                    <ConfirmPrintDynamicNotes
                                        notes={dynamicNotesDistincts(dynamicNotesTop)}
                                        cultureName={cultureName}
                                    />
                                }
                            </div>
                            {itemStepPrint.map(qInfo => <>
                                {qInfo.productSubType === 'Flight' && isFirstFlight && <>
                                    {setFirstFlight(false)}
                                    <div className="mb-3 mt-5" style={{ display: 'grid' }}>
                                        <ConfirmPrintStepHeader
                                            title="I VOSTRI VOLI"
                                            PNR={qInfo.productInfo.priceBar.supplierPNR}
                                            totaleViaggio=''
                                            iconName='voli'
                                            cultureName={cultureName}
                                        />
                                    </div>
                                    </>
                                }
                                {qInfo.productSubType !== 'Flight' && isFirstService && <>
                                        {setFirstService(false)}
                                        <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                            <ConfirmPrintStepHeader
                                                title="ELENCO SERVIZI"
                                                PNR=''
                                                totaleViaggio=''
                                                iconName='elenco_servizi'
                                                cultureName={cultureName}
                                            />
                                        </div>
                                    </>
                                }
                                {/* prodotti */}
                                {((qInfo.productSubType === 'Flight' && template) || (!template)) &&
                                    <div className="mb-3" style={{ display: 'grid' }}>
                                        <ConfirmPrintStepItem
                                            qInfo={qInfo}
                                            isPrintCliente={false}
                                            isNotesHide={false}
                                            dynamicNotesDistinct={dynamicNotesDistincts(dynamicNotes)}
                                        />
                                    </div>
                                }
                            </>
                            )}
                            { /* template */}
                            {isShowAfterAllCall && template && template.steps && template.steps.length > 0 && template.steps.map((step, stepKey) =>
                                <>
                                    {itemStepPrint.filter(x => x.productInfo.stepId === step.step && x.productType !== "Flight").map((item, itemKey) =>
                                        <>
                                            {itemKey === 0 &&
                                            <>
                                                <div className="mb-2" style={{ display: 'grid' }}>
                                                    <ConfirmPrintStepHeader
                                                        key={stepKey}
                                                        title={step.webDestinationName}
                                                        iconName=''
                                                        stepIndex={step.step}
                                                        minDate={step.dateFrom}
                                                        maxDate={step.dateTo}
                                                    />
                                                </div>
                                                {setShowImageStep(true)}
                                                {dynamicNotesStep && dynamicNotesStep.length > 0 &&
                                                    dynamicNotesStep.map(note =>
                                                        note.step === step.step &&
                                                        <>
                                                            <div className="mb-3" style={{ display: 'grid' }}>
                                                                <ConfirmPrintDynamicNotes
                                                                    notes={[note]}
                                                                    cultureName={cultureName}
                                                                    isShowImage={getShowImageStep()}
                                                                    hideText={showTextStep(note)}
                                                                />
                                                                {setShowImageStep(false)}
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                            }
                                            <div className="mb-3" style={{ display: 'grid' }}>
                                                <ConfirmPrintStepItem
                                                    key={itemKey}
                                                    qInfo={item}
                                                    isPrintCliente={false}
                                                    isNotesHide={false}
                                                    dynamicNotesDistinct={dynamicNotesDistincts(dynamicNotes)}
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            {/* sconto commerciale */}
                            <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                <ConfirmPrintDiscount
                                    recapItems={itemStepPrint}
                                    cultureName={cultureName}
                                />
                            </div>
                            {/* quote accessorie */}
                            <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                <ConfirmPrintAncillaries
                                    recapItems={itemStepPrint}
                                    cultureName={cultureName}
                                />
                            </div>
                            {/* TOTALE VIAGGIO - DETTAGLIO COMMISSIONI */}
                            <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                <ConfirmPrintStepHeader
                                    title="TOTALE VIAGGIO - DETTAGLIO COMMISSIONI"
                                    PNR=''
                                    totaleViaggio=''
                                    iconName='dettaglio_commissioni'
                                    cultureName={cultureName}
                                />
                            </div>
                            <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                <ConfirmPrintCommissionsDetail
                                    marginItems={orders[0].pratiche}
                                    totaleViaggio={getTotaleViaggio(orders[0].pratiche)}
                                    cultureName={cultureName}
                                />
                            </div>
                            {/* scadenza pagamenti (scadenziario) */}
                            <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                <ConfirmPrintStepHeader
                                    title="SCADENZE DI PAGAMENTO"
                                    PNR=''
                                    totaleViaggio=''
                                    iconName='cronologia'
                                    cultureName={cultureName}
                                />
                            </div>
                            <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                <ConfirmPrintScadenziario
                                    scadenziario={scadenziario}
                                    cultureName={cultureName}
                                />
                            </div>

                            {/* Cancel Policy */}
                            <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                <ConfirmPrintStepHeader
                                    title="DETTAGLIO PENALI PRODOTTO"
                                    PNR=''
                                    totaleViaggio=''
                                    iconName='elenco_servizi'
                                    cultureName={cultureName}
                                />
                            </div>
                            <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                <ConfirmPrintCancelPolicy
                                    cancelPolicyArr={getCancelPolicyArr()}
                                    cultureName={cultureName}
                                />
                            </div>
                            {/* note dinamiche */}
                            {dynamicNotes && dynamicNotes.length > 0 && <>
                                <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                    <ConfirmPrintStepHeader
                                        title="INFO UTILI"
                                        PNR=''
                                        totaleViaggio=''
                                        iconName='info_utili'
                                        cultureName={cultureName}
                                    />
                                </div>
                                {/* note prodotti lunghe */}
                                <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                    <ConfirmPrintDynamicNotes
                                        notes={dynamicNotesDistincts(dynamicNotes)}
                                        cultureName={cultureName}
                                    />
                                </div>
                            </>
                            }

                            {/* programma di viaggio, note extra */}
                            {itemStepPrint.map(qInfo => <>
                                {qInfo.productType === 'Activity' && qInfo.productInfo.option.descriptions.length > 0 &&
                                    qInfo.productInfo.option.descriptions.filter(x => x.isPrint).map((descToPrint, idx) =>
                                        <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                            <ConfirmTourStaticPrint
                                                descriptions={replaceAllStyleElements(descToPrint.description)}
                                                title={descToPrint.title}
                                                tourName={idx === 0 ? qInfo.productInfo.name : ''}
                                            />;
                                        </div>
                                    )
                                }
                                {/* note extra struttura */}
                                {qInfo.productType === 'Structure' && qInfo.productInfo.descriptions.length > 0 &&
                                    qInfo.productInfo.descriptions.filter(x => x.isPrint).map((descToPrint, idx) =>
                                        <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                            <ConfirmTourStaticPrint
                                                descriptions={replaceAllStyleElements(descToPrint.description)}
                                                title={descToPrint.title}
                                                tourName={idx === 0 ? qInfo.productInfo.name : ''}
                                            />;
                                        </div>
                                    )
                                }
                                {/* note extra tour */}
                                {qInfo.productType === 'Tour' && qInfo.productInfo.descriptions.length > 0 &&
                                    qInfo.productInfo.descriptions.filter(x => x.isPrint).map((descToPrint, idx) =>
                                        <div className="mb-3 mt-3" style={{ display: 'grid' }}>
                                            <ConfirmTourStaticPrint
                                                descriptions={replaceAllStyleElements(descToPrint.description)}
                                                title={descToPrint.title}
                                                tourName={idx === 0 ? qInfo.productInfo.name : ''}
                                            />;
                                        </div>
                                    )
                                }

                                {/* note pratica */}
                                {notesPratica && notesPratica.length > 0 && notesPratica.filter(x => x.idPratica === qInfo.productInfo.idPratica).map(note =>
                                    <div className="mt-3" style={{ display: 'grid' }}>
                                        <ConfirmTourStaticPrint
                                            titleStep={qInfo.productInfo.name}
                                            descriptions={note.nota}
                                            title={note.title}
                                        />
                                    </div>
                                )}
                            </>
                            )}
                        </div>
                    </div>
                    :
                    !isError ?
                        <Loading textMsg="Caricamento in corso..." />
                    :
                    <div className="text-center">
                        <Error textMsg="Ordine non disponibile" />
                    </div>
                }
            </section>
        </> 
    );
}
