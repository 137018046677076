import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime, getStatusClass } from '../../../js/Utils.js';
import { imageOnError, imageOnLoadTransfer } from '../../../js/CDNUtility';
import { getIconUrlToPrint, replaceAllStyleElements, isNotesTooBig, getAllFirstUppercase } from '../../../js/PrintUtility';
import { ServiceNotePrint } from '../service/ServiceNotePrint.js';


export const TransferDetailPrint = ({ id, type, isNotStep, transferDetail, isPrintCliente, isShowNote = false, isConfirmPrint }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            {/*Transfer*/}
            {transferDetail &&
                <table className="w-100 border shadow">
                    <tbody>
                        <tr className="shadow">
                            <td className="widthTdImage">
                                <img src={imageOnLoadTransfer()} alt="Thumb" className="imgProductTransfer" onError={imageOnError} />
                            </td>
                            <td className="w-80  px-3">
                                <table className="w-100">
                                    <tbody>
                                        <tr className="h6">
                                            <td colspan="2" className="fontBold">
                                                {t(`Product:Transfer:Item`)}
                                            </td>
                                            <td className="text-right">
                                                {isConfirmPrint && transferDetail.priceBar && transferDetail.priceBar.wsBookingId &&
                                                    <>
                                                    N. PNR: {transferDetail.priceBar.wsBookingId}
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="h5">
                                            <td colspan="4">
                                                {getAllFirstUppercase(transferDetail.name) }
                                            </td>
                                            <td className="col-2 text-right">
                                                {isConfirmPrint &&
                                                    <span className={"h6 status " + getStatusClass(transferDetail.priceBar.status)}>{t(`statiPrenotazione:` + transferDetail.priceBar.status)}</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-imagePdf">
                                                <img src={getIconUrlToPrint('hail')} className="ico-image mr-1" alt="" />
                                            </td>
                                            <td className="w-40">
                                                <label>{transferDetail.vehicle && transferDetail.vehicle.type}</label>
                                            </td>
                                            <td className="col-4">
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('person')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td className="w-30">
                                                    {transferDetail.refPax ?
                                                        <>
                                                            <label className="mr-1">Pax Rif.: </label>
                                                            <label>{transferDetail.refPax}</label>
                                                            {transferDetail.option &&
                                                                <label>&nbsp;({transferDetail.option.adults + transferDetail.option.children} pax)</label>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <label>{transferDetail.adults}&nbsp;{t(`Template:Adults`)}&nbsp;</label>
                                                            <label>{transferDetail.children}&nbsp;{t(`Template:Children`)}&nbsp;</label>
                                                        </>
                                                    }
                                                </td>
                                            </td>
                                           
                                        </tr>
                                        {transferDetail.outbound &&
                                        <>
                                            <tr>Tratta 1</tr>
                                            <tr>
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('carRental')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    <label>{formatDateTime(transferDetail.outbound.date, cultureName)}&nbsp;&nbsp;</label>
                                                    <label className="mr-1">{t(`Product:Transfer:ArrivalFrom`)}: &nbsp;</label>
                                                    <label className="font-weight-bold">{transferDetail.outbound.from}&nbsp;</label>
                                                    <label className="mr-1">{t(`Product:Transfer:ArrivalTo`)}: &nbsp;</label>
                                                    <label>{transferDetail.outbound.to}</label>
                                                </td>
                                            </tr>
                                        </>
                                        }
                                        {transferDetail.return &&
                                        <>
                                            <tr>Tratta 2</tr>
                                            <tr>
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('carRental')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    <label>{formatDateTime(transferDetail.return.date, cultureName)}&nbsp;&nbsp;</label>
                                                    <label className="mr-1">{t(`Product:Transfer:ReturnFrom`)}:&nbsp;</label>
                                                    <label className="font-weight-bold">{transferDetail.return.from}&nbsp;</label>
                                                    <label className="mr-1">{t(`Product:Transfer:ReturnTo`)}:&nbsp;</label>
                                                    <label>{transferDetail.return.to}</label>
                                                </td>
                                            </tr>
                                        </>
                                        }
                                        <tr>
                                            <td colspan="2">
                                                {transferDetail.notesService && transferDetail.priceBar && transferDetail.priceBar.isDMC && isShowNote &&
                                                <>
                                                    <div className="h075 text-left">{t(`General:Note`)}:</div>
                                                    <div>
                                                        <ServiceNotePrint notes={transferDetail.notesService} noteTypeToShow={[isConfirmPrint ? 11 : 13]} />
                                                    </div>
                                                </>
                                                }
                                            </td>
                                        </tr>
                                        {isConfirmPrint && transferDetail.cancelPolicy &&
                                            <tr>
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('localAtm')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    {transferDetail.cancelPolicy.isFree ?
                                                        <>
                                                            <label className="text-success">{t(`CancelPolicy:FreeCancellation`)} {formatDateTime(transferDetail.cancelPolicy.expirationDate, { twoDigits: true })}</label>
                                                        </>
                                                        :
                                                        <label className="penalty">{t(`CancelPolicy:PenaltyCancellation`)}</label>
                                                    }
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </>
    );
}