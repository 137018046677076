import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";


export const HomeVillaDettaglio = () => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));

    let params = new URLSearchParams(window.location.search);
    let villaId = params.get('villa');

    const [villa, setVilla] = useState(null);
    const [ospiti, setOspiti] = useState(0);
    const [date, setDate] = useState(null);
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [msg, setMsg] = useState("");

    const [resMessage, setResMessage] = useState("");

    const [isSendRequest, setIsSendRequest] = useState(false);
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const [validateInputData, setValidateInputData] = useState({
        isValidName: false,
        isValidEmail: false,
        isValidPhone: false,
        isValidDate: false,
        isValidOspiti: false,
    });


    function readJson() {
        let data = require('./Export_Ville.json');
        let tmpData = data.filter(x => x.codice === parseInt(villaId));

        if (tmpData.length > 0)
            setVilla(tmpData[0]);
    }

    useEffect(() => {
        readJson();
    }, [])

    useEffect(() => {
        if (Object.keys(validateInputData).some(k => validateInputData[k])) { // se sono tutti a true
            let requestParam = {
                cultureCode: cultureName,
                name: name,
                date: date,
                phone: phone,
                ospiti: ospiti,
                email: email,
                msg: msg,
                villaName: villa.nome
            };

            sendRequest(requestParam);
        }
        else {
            setIsLoadingButton(false);
        }
    }, [validateInputData])

    function getImage(villa) {
        return villa.gallerias.length > 0 ? "https://ikhbackend.evinapp.it/Files/Images/" + villa.gallerias[0].immagine : "https://ikhbackend.evinapp.it/Files/Images/22_011.jpg";
    }

    function normalizeText(text) {
        if (text) {
            var icon = "<i className=\"fa fa-check ml-3\" aria-hidden=\"true\"></i>";
            var res = text.replaceAll("\r\n", "<br/>").replaceAll("\n\n", "<br/>").replaceAll("\n\r", "<br/>").replaceAll("•", "<br/>" + icon);
            return res;
        }
        else
            return "";
    }


    function onSendRequest() {
        setIsSendRequest(true);
        setIsLoadingButton(true);

        let _validateInputData = { ...validateInputData };
        if (name) {
            _validateInputData.isValidName = true;
        }
        if (date) {
            _validateInputData.isValidDate = true;
        }
        if (phone) {
            _validateInputData.isValidPhone = true;
        }
        if (ospiti > 0) {
            _validateInputData.isValidOspiti = true;
        }
        if (email) {
            let isValidMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
            if (isValidMail) {
                _validateInputData.isValidEmail = true;
            }
        }

        setValidateInputData(_validateInputData);
    }

    async function sendRequest(requestParam) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}ville/sendEmail`, requestOption);
        debugger;
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response) {
                setResMessage("Mail inviata con successo.")
            }
            else {
                setResMessage("Si è verificato un problema.")
            }

            setIsLoadingButton(false);
        }
    }


    return (
        <>
            {villa &&
            <>
                <div style={{ display: "table", width: "100%" }} >
                    <div style={{ display: "table-row", backgroundImage: `url("https://ikh.villas/wp-content/uploads/2022/03/shutterstock_717580867.jpg.webp")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "450px" }} >
                        <div style={{ display: "table-cell", height: "450px", verticalAlign: "middle" }}>
                            <div className="text-white text-center" style={{ fontSize: "3rem", lineHeight: "3.5rem" }}> <br /> <b style={{ fontSize: "3.5rem" }} ></b></div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="villa-title-name" style={{ marginLeft: "10%", width: "80%", marginRight: "10%", borderTop: "6px solid #052b5e" }} >
                        <div className="h1 text-center pt-2" tyle={{ paddingTop: "30px" }}>
                            {villa.nome.replace("[GOING]", "").replace("(GOING)", "")}
                        </div>
                        <div className="text-center" style={{ paddingTop: "30px" }} >
                            <span className="material-icons">share_location</span>
                            <span className="h4">
                                {villa.nazione}, {villa.area},  {villa.citta}
                            </span>

                        </div>
                        <div className="row" style={{paddingBottom: "40px", paddingTop: "30px"}}>
                            <div className="col-lg-2 col-sm-12">
                                <p className="inline h6"><span className="material-icons-outlined h7 mr-1">villa</span>
                                    {villa.descCategoria}
                                </p>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <p className="inline h6"><span className="material-icons-outlined h7 mr-1">people</span>
                                    {villa.nOspiti} OSPITI
                                </p>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <p className="inline h6"><span className="material-icons-outlined h7 mr-1">bathtub</span>
                                    {villa.nBagni} BAGNI
                                </p>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <p className="inline h6"><span className="material-icons-outlined h7 mr-1">bedroom_parent</span>
                                    {villa.nLetti} CAMERE DA LETTO
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12" style={{lineHeight: "1.5rem", marginBottom: "40px"}}>
                            <div id="imageVilla" className="cursor-pointer" style={{ backgroundImage: `url(" ${getImage(villa)}")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "600px" }} data-bs-toggle="modal" data-bs-target="#galleryModal"></div>
                            <div className="h4" style={{ marginTop: "2rem" }}>
                                CARATTERISTICHE E SERVIZI
                            </div>
                            <div>
                                <label dangerouslySetInnerHTML={{ __html: normalizeText(villa.servizi) }} />
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div style={{ backgroundColor: "#cfe2ff", padding: "1rem", borderRadius: "10px" }}>
                                <div className="h5">Richiesta quotazione:</div>
                                <label style={{ color: "gray" }}>Compila il form per ricevere una quotazione dedicata per te.</label>
                                <div className="mt-2">
                                    <label style={{ fontWeight: "600" }}>Data inzizio</label>
                                    <input id="dateForm" style={{ width: "100%", padding: "0.75rem", borderRadius: "5px", border: (isSendRequest && !validateInputData.isValidEmail ? "1px solid red" : "none") }} className="bookparameter-mob" type="date" onChange={e => setDate(e.currentTarget.value) } />
                                </div>
                                <div>
                                    <input name="txtNome" type="text" id="txtNome" className="bookparameter-mob" placeholder="Nome" style={{ width: "100%", marginTop: "0.5rem", padding: "0.75rem", borderRadius: "5px", border: (isSendRequest && !validateInputData.isValidName ? "1px solid red" : "none") }} onChange={e => setName(e.currentTarget.value)} />
                                </div>
                                <div>
                                    <input name="txtTelefono" type="number" id="txtTelefono" className="bookparameter-mob" placeholder="Telefono" style={{ width: "100%", marginTop: "0.5rem", padding: "0.75rem", borderRadius: "5px", border: (isSendRequest && !validateInputData.isValidPhone ? "1px solid red" : "none") }} onChange={e => setPhone(e.currentTarget.value)} />
                                    <span className="parameterdefinput withlabel mt-1">
                                        <label style={{ fontWeight: "600" }}>Ospiti</label>
                                        <select name="DDLOspiti" className="form-select border-0 border-bottom" id="DDLOspiti" style={{ width: "100%", textAlign: "center", border: (isSendRequest && !validateInputData.isValidOspiti ? "1px solid red" : "none") }} onChange={e => setOspiti(e.currentTarget.value)} >
                                            <option value="0"></option>
                                            {Array.from(Array(12), (e, i) =>
                                                <option key={i + 1} value={i + 1}>{i + 1}</option>
                                            )}
                                        </select>
                                    </span>
                                </div>
                                <div>
                                    <input name="txtEmail" type="email" id="txtEmail" className="bookparameter-mob" required="" placeholder="Email" style={{ width: "100%", marginTop: "0.5rem", padding: "0.75rem", borderRadius: "5px", border: (isSendRequest && !validateInputData.isValidEmail ? "1px solid red" : "none") }} onChange={e => setEmail(e.currentTarget.value)} />
                                </div>
                                <div>
                                    <input name="txtMessaggio" type="text" id="txtMessaggio" className="bookparameter-mob" placeholder="Messaggio" style={{ width: "100%", marginTop: "0.5rem", padding: "0.75rem", borderRadius: "5px", border: "none" }} onChange={e => setMsg(e.currentTarget.value)} />
                                </div>
                                {resMessage === "" &&
                                    <button onClick={e => onSendRequest()} id="btnSearch" className="btn btn-success h6" type="submit" style={{ width: "100%", marginTop: "0.5rem", padding: "0.75rem", borderRadius: "5px", border: "none" }} >
                                    {
                                        !isLoadingButton ?
                                            <>Invia Richiesta</>
                                            :
                                            <div className="spinner-border spinner-border-sm text-light" role="status" style={{ 'fontSize': '9px' }}>
                                                <span className="sr-only"></span>
                                            </div>
                                    }
                                    </button>
                                }
                                {resMessage === "" ?
                                    <label style={{ color: "gray" }}>Riceverai copia della richiesta effettuata</label>
                                :
                                    <label className="pt-3">{resMessage}</label>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* modal foto */}
                <div className="modal fade" id="galleryModal" tabindex="-1" aria-labelledby="galleryModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="galleryModalLabel">{t('Button:AllPhonto')}</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div id="carouselGalleryIndicators" className="carousel slide" data-bs-ride="true">
                                    <div className="carousel-inner carousel-inner-custom text-center">
                                        {villa.gallerias && villa.gallerias.length > 0 && villa.gallerias.map((img, idx) =>
                                            <div className={"carousel-item " + (idx === 0 ? "active" : "")}>
                                                <img src={"https://ikhbackend.evinapp.it/Files/Images/" + img.immagine} className="img-fluid " />
                                            </div>
                                        )}
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselGalleryIndicators" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselGalleryIndicators" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    );
}