import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useAuth } from '../../Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../Common/Loading';
import { handleChangePax, renderProductBookParameters, renderPaxBookParameters, productNotAvailableClass_Opacity, productNotAvailableClass_Border } from './SharedFunctions';
import { formatDateTime } from '../../../js/Utils.js';
import { registerSessionQuotation } from '../../../js/QuotationUtils';
import { BookParameterMandatoryCssClass } from '../../../js/Constant';
import configData from "../../../appsettings.json";
import { AvailableFlight } from "../Available/AvailableFlight";
import { M3IconProductType } from '../../Common/M3IconProductType';
import { M3Pax } from "../../Common/M3Pax";

export const CartFlight = ({ cartItemId, isCartQuotation, userPermissions, cartPaxesNames, staticBookParams, productBookParams, handleBookParameters, handleReservationData, userLockBook, callTermsFinished, onOpenModalConfirmDelete }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const cultureName = cookies.get("CultureName");
    const { setQuotationInfoSession } = useAuth();

    // debug
    const [searchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isLoading, setIsLoading] = useState(true);
    const [cartItem, setCartItem] = useState([]);
    const [isOperationCanCreatePnr, setIsOperationCanCreatePnr] = useState(false);

    const [autoCallBookOperation, setAutoCallBookOperation] = useState(true);


    useEffect(() => {
        const getCartItem = async () => {
            callGetCartItem();
        }

        setIsLoading(true);
        getCartItem();
    }, []);

    useEffect(() => {
        callGetCartItem();

        try {
            let bpBookOperationIdx = productBookParams.ProductBookParameters.findIndex(x => x.id === "RES-PRT");
            let bpBookOperation = productBookParams.ProductBookParameters[bpBookOperationIdx];

            let canCreatePnr = bpBookOperation.validValues.findIndex(x => x.key === "R") > -1 && !userLockBook;
            setIsOperationCanCreatePnr(canCreatePnr);
        } catch (ex) { }

    }, [callTermsFinished]);

    useEffect(() => {
        if (cartItem && cartItem.passengers && cartPaxesNames && cartPaxesNames.length > 0) {
            for (let i = 0; i < cartItem.passengers.length; i++) {
                let pax = cartItem.passengers[i];
                let e = { target: { value: pax.index }, Index: pax.index };

                let ddlPaxId = `ci_${cartItem.cartItemId}_option_1_pax_${pax.index}_ddlPax`;
                let ddlPax = document.getElementById(ddlPaxId);
                if (ddlPax) {
                    ddlPax.value = pax.index;
                    handleChangePax(cartItem, cartPaxesNames, handleBookParameters, 1, pax.index, ddlPaxId, e);
                }
            }
        }
    }, [cartPaxesNames]);


    async function callGetCartItem() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetCartItem/' + cartItemId, requestOption);
        const response = await fetchedRes.json();

        setCartItem(response);
        setIsLoading(false);
    }

    const flightBookOperationOnChange = (e) => {
        let id = e.target.id;
        let val = e.target.value;

        // --- set label class ---
        var selectedLabelId = id === `rbFlightCreatePnr_${cartItemId}` ? `lblFlightCreatePnr_${cartItemId}` : `lblFlightPrintPnr_${cartItemId}`;
        var notSelectedLabelId = selectedLabelId === `lblFlightCreatePnr_${cartItemId}` ? `lblFlightPrintPnr_${cartItemId}` : `lblFlightCreatePnr_${cartItemId}`;
        let lblSelected = document.getElementById(selectedLabelId);
        let lblNotSelected = document.getElementById(notSelectedLabelId);

        lblSelected.classList.remove("btn-flight-operation");
        lblSelected.classList.add("btn-flight-operation-selected");

        lblNotSelected.classList.remove("btn-flight-operation-selected");
        lblNotSelected.classList.add("btn-flight-operation");
        // --- set label class ---

        if (val === "on") {
            let data = { BookParameters: [{ Id: "RES-PRT", Value: id === "rbFlightCreatePnr_" + cartItemId ? "R" : "P" }] };
            //handleBookParameters(cartItem.cartItemId, "PRODUCT", data);
            callUpdateFlightBookOp(cartItem.cartItemId, data, false);
        }
    }

    const renderAction = (pBookParmas) => {
        if (pBookParmas === null || pBookParmas === undefined ||
            pBookParmas.ProductBookParameters === null || pBookParmas.ProductBookParameters === undefined || pBookParmas.ProductBookParameters.length === 0) {
            return (<></>);
        } else if (cartItem && cartItem.isDmc) {
            return (<></>);
        }
        else {
            let bpBookOperationIdx = pBookParmas.ProductBookParameters.findIndex(x => x.id === "RES-PRT");
            let bpBookOperation = productBookParams.ProductBookParameters[bpBookOperationIdx];

            let canCreatePnr = bpBookOperation.validValues.findIndex(x => x.key === "R") > -1 && !userLockBook;
            let canPrintPnr = bpBookOperation.validValues.findIndex(x => x.key === "P") > -1 && !userLockBook;

            // if the user can do only one action, do not show the button and save book parameter
            if (canCreatePnr && !canPrintPnr) {
                let data = { BookParameters: [{ Id: "RES-PRT", Value: "R" }] };
                callUpdateFlightBookOp(cartItem.cartItemId, data, true);
                return (<></>);
            }

            if (!canCreatePnr && canPrintPnr) {
                let data = { BookParameters: [{ Id: "RES-PRT", Value: "P" }] };
                callUpdateFlightBookOp(cartItem.cartItemId, data, true);
                return (<></>);
            }

            return (
                <>
                    {canCreatePnr
                        ? (
                            <>
                                <input id={`rbFlightCreatePnr_${cartItemId}`} type="radio" className="btn-check" name={`rbFlightBookOperation_${cartItemId}`} autoComplete="off" onChange={e => flightBookOperationOnChange(e)} defaultChecked={canCreatePnr} />
                                <label id={`lblFlightCreatePnr_${cartItemId}`} className={`btn btn-sm ${canCreatePnr ? "btn-flight-operation-selected" : "btn-flight-operation"}`} htmlFor={`rbFlightCreatePnr_${cartItemId}`}>{t("MyBook:CreatePNR")}</label>
                            </>
                        )
                        : (<></>)
                    }

                    {canPrintPnr
                        ? (
                            <>
                                <input id={`rbFlightPrintPnr_${cartItemId}`} type="radio" className="btn-check btn-sm" name={`rbFlightBookOperation_${cartItemId}`} autoComplete="off" onChange={e => flightBookOperationOnChange(e)} defaultChecked={!canCreatePnr && canPrintPnr} />
                                <label id={`lblFlightPrintPnr_${cartItemId}`} className={`btn btn-sm ${!canCreatePnr && canPrintPnr ? "btn-flight-operation-selected" : "btn-flight-operation"}`} htmlFor={`rbFlightPrintPnr_${cartItemId}`}>{t("MyBook:DoEmet")}</label>
                            </>
                        )
                        : (<></>)
                    }
                </>
            );
        }
    }


    async function callUpdateFlightBookOp(ciId, data, fromAutoCall) {
        if (fromAutoCall && !autoCallBookOperation)
            return;

        if (fromAutoCall)
            setAutoCallBookOperation(false);

        let resPrt = data.BookParameters[0].Value;

        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/UpdateFlightBookOp/' + ciId + '/' + resPrt, requestOption);
        const response = await fetchedRes.json();

        setIsOperationCanCreatePnr(resPrt && resPrt === "R");

        if (response && response.updated) {
            handleBookParameters(ciId, "PRODUCT", data);
        }
    }

    async function callUpdateQuotationItemStatus(quotationId, quotationItemId) {
        let inputData =
        {
            QuotationId: quotationId,
            QuotationItemId: quotationItemId,
            Status: 70,
            LogNote: "Status changed from CartQuotation due to BookingTerm error",
            QuotationSettings: { BaseUrl: "" }
        };

        const requestOption =
        {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/UpdateQuotationItemStatus', requestOption);
        const response = await fetchedRes.json();

        let responseQuotation = await registerSessionQuotation(quotationId, quotationItemId, true, cartItemId);
        if (responseQuotation) {
            setQuotationInfoSession(responseQuotation.quotationInfo);
            window.location.href = responseQuotation.url;
        }
    }

    function onClickChangeProduct(url, quotationId, quotationItemId) {
        if (quotationId !== null && quotationId !== undefined && quotationItemId !== null && quotationItemId !== undefined) {
            callUpdateQuotationItemStatus(quotationId, quotationItemId);
        }
        else {
            window.location.href = url;
        }
    }

    function onClickDeleteNotAvailable() {
        onOpenModalConfirmDelete(cartItemId);
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <>
                        <div className={"card mt-3 p-3 " + productNotAvailableClass_Border(productBookParams)}>
                            <div className="card p-3">
                                <div className={productNotAvailableClass_Opacity(productBookParams)}>

                                    <div className="row">
                                        <div className="col-7">
                                            <M3IconProductType productType={cartItem.productType} externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                            <img src={cartItem.airlineLogo} alt={cartItem.validatingCarrier} />
                                            <span className="titlecard ml-1">{cartItem.name}</span>
                                        </div>
                                        <div className="col-2">
                                            <div class="w-100">
                                                <M3Pax adt={cartItem.adults}
                                                    chd={cartItem.children}
                                                    chdAges={cartItem.childrenAges}
                                                    inf={cartItem.infants}
                                                    infAges={cartItem.infantAges}
                                                    extraOptions={{ showInfant: true, iconClass: "icon-15" }} />
                                            </div>
                                        </div>
                                        <div className="col-3 text-right">
                                            {cartItem.status && cartItem.status === 'OnRequest' && <span className="text-warning h6 mb-0 small d-block"><var>{cartItem.status}</var></span>}
                                            {cartItem.supplier && <span className="text-info small d-block mt-2"><var>{cartItem.supplier.supplierName}</var></span>}
                                        </div>
                                    </div>

                                    {callTermsFinished &&
                                        <React.Fragment>
                                            <div className="d-inline float-end">
                                                <button className="btn btn-sm btn-danger" onClick={() => onClickDeleteNotAvailable(cartItemId)}>{t("Button:Delete")}</button>
                                            </div>

                                            <div className="btn-group float-end ml-4 mr-2">
                                                {renderAction(productBookParams)}
                                            </div>
                                        </React.Fragment>
                                    }
                                    <p><label>{formatDateTime(cartItem.dateFrom, cultureName)} - {formatDateTime(cartItem.dateTo, cultureName)}</label></p>


                                    {
                                        callTermsFinished && isOperationCanCreatePnr && <><div className="row">
                                            <div className="col-12 text-danger text-center my-2">
                                                Questo tasto opziona i posti voli, sarà poi necessario, una volta andato a buon fine l'ordine, emettere il biglietto entro la giornata, attraverso la funzione Emetti.
                                                I PNR non emessi in giornata saranno automaticamente cancellati dal sistema
                                            </div>
                                        </div>
                                        </>
                                    }
                                </div>
                                <div className={productNotAvailableClass_Opacity(productBookParams)}>
                                    {!callTermsFinished
                                        ? (<><Loading textMsg={t(`Cart:LoadingBookingTerms`)} isSmall={true} /></>)
                                        : (
                                            <>
                                                {productBookParams !== null && productBookParams !== undefined && productBookParams.IsAvailable
                                                    ? (
                                                        <>
                                                            {/*Passengers*/}
                                                            {cartItem.passengers.map(pax =>
                                                                <div key={pax.index} className="row">
                                                                    <div className="form-floating col-md-3">
                                                                        <select id={`ci_${cartItem.cartItemId}_option_1_pax_${pax.index}_ddlPax`} className="form-select border-0 border-bottom border-dark" aria-label="Fornitore" onChange={(e) => handleChangePax(cartItem, cartPaxesNames, handleBookParameters, 1, pax.index, `ci_${cartItem.cartItemId}_option_1_pax_${pax.index}_ddlPax`, e)}>
                                                                            <option value="-1">{t("Button:Select")}&nbsp; pax</option>
                                                                            {cartPaxesNames !== null && cartPaxesNames !== undefined && cartPaxesNames.length > 0
                                                                                ? (
                                                                                    cartPaxesNames.map(paxName =>
                                                                                        <option key={paxName.Index} value={paxName.Index}>{paxName.BpFirstName[0].Value} {paxName.BpLastName[0].Value}</option>
                                                                                    )
                                                                                )
                                                                                : (<></>)
                                                                            }
                                                                        </select>
                                                                        <label htmlFor={`ci_${cartItem.cartItemId}_option_1_pax_${pax.index}`}>
                                                                            <span className={BookParameterMandatoryCssClass.Mandatory}>{t("MyBook:Passenger")}&nbsp; {pax.paxType} {pax.index}:</span>
                                                                        </label>
                                                                    </div>

                                                                    {renderPaxBookParameters(cartItem, productBookParams, staticBookParams, handleBookParameters, 1, pax.index)}
                                                                </div>
                                                            )}

                                                            {/*Product Book Parameters*/}
                                                            {renderProductBookParameters(cartItem, productBookParams, handleBookParameters)}
                                                        </>
                                                    )
                                                    : (<></>)
                                                }
                                            </>
                                        )
                                    }
                                </div>

                                {/*Product Not Available*/}
                                {productBookParams !== null && productBookParams !== undefined && !productBookParams.IsAvailable
                                    ? (
                                        <AvailableFlight
                                            listUrl={productBookParams.TermsError_ListUrl}
                                            quotationId={productBookParams.QuotationId}
                                            quotationItemId={productBookParams.QuotationItemId}
                                            deleteFn={onClickDeleteNotAvailable}
                                            changeProductFn={onClickChangeProduct}
                                            mode="CART"
                                        />
                                    )
                                    : (<></>)
                                }
                            </div>

                            {/* --- DEBUG --- */}
                            {debug === "1"
                                ? (<><br /><br />{JSON.stringify(productBookParams)}</>)
                                : (<></>)
                            }
                            {/* --- DEBUG --- */}
                        </div>
                    </>
                )
            }
        </>
    )
}