import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { OsmTravelPlaceMap } from '../../TravelPlaceMap/OsmTravelPlaceMap';
import { formatPrice } from '../../../js/Utils';
import { searchCarpetParameter } from '../../../js/Constant';
import configData from "../../../appsettings.json";
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { ProductFilterByPrice } from '../Common/Filter/ProductFilterByPrice';
import { M3StructureStars } from '../../Common/M3StructureStars';
import { ProductFilter } from '../Common/Filter/ProductFilter';
import { ProductFilterByName } from '../Common/Filter/ProductFilterByName';
import { M3Icon } from "../../Common/M3Icon";
import { FilterAreaMap } from '../../TravelPlaceMap/FilterAreaMap';
import { StructureSearchEngine } from "../SearchEngine/StructureSearchEngine";
import L from "leaflet";
import { getStructureDetailLink } from '../../../js/ProductService.js';
import GeoJSONLayer from '../../TravelPlaceMap/GeoJSONLayer';


export const TourDetailMap = () => {
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const { t } = useTranslation();
    const [featureFiltered, setFeatureFiltered] = useState([]);
    const [mapArr, setMapArr] = useState(null);

    const [showFilterArea, setShowFilterArea] = useState(false);
    const [sizeInMeters, setSizeInMeters] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMap, setIsLoadingMap] = useState(true);
    const [isError, setIsError] = useState(false);
    const [items, setItems] = useState({});

    // mappe
    const geoJsonRef = useRef();
    const [map, setMap] = useState(null);

    //const productList = JSON.parse(localStorage.getItem("ProductList"));
    let params = new URLSearchParams(window.location.search);
    let tourId = params.get('aId');
    let clientFlowId = params.get('cfId');

    const [filterSelected, setFilterSelected] = useState({
        structureName: null,
        categories: [],
        mealplans: [],
        amenities: [],
        suppliers: [],
        rangePrice: null,
        pageNumber: 0,
        orderBy: searchCarpetParameter.hotel.orderBy
    });

    const [totalProduct, setTotalProduct] = useState(0);
    const [touchPoints, setTouchPoints] = useState(null);
    const [isErrorStaticData, setIsErrorStaticData] = useState(false);

    // filtri
    const [isResetSelectedItem, setIsResetSelectedItem] = useState(false);
    const [isOrderAZ, setIsOrderAZ] = useState(true);
    const [isChangeAzDisabled, setIsChangeAzDisabled] = useState(true);
    const [filterValues, setFilterValues] = useState(null);
    const [rangePriceInfo, setRangePriceInfo] = useState({
        min: "0",
        max: "5000",
        step: "50",
        currency: "EUR"
    });

    useEffect(() => {
        getStaticData();
    }, []);

    useEffect(() => {
        if (touchPoints && touchPoints.length > 0) {
            setIsLoading(false);

            setTotalProduct(touchPoints.length);

            let coordinatesLines = [];
            let featureArr = [];
            touchPoints.forEach(function (touchPoint, index) {
                coordinatesLines.push([touchPoint.longitude, touchPoint.latitude]);

                featureArr.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [touchPoint.longitude, touchPoint.latitude]
                    },
                    properties: {
                        StructureId: touchPoint.id,
                        StructureName: touchPoint.webDestinationName,
                        ItineraryPointId: index + 1,
                        ItineraryId: 1,
                    }
                });
            });

            featureArr.unshift({
                type: "Feature",
                properties: {
                    ItineraryId: 1,
                    ItineraryName: "Itinerario"
                },
                geometry: {
                    coordinates: coordinatesLines,
                    type: "LineString"
                }
            });

            setMapArr({
                type: "FeatureCollection",
                features: featureArr
            });
            setIsLoadingMap(true);
        }
        else {
            setMapArr(null);
        }
    }, [touchPoints]);


    const clusterMarkerPopup = (childMarkers) => {
        var htmlContainer = "<div>";
        htmlContainer += '<div style="display:block; max-height: 400px; width: 250px; overflow-x: hidden;" />';
        childMarkers.forEach(function (m) {
            if (m.feature?.properties?.StructureName) {
                htmlContainer += "<div><label>Name: " + m.feature.properties.StructureName + " </label></div>"
            }
            if (m.feature?.properties?.htmlDescriptionBox) {

                htmlContainer += m.feature.properties.htmlDescriptionBox;
            }
            htmlContainer += "<hr/>"
        });
        htmlContainer += "</div>";
        htmlContainer += "</div>";
        return htmlContainer;
    }

    const markerPopup = (feature) => {
        var htmlContainer = '<div data-id="'+ feature.properties.StructureId +'" class="divHotel cursor-pointer" style="display: block; max-height: 400px; width: 250px; overflow-x: hidden;" />';
        
        if (feature.properties.StructureThumbnail) {
            htmlContainer += "<div><img src='" + feature.properties.StructureThumbnail + "' alt='' height='250px'> </div>"
        }
        if (feature.properties?.StructureName) {
            htmlContainer += "<div class='mt-2'><h6>" + feature.properties.StructureName + " </h6></div>"
        }
        if (feature.properties?.Address) {
            htmlContainer += "<div class='mt-2'><span>" + feature.properties.Address + " </span></div>"
        }
        if (feature.properties?.BestPriceAmount) {
            htmlContainer += "<div class='mt-3 text-right'><h6>" + formatPrice(feature.properties.BestPriceAmount, feature.properties.BestPriceCurrency, cultureCode) + " </h6></div>"
        }

        if (feature.properties?.ItineraryName) {
            htmlContainer += "<div><label>Name: " + feature.properties.ItineraryName + " </label></div>"
        }

        if (feature.properties?.ItineraryPointId) {
            htmlContainer += "<div><label>Step: " + feature.properties.ItineraryPointId + " </label></div>"
        }

        htmlContainer += "</div>";
        return htmlContainer;
    }


    const updateFeatures = (features) => {
        setFeatureFiltered(features);
    }

    async function getStaticData() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}tour/GetTourData/${tourId}/${cultureCode}`, requestOption);
        const response = await fetchedRes.json();
        if (response.success) {
            setTouchPoints(response.tour.touchPoints);
            setIsLoading(false);
        } else {
            setIsErrorStaticData(true);
            console.error(response.errorMsg);
        }
    }


    function onChangeSortBy(event) {
        let orderByValue = event.target.selectedOptions[0].value;

        let filterSelectedUpdate = {
            ...filterSelected,
            orderBy: orderByValue
        };

        if (orderByValue === "0" || orderByValue === "1") {
            setIsChangeAzDisabled(true);
        }
        else {
            setIsChangeAzDisabled(false);
        }

        setFilterSelected(filterSelectedUpdate);
    }

    function handleClickPointOnMap() {
        setTimeout(function () {
            let elements = document.getElementsByClassName("divHotel");
            for (let i = 0; i < elements.length; i++) {
                elements[i].addEventListener("click", function (e) {
                    if (e.currentTarget.dataset.id) {
                        //clickSearch(parseInt(e.currentTarget.dataset.id));
                    }
                });
            }
        }, 200);
    }

    function handleClick(item) {
        const markerBounds = L.latLngBounds([item.latLng]);
        map.fitBounds(markerBounds);
        var marker = geoJsonRef.current.getLayers().find(x => x.options.id === item.id);
        if (!marker)
            return;
                
        if (marker.getLatLng) {
            const latLngs = [marker.getLatLng()];
            const markerBounds = L.latLngBounds(latLngs);
            map.fitBounds(markerBounds);
        } else if (marker.getBounds) {
            const markerBounds = marker.getBounds();
            map.fitBounds(markerBounds);
        }

        marker.openPopup();

        handleClickPointOnMap();
    }

    const renderProduct = (touchPoint, index) => {
        return (
            <>
                <div className="border rounded mb-2 p-1 cursor-pointer" data-id={touchPoint.id} onClick={() => handleClick(touchPoint)} >
                    <span style={{ fontSize: "13px" }}>{touchPoint.name} (step {index+1})</span>
                    <div className="d-flex justify-content-sm-between">
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            {isLoading && <div className="col-12"><Loading textMsg={t('Product:Structure:LoadingList')} /></div>}
            {!isLoading && isError && <div className="col-12"><Error textMsg={t('Product:Structure:Error')} /></div>}
            {!isLoading && !isError &&
                <div className="container mw-100" >
                    <div className="row">
                    </div>
                    <div className="row p-2">
                        <div className="col-3">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            <b><data m3lab="Product.Tour.TouchPointsList">{t(`Product:Tour:TouchPointsList`)}</data></b>
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body" style={{ overflowY: "scroll", height: "600px" }}>
                                            <div className="text-right">
                                                <label>{t("Product:Tour:TouchPoints")}: {totalProduct}</label>
                                            </div>
                                            {featureFiltered && featureFiltered.length > 0 && featureFiltered.map((touchPoint, index) =>
                                                <>
                                                    {index < 10 &&
                                                        renderProduct(touchPoint, index)
                                                    }
                                                </>
                                            )}
                                            {featureFiltered && featureFiltered.length > 10 &&
                                                <>
                                                    <a className="cursor-pointer pl-1" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1" style={{ textDecoration: "none" }}>
                                                        <data m3lab="General.More">{t("General:More")}</data>
                                                    </a>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="collapse multi-collapse" id="multiCollapseExample1">
                                                            {featureFiltered.map((touchPoint, index) =>
                                                                    <>
                                                                        {index >= 10 &&
                                                                            renderProduct(touchPoint, index)
                                                                        }
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-9 generalMapContainer" style={{zIndex: "0"}}>
                        {isLoadingMap && mapArr &&
                            <OsmTravelPlaceMap
                                geoJsonRef={geoJsonRef}
                                data={mapArr}
                                center={[45.6479389724, 8.7796935685]}
                                zoom={8}
                                scrollWheelZoom={true}
                                showFilterArea={showFilterArea}
                                sizeInMeters={sizeInMeters}
                                showClusterPopup={false}
                                updateFeatures={updateFeatures}
                                markerPopup={markerPopup}
                                showCluster={false}
                                map={map}
                                setMap={setMap}
                                clusterMarkerPopup={clusterMarkerPopup}
                                handleClickPointOnMap={handleClickPointOnMap}
                            />
                        }
                        {!isLoadingMap && mapArr &&
                            <Loading textMsg={t('Product:Structure:LoadingList')} />
                        }
                        {!isLoadingMap && !mapArr &&
                            <div className="text-center">
                                {t('MyBook:NothingData')}
                            </div>
                        }
                        </div>
                    </div>
                </div>
            }


        </>
    );
}