import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import configData from "../../../appsettings.json";
import { getDateObj } from "../../../js/Utils.js";

export const M3Time = ({ time, extraOptions, validDateRule, onSelectTime, productType }) => {
    const { t } = useTranslation();

    const [time_, setTime] = useState(null);

    useEffect(() => {
        if (time)
            setTime(time);
    }, [time]);


    function onSetTime(value) {
        setTime(value);
        onSelectTime(value);
    }

    return (
        <>
            <Calendar
                value={time_}
                onChange={(e) => onSetTime(e.value)}
                inline
                readOnlyInput
                timeOnly
                stepMinute={15} // Impostazione dello step a 15 minuti
                style={{ width: '100%' }}
            />
        </>
    );
}
