import React, { useState, useEffect } from 'react';
import { Loading } from '../../Common/Loading';
import configData from "../../../appsettings.json";
import { formatDateTime } from '../../../js/Utils.js';
import { Map } from '../../Common/Map';
import { DynamicNote } from '../DynamicNote';
import { BookingNote } from '../BookingNote';
import { replaceAllStyleElements, getPaxGenderVoucher  } from '../../../js/PrintUtility';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { BookParameterId } from '../../../js/Constant';

export const VoucherTourDetail = ({ id, customProductDetail, customDynamicNote, customBookingNote }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [productDetail, setProductDetail] = useState([]);
    const { t } = useTranslation();
    
    useEffect(() => {
        setIsLoading(true);
        const getProductDetail = async (inputData) => {

            const response = callGetProductDetail(inputData);
        }
        getProductDetail({ PNR: id, ProductType: configData.Settings.Products.Tour.IdTipoProdotto, Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl } });
    }, []);

    function onCustomDynamicNote(dynamicNote) {
        customDynamicNote(dynamicNote);
    }

    function onCustomBookingNote(bookingNote) {
        customBookingNote(bookingNote);
    }

    async function callGetProductDetail(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        const response = await fetchedRes.json();
        
        setProductDetail(response);
        customProductDetail(response);
        setIsLoading(false);
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    productDetail && productDetail.rooms && productDetail.rooms.length > 0 &&
                    <>
                        <div className="stepHeader text-center mt-2" style={{ borderRadius: "5px", padding: "7px" }}>
                            <h5 className="color-text-voucher">{t("Print:Voucher:Tour")} &nbsp;- &nbsp; {t("Print:Voucher:BookingNumber")}: {productDetail.rooms[0].providerRecordLocatorId}</h5>
                        </div>
                        {/*Testata Servizio*/}
                        <div className="card mt-3 border-gray-500">
                            <div className="m-2">
                                <h6>{productDetail.name}</h6>
                                <div className="m-0">{productDetail.address}</div>
                                <div className="m-0">
                                    {productDetail.phone && <span><label>
                                        <M3Icon iconName="Phone" externalClass="icon-15" hasLabel={false} /> {t("Print:Voucher:Phone")}:</label> {productDetail.phone}</span>}
                                    {productDetail.email && <span><label><i className="ph-envelope-simple-bold"></i>email: </label> {productDetail.email}</span>}
                                </div>
                                {productDetail.priceBar && productDetail.priceBar.supplier &&
                                    <div>
                                        <span><label>{t("Print:Voucher:Supplier")}: </label> {productDetail.priceBar.supplier} </span>
                                    </div>
                                }
                            </div>
                            {/*Rooms*/}
                            {
                                productDetail.rooms.map(room =>
                                    <div key={room.sequence} className="pb-1 border-top border-light m-2">
                                        <div><span><label>{t("Print:Voucher:Service")}:</label> {room.roomName}</span></div>
                                        {room.providerReservationId &&
                                        <>
                                            <span><label>{t("Print:Voucher:BookingNumber")}:</label> {room.providerReservationId}</span> &nbsp;
                                        </>
                                        }
                                        <span> <label>Ref. Pax:</label> {room.refPax}</span>
                                    </div>
                                )
                            }
                        </div>
                        {/*Close testata servizio*/}
                        <div className="stepHeader text-center mt-3 mb-2" style={{ borderRadius: "5px", padding: "7px" }}>
                            <h5 className="color-text-voucher">{t("Print:Voucher:BookingDetailReservationNumber")}:&nbsp;{productDetail.priceBar.wsBookingId}</h5>
                        </div>
                        <div className="mb-3 card" style={{ border: "1px solid grey", borderRadius: "5px" }}>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 document-print-4">
                                    <span><label>{t("Print:Voucher:BookingDays")}:</label> {productDetail.priceBar.numNights} {t("Print:Voucher:Nights")} / {productDetail.rooms.length} {t("Print:Voucher:Rooms")}</span>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 document-print-4">
                                    <span><label>{t("Print:Voucher:CheckIn")}: </label> {formatDateTime(productDetail.priceBar.startDate, { twoDigits: true })} </span>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 document-print-4">
                                    <span><label>{t("Print:Voucher:CheckOut")}: </label> {formatDateTime(productDetail.priceBar.endDate, { twoDigits: true })}</span>
                                </div>
                            </div>
                            {/*Rooms Detail*/}
                            {
                                productDetail.rooms.map(room =>
                                    <>
                                        <div key={room.sequence} >
                                            <div className="card document-bg-std x-100 text-left">
                                                <div className="mb-0 mt-2 ml-2">{t("Print:Voucher:Room")} {room.sequence}</div>
                                            </div>
                                            <div className="mr-4 ml-4">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <span><label>{t("Print:Voucher:TypeRoom")}: </label> {room.roomName}</span>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <span><label>{t("Print:Voucher:MealPlan")}: </label> {room.planName}</span>
                                                    </div>
                                                    {room.passengers && room.passengers.length > 0 && room.passengers.map(pax =>
                                                        <>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <span>
                                                                    <label>{pax.index} Pax: </label>
                                                                </span>
                                                                <span> {pax.fullName}</span> {pax.paxType !== "Adult" && pax.paxType}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            {(room.notes !== null && room.notes !== undefined && room.notes.length > 0 &&
                                                <>
                                                    <div>Note</div>
                                                    <div>
                                                        <ul>
                                                            {room.notes.map((note, index) =>
                                                                <li key={index}>
                                                                    <span className="document-txt-note" dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(note) }} style={{ fontSize: '11px' }}></span>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        {<BookingNote idPratica={productDetail.idPratica} customBookingNote={onCustomBookingNote} />}
                        {<DynamicNote pageId="11" idTipoProdotto={configData.Settings.Products.Tour.IdTipoProdotto} productDetail={productDetail} customDynamicNote={onCustomDynamicNote} />}

                        {/* MAPPA */}
                        {productDetail.longitude && productDetail.latitude &&
                            <div className="py-0 mt-4">
                                <div className="row">
                                    <div className="col-12">
                                        <Map longitude={productDetail.longitude} latitude={productDetail.latitude} />
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                )
            }
        </>
    );
}