import React from 'react';
import { getCDNLogoDocument } from '../../../js/CDNUtility';
import { getIconUrlToPrint } from '../../../js/PrintUtility';

export const HeaderPDFPrint = ({ headerElements, title, isPrintCliente }) => {

    function renderHtml(html) {
        if (html) {
            html = html.replaceAll('#TITLE#', title ? title : '');
            html = html.replaceAll('#LOGO#', getCDNLogoDocument());
            html = html.replaceAll('#EMAILIMG#', getIconUrlToPrint('email'));
            html = html.replaceAll('#PHONEIMG#', getIconUrlToPrint('phone'));
        }

        return html;
    }

    return (<>
        {
            headerElements && headerElements.map(item => {
                return <div dangerouslySetInnerHTML={{ __html: renderHtml(item.html) }}></div>
            })
        }
    </>
    )
}
