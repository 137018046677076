import React, { useState, useEffect, useCallback } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Common/Loading';
import { formatDateTime, formatPrice } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { FormRichiestaInterventoBooking } from './Form/FormRichiestaInterventoBooking';
import { FormSaveCart } from './Form/FormSaveCart';
import { FormSendMail } from './Form/FormSendMail';
import { FormChangeMarkupFee } from './Form/FormChangeMarkupFee';
import { FormAddMarkupFee } from './Form/FormAddMarkupFee';
import { M3Icon } from '../Common/M3Icon';
import { M3IconProductType } from '../Common/M3IconProductType';
import { ProductAllPrice } from '../Product/Common/Pricing/ProductAllPrice';

export const CartSummary = ({ enableShowNetPrices, showCost, originalAddedMkpFee, addictionalProducts, selectedAddProdIds, callTermsFinished, handleAddedMkpFee, handleModifyMkpFee, userPermissions, selectedBuoniScontoIds, productChangePolicyPrice }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const cultureName = cookies.get("CultureName");

    const [isLoading, setIsLoading] = useState(true);
    const [cartSummary, setCartSummary] = useState([]);
    const [showPriceBar, setShowPriceBar] = useState(false);

    const [showSaveToBooking, setShowSaveToBooking] = useState(false);
    const [richiestaBookOnSendRes, setRichiestaBookOnSendRes] = useState(null);

    const [showSaveAndPrint, setShowSaveAndPrint] = useState(false);
    const [showSendMailPay, setShowSendMailPay] = useState(false);
    const [showSendMail, setShowSendMail] = useState(false);
    const [richiestaBookOnSaveCart, setRichiestaBookOnSaveCart] = useState(null);
    const [richiestaBookOnSendMail, setRichiestaBookOnSendMail] = useState(null);

    const [showChangeMarkupFee, setShowChangeMarkupFee] = useState(false);
    const [showAddMarkupFee, setShowAddMarkupFee] = useState(false);
    const [showCalculatorMarkup, setShowCalculatorMarkup] = useState(false);
    const [showCalculatorFee, setShowCalculatorFee] = useState(false);
    const [addedMkpFee, setAddedMkpFee] = useState(null);


    useEffect(() => {
        setAddedMkpFee(originalAddedMkpFee);
    }, [originalAddedMkpFee]);

    useEffect(() => {
        const getCartSummary = async (inputData) => {
            const response = callGetCartSummary(inputData);
        }

        setIsLoading(true);
        getCartSummary({
            SelectedAddictionalProducts: selectedAddProdIds,
            AddictionalProducts: addictionalProducts,
            AddedMkpFee: addedMkpFee,
            AddictionalProductsSettings: { GroupMandatory: configData.Settings.Cart.AddictionalProductsGroupMandatory, GroupMandatoryDescription: t(`Cart:GroupMandatoryDescription`) },
            SelectedBuoniScontoIds: selectedBuoniScontoIds
        });

    }, [selectedAddProdIds, selectedBuoniScontoIds, productChangePolicyPrice]);

    useEffect(() => {
        setShowSaveToBooking(showSaveToBooking || (userPermissions && userPermissions.saveToBooking));
    }, [userPermissions])

    async function callGetCartSummary(inputData, callAddedMkpFee, callModifyMkpFee) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetCartSummary', requestOption);
        const response = await fetchedRes.json();

        setShowSaveToBooking(response.allowedOperations.SaveToBooking.enabled || (userPermissions && userPermissions.saveToBooking));
        setShowSaveAndPrint(response.allowedOperations.SaveAndPrintProposal.enabled);
        setShowSendMailPay(response.allowedOperations.SendMailPay.enabled);
        setShowSendMail(response.allowedOperations.SendMail.enabled);
        setShowChangeMarkupFee(response.allowedOperations.CalculatorChangeMarkupFee.enabled);
        setShowAddMarkupFee(response.allowedOperations.CalculatorAddMarkupFee.enabled);
        setShowCalculatorMarkup(response.allowedOperations.CalculatorShowMarkup.enabled);
        setShowCalculatorFee(response.allowedOperations.CalculatorShowFee.enabled);

        setCartSummary(response);
        setIsLoading(false);

        if (callAddedMkpFee && callAddedMkpFee === true) {
            handleAddedMkpFee(inputData.AddedMkpFee);
        }
        if (callModifyMkpFee && callModifyMkpFee === true) {
            handleModifyMkpFee();
        }
    }

    const handleShowPriceBar = async e => {
        setShowPriceBar(!showPriceBar);
    }

    async function callDeleteCart() {
        const requestOption = { method: 'DELETE', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/DeleteCart', requestOption);
        const response = await fetchedRes.json();

        if (response.deleted) {
            window.location.reload();
        }
    }

    // Funzionalita richiesta intervento booking
    function onSendRichiestaInterventoBooking(inputData) {
        inputData.addictionalProductSelected = selectedAddProdIds;
        inputData.addictionalProducts = addictionalProducts;
        inputData.addedMkpFee = addedMkpFee;

        saveRichiestaInterventoBooking(inputData);
    }

    async function saveRichiestaInterventoBooking(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'shoppingcart/SaveRichiestaInterventoBooking', requestOption);
        const response = await fetchedRes.json();

        if (response) {
            setRichiestaBookOnSendRes(response);
        } else {
            setRichiestaBookOnSendRes({ success: false, message: "Generic Error" });
        }
    }

    // open popup "RichiestaInterventoBooking"
    const openModalRichiestaInterventoBooking = useCallback(() => {
        let myBtn = document.getElementById("open_RichiestaInterventoBooking");
        myBtn.click();
    }, []);

    // Funzionalita salva e stampa
    function onSaveCartAndPrint(inputData) {
        inputData.addictionalProductSelected = selectedAddProdIds;
        inputData.addictionalProducts = addictionalProducts;
        inputData.addedMkpFee = addedMkpFee;

        saveCartHowQuotation(inputData, 'SAVE');
    }

    async function saveCartHowQuotation(inputData, operationType) {
        inputData.autoGenerateStepGroupBy = configData.Settings.QuotationTO.AutoGenerateStepGroupBy;
        inputData.addictionalProducts = addictionalProducts;

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'shoppingcart/savecartquotation', requestOption);
        const response = await fetchedRes.json();

        if (operationType === 'SEND') {
            if (response.quotationId) {
                window.location.href = `${configData.Settings.Base_Url}quotation/${response.quotationId}?opSend=1`;
            } else {
                setRichiestaBookOnSendMail({ success: false, message: "Generic Error" });
            }
        } else {
            if (response.quotationId) {
                window.location.href = `${configData.Settings.Base_Url}quotation/${response.quotationId}?opSend=1`;
            } else {
                setRichiestaBookOnSaveCart({ success: false, message: "Generic Error" });
            }
        }

    }

    // open popup "SalvaEStampa"
    const openModalSaveCart = useCallback(() => {
        let myBtn = document.getElementById("open_SaveAndPrintCart");
        myBtn.click();
    }, []);

    // Funzionalita salva e stampa
    function onSendMailAndSave(inputData) {
        inputData.addictionalProductSelected = selectedAddProdIds;
        saveCartHowQuotation(inputData, 'SEND');
    }

    // open popup "InviaMail"
    const openModalSendMail = useCallback(() => {
        let myBtn = document.getElementById("open_SendMail");
        myBtn.click();
    }, []);

    // ChangeMarkupFee
    const openModalChangeMarkupFee = useCallback(() => {
        let myBtn = document.getElementById("open_ChangeMarkupFeeCart");
        myBtn.click();
    }, []);
    const handleChangeMarkupFee = () => {
        callGetCartSummary({
            SelectedAddictionalProducts: selectedAddProdIds,
            AddictionalProductsSettings: { GroupMandatory: configData.Settings.Cart.AddictionalProductsGroupMandatory, GroupMandatoryDescription: t(`Cart:GroupMandatoryDescription`) }
        }, false, true);
    }

    // AddMarkupFee
    const openModalAddMarkupFee = useCallback(() => {
        let myBtn = document.getElementById("open_AddMarkupFeeCart");
        myBtn.click();
    }, []);
    const handleAddMarkupFee = (addProds) => {
        setAddedMkpFee(addProds);
        callGetCartSummary({
            SelectedAddictionalProducts: selectedAddProdIds,
            AddictionalProducts: addictionalProducts,
            AddictionalProductsSettings: { GroupMandatory: configData.Settings.Cart.AddictionalProductsGroupMandatory, GroupMandatoryDescription: t(`Cart:GroupMandatoryDescription`) },
            AddedMkpFee: addProds
        }, true, false);
    }

    const renderPaxes = (paxes) => {
        let numAdults = paxes[0].adults;
        let numChildren = paxes[0].children;

        if (paxes.length > 1) {
            for (let i = 1; i < paxes.length; i++) {
                numAdults += paxes[i].adults;
                numChildren += paxes[i].children;
            }
        }

        return (<label>{numAdults}&nbsp;{t("SearchEngine:Adults")}, {numChildren}&nbsp;{t("SearchEngine:Children")}</label>);
    }

    function callOpenDeleteCart() {
        try {
            let myBtn = document.getElementById("open_EmptyCart");
            myBtn.click();
        } catch (ex) { }
    }

    return (
        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-4 px-2">
            {isLoading
                ? (<div className="cart-summary-container bg-white pb-2 rounded"><Loading textMsg={t('MyBook:Loading')} /></div>)
                : cartSummary !== undefined && cartSummary.items !== null
                    ? (
                        <div className="cart-summary-container bg-white px-2 rounded">
                            <div className="w-100 text-center">
                                <div className="titlecard h5 d-inline-block">
                                    {cartSummary.quotationId !== null && cartSummary.quotationId !== undefined
                                        ? (
                                            <>
                                                <div><label>{t("MyBook:ConfirmQuotation")} N.</label> {cartSummary.quotationId}</div>
                                                <div></div>
                                                <div><label>N. &nbsp;{t("MyBook:Cart")} {cartSummary.cartId}</label></div>
                                            </>
                                        )
                                        : (
                                            <>
                                                <div><label>N. &nbsp;{t("MyBook:Cart")}</label></div>
                                                <div>{cartSummary.cartId}</div>
                                            </>
                                        )
                                    }
                                    <div>
                                        <label> {t("MyBook:From")}:&nbsp; {formatDateTime(cartSummary.minDate, cultureName, { noYear: true })}  &nbsp;{t("MyBook:To")}&nbsp; {formatDateTime(cartSummary.maxDate, cultureName, { noYear: true })}</label>
                                    </div>
                                </div>
                                <div className="d-inline-block">
                                    {
                                        enableShowNetPrices ? <button className={(showPriceBar ? "tp-btn-select border-0" : "border border-dark bg-white") + " p-1 px-2 pt-2 rounded ml-2"} m3rool="f_ShowPriceBar" onClick={_ => { handleShowPriceBar() }}>
                                            <data m3lab="Button.Net">
                                                {t("Button:Net")}
                                            </data>
                                        </button> : (<></>)
                                    }
                                </div>
                            </div>
                            <div>
                                <table className="w-100" style={{ tableLayout: 'fixed' }}>
                                    <tbody>
                                        {cartSummary.items.map((item, index) =>
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td className="titlecard align-top">
                                                        <M3IconProductType
                                                            productType={item.productType}
                                                            productSubtype={item.moduleId}
                                                            externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle"
                                                            hasLabel="true"
                                                        />
                                                    </td>
                                                    <td className="align-text-top text-right">
                                                        <div>{formatPrice(item.pricing.displayPrice, item.pricing.displayPriceCurrency, cultureName)}</div>
                                                        {showPriceBar && item.productSubType !== 99
                                                            ? (
                                                                <div className="basketsummaryDetPrice">
                                                                    <ProductAllPrice showPriceBar={showPriceBar} showCost={showCost} pricing={item.pricing} />
                                                                </div>
                                                            )
                                                            : (<></>)
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="text-break">
                                                        <p>{item.productName}</p>
                                                        {item.pdfUrl && <p><a href={item.pdfUrl} target="_blank">Vedi Pdf</a></p>}
                                                        {
                                                            item.pdfUrls && item.pdfUrls ? item.pdfUrls.map((pdfUrl, index) => {
                                                                return <React.Fragment key={index}>
                                                                    <p><a href={pdfUrl} target="_blank">{(index + 1)}. Vedi Pdf</a></p>
                                                                </React.Fragment>
                                                            }) : <></>
                                                        }
                                                        {item.dateFrom !== null
                                                            ? (<span>{formatDateTime(item.dateFrom, cultureName)} - {formatDateTime(item.dateTo, cultureName)}</span>)
                                                            : (<></>)
                                                        }
                                                        <br />
                                                        {item.paxes !== null && item.paxes.length > 0
                                                            ? (renderPaxes(item.paxes))
                                                            : (<></>)
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        <div className="mx-3 mt-2 mb-3">
                                                            <div className=" border-top border-gray-600 w-80 "></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )}

                                        {/*Gift Cards*/}
                                        {cartSummary.giftCards && cartSummary.giftCards.length > 0 && cartSummary.giftCards.map((item, index) =>
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td className="titlecard align-top">
                                                        <M3Icon iconName="Wallet" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" /> Gift Card
                                                    </td>
                                                    <td className="align-text-top text-right">
                                                        <div>{formatPrice(item.amount, item.currency, cultureName)}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="text-break">
                                                        <p>{item.code}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        <div className="mx-3 mt-2 mb-3">
                                                            <div className=" border-top border-gray-600 w-80 "></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )}

                                        {/*Cart Summary*/}
                                        <tr className="titlecard">
                                            <td>{t("General:TotPrice")}</td>
                                            <td className="align-text-top text-right">
                                                {formatPrice(cartSummary.pricing.displayPrice, cartSummary.pricing.displayPriceCurrency, cultureName)}
                                            </td>
                                        </tr>

                                        {showPriceBar
                                            ? (
                                                <tr>
                                                    <td colSpan="2" className="align-text-top  text-right">
                                                        <ProductAllPrice showPriceBar={showPriceBar} showCost={showCost} pricing={cartSummary.pricing} />
                                                    </td>
                                                </tr>
                                            )
                                            : (<></>)
                                        }
                                        <tr>
                                            <td colSpan="2">
                                                <div className=" mt-2 mb-3">
                                                    <div className=" border-top border-gray-600"></div>
                                                </div>
                                            </td>
                                        </tr>
                                        {
                                            cartSummary.pricingLocos && <>
                                                <tr className="titlecard">
                                                    <td>{t("General:TotPriceLoco")}</td>
                                                    <td className="align-text-top text-right">
                                                        {formatPrice(cartSummary.pricingLocos.displayPrice, cartSummary.pricingLocos.displayPriceCurrency, cultureName)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        <div className=" mt-2 mb-3">
                                                            <div className=" border-top border-gray-600"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div >
                                <button className="text-left my-1 w-100 btn btn-sm tp-btn-select py-2 py-2 " onClick={() => callOpenDeleteCart()}><M3Icon iconName="Delete" externalClass="h125 text-white mr-2 position-relative top-3" /> {t("MyBook:EmptyCart")}</button>

                                {showSaveToBooking && <button className="text-left my-1 w-100 btn btn-sm tp-btn-select py-2 py-2 " onClick={e => openModalRichiestaInterventoBooking()}><M3Icon iconName="Send" externalClass="h125 text-white mr-2 position-relative top-3" /> {t("MyBook:AskBooking")}</button>}
                                {showSaveAndPrint && <button className="text-left my-1 w-100 btn btn-sm tp-btn-select py-2 py-2 " onClick={e => openModalSaveCart()}><M3Icon iconName="Save" externalClass="h125 text-white mr-2 position-relative top-3" />{t("Button:SaveAndPrint")}</button>}
                                {(showSendMailPay || showSendMail) && <button className="text-left my-1 w-100 btn btn-sm tp-btn-select py-2 py-2 " onClick={e => openModalSendMail()}><M3Icon iconName="Send" externalClass="h125 text-white mr-2 position-relative top-3" />   {t("MyBook:SendQuotation")}</button>}
                                {callTermsFinished && showChangeMarkupFee && <button className="text-left my-1 w-100 btn btn-sm tp-btn-select py-2 py-2 " onClick={e => openModalChangeMarkupFee()}><M3Icon iconName="Edit" externalClass="h125 text-white mr-2 position-relative top-3" />
                                    {!showCalculatorFee && showCalculatorMarkup && <> {t("Button:UpdateMarkup")}</>}
                                    {showCalculatorFee && !showCalculatorMarkup && <> {t("Button:UpdateFee")}</>}
                                    {showCalculatorFee && showCalculatorMarkup && <> {t("Button:UpdateMarkupAndFee")}</>}
                                </button>}
                                {callTermsFinished && showAddMarkupFee && <button className="text-left my-1 w-100 btn btn-sm tp-btn-select py-2 py-2 " onClick={e => openModalAddMarkupFee()}><M3Icon iconName="Edit" externalClass="h125 text-white mr-2 position-relative top-3" />
                                    {!showCalculatorFee && showCalculatorMarkup && <> {t("Button:AddMarkup")}</>}
                                    {showCalculatorFee && !showCalculatorMarkup && <> {t("Button:AddFee")}</>}
                                    {showCalculatorFee && showCalculatorMarkup && <> {t("Button:AddMarkupAndFee")}</>}
                                </button>}
                            </div>
                            <div style={{ display: 'none' }}>
                                <button className="text-left my-1 w-100 btn btn-sm tp-btn-select py-2 py-2 ">
                                    <M3Icon iconName="Pdf" externalClass="h125 text-white mr-2 position-relative top-3" />
                                    {t("MyBook:SeePdf")}
                                </button>
                                <button className="text-left my-1 w-100 btn btn-sm tp-btn-select py-2 py-2 ">
                                    <M3Icon iconName="Save" externalClass="h125 text-white mr-2 position-relative top-3" />
                                    {t("MyBook:SaveModelTavel")}
                                </button>
                                <button className="text-left my-1 w-100 btn btn-sm tp-btn-select py-2 py-2 ">
                                    <M3Icon iconName="Preview" externalClass="h125 text-white mr-2 position-relative top-3" />
                                    {t("MyBook:LookItinerary")}
                                </button>
                            </div>

                        </div>
                    )
                    : <></>
            }

            {
                showSaveToBooking && !isLoading && <FormRichiestaInterventoBooking
                    onSend={onSendRichiestaInterventoBooking}
                    onSendResponse={richiestaBookOnSendRes}
                    minDate={cartSummary.minDate}
                    maxDate={cartSummary.maxDate}
                    isFromCart={true}
                />
            }

            {
                showSaveAndPrint && !isLoading && <FormSaveCart
                    onSend={onSaveCartAndPrint}
                    onSendResponse={richiestaBookOnSaveCart}
                    cartId={cartSummary.cartId}
                    isFromCart={true}
                />
            }

            {
                (showSendMailPay || showSendMail) && !isLoading && <FormSendMail
                    onSend={onSendMailAndSave}
                    onSendResponse={richiestaBookOnSendMail}
                    cartId={cartSummary.cartId}
                    allowRubrica={showSendMailPay}
                    isFromCart={true}
                />
            }

            {
                showChangeMarkupFee && !isLoading && <FormChangeMarkupFee
                    cartSummary={cartSummary}
                    showCalculatorMarkup={showCalculatorMarkup}
                    showCalculatorFee={showCalculatorFee}
                    handleChangeMarkupFee={handleChangeMarkupFee}
                />
            }

            {
                showAddMarkupFee && !isLoading && <FormAddMarkupFee
                    handleAddMarkupFee={handleAddMarkupFee}
                    showCalculatorMarkup={showCalculatorMarkup}
                    showCalculatorFee={showCalculatorFee}
                    addedMkpFee={addedMkpFee}
                />
            }

            <a id={"open_EmptyCart"} data-bs-toggle="modal" data-bs-target={"#modal_EmptyCart"}></a>
            <div className="modal fade" id={"modal_EmptyCart"} tabIndex="-1" aria-labelledby="modal_EmptyCartLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal_EmptyCartLabel">{t("MyBook:EmptyCart")}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {t("MyBook:EmptyCartMsg")}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t('Button:Close')}</button>
                            <button type="button" className="btn bt-sm tp-btn-search" onClick={_ => callDeleteCart()}>{t('Button:Confirm')}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}