import React from 'react';
import { getIconUrlToPrint } from "../../../../js/PrintUtility";
import Cookies from 'universal-cookie';
import { getCDNLogoDocument } from "../../../../js/CDNUtility";
import { BookParameterId } from '../../../../js/Constant';
import { formatDateTime } from '../../../../js/Utils.js';

export const HeaderFirstPagePDFPrint = ({ title, extraInfo }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    
    function getRefPax(paxes) {
        let pax = "";

        for (var i = 0; i < paxes.length; i++) {
            if (i === 0) {
                pax = paxes[i].bookParameters.filter(x => x.id === BookParameterId.Personal.FirstName)[0].value + ' ' +
                    paxes[i].bookParameters.filter(x => x.id === BookParameterId.Personal.LastName)[0].value;
                break;
            }
        }

        return pax;
    }
        
    return (<>
        <table>
            <tr className="col-lg-12">
                <td className="col-lg-4">
                    <img src={getCDNLogoDocument()} className="logo-header" alt="Going Logo" />
                </td>
                <td className="col-lg-4" style={{ borderLeft: '1px solid #DAB451', borderRight: '1px solid #DAB451', marginTop: '10px', }}>
                    <tr style={{ marginRight: "20px", marginLeft: "20px", }} >
                        <td className="badge-customer text-white" style={{ textAlign: 'center', paddingTop: '0px', paddingBottom: '0px' }}>
                            <div style={{fontSize: '7px',}}>CLIENTE</div>
                        </td>
                    </tr>
                    <tr style={{ marginRight: "20px", marginLeft: "20px", paddingTop: '0px', paddingBottom: '0px' }}>
                        <td style={{ fontSize: '8px', textAlign: 'center', marginTop: '-5px' }}>
                            {extraInfo && extraInfo.paxes &&
                                <h6>{getRefPax(extraInfo.paxes)}</h6>
                            }
                        </td>
                    </tr>
                    <tr style={{ marginRight: "20px", marginLeft: "20px", }}>
                        <td className="badge-customer text-white" style={{ textAlign: 'center', paddingTop: '0px', paddingBottom: '0px', }}>
                            <div style={{ fontSize: '7px' }}>DATA DEL VIAGGIO</div>
                        </td>
                    </tr>
                    <tr style={{ marginRight: "20px", marginLeft: "20px", paddingTop: '0px', paddingBottom: '0px' }}>
                        <td style={{ fontSize: '8px', textAlign: 'center', marginTop: '-5px' }}>
                            {extraInfo && extraInfo.startDate && extraInfo.endDate && 
                                <h6>Dal {formatDateTime(extraInfo.startDate, cultureName)} - AL {formatDateTime(extraInfo.endDate, cultureName)}</h6>
                            }
                        </td>
                    </tr>
                </td>
                <td className="col-lg-4">
                    {extraInfo.customerInfo && extraInfo.customerInfo.logoUrl &&
                        <img src={extraInfo.customerInfo.logoUrl} className="logo-header-agency" />
                    }
                </td>
            </tr>
            <tr>
                <td style={{ color: "#08123B", minHeight: '25px' }}>
                </td>
            </tr>
        </table>
    </>
    )
}
