import React from 'react';
import { formatDateTime } from '../../../../../js/Utils.js';
import { getIconUrlToPrint, replaceAllStyleElements, getPaxGenderVoucher } from '../../../../../js/PrintUtility';
import { BookParameterId } from '../../../../../js/Constant';

export const PrintVoucherTour = ({ t, productDetail, cultureName }) => {
    debugger;
    return (
        <>
            {productDetail && productDetail.rooms && productDetail.rooms.length > 0 &&
                <>
                    <div className="stepHeader color-text-voucher" style={{ borderRadius: "5px", padding: "7px" }}>
                        <h5>{t("Print:Voucher:Tour")}&nbsp;- &nbsp; {t("Print:Voucher:BookingNumber")}: {productDetail.rooms[0].providerRecordLocatorId}</h5>
                    </div>

                    {/*spazio*/}
                    <div style={{ padding: "7px" }}></div>

                    <div style={{ border: "1px solid grey", borderRadius: "5px" }}>
                        <table>
                            <tr className="m-2">
                                <td style={{ textAlign: "left" }}>
                                    <h5>{productDetail.name}</h5>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <h5>{productDetail.address}</h5>
                                </td>
                            </tr>
                            <tr>
                                {productDetail.phone &&
                                    <>
                                        <td style={{ textAlign: "left", maxWidth: "13px" }}>
                                            <img src={getIconUrlToPrint('phone')} alt="" className="ico-image" />
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            <span> {t("Print:Voucher:Phone")}: {productDetail.phone}</span>
                                        </td>
                                    </>
                                }
                                {productDetail.email &&
                                    <>
                                        <td style={{ fontSize: '16px', maxWidth: "13px" }}>
                                            <img src={getIconUrlToPrint('email')} alt="" className="ico-image" />
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            <span> email: {productDetail.email}</span>
                                        </td>
                                        <td style={{ width: "80%" }}>
                                        </td>
                                    </>
                                }
                            </tr>
                            <tr>
                                {productDetail.priceBar && productDetail.priceBar.supplier &&
                                    <>
                                        <td style={{ textAlign: "left" }}>
                                            <span><label>{t("Print:Voucher:Supplier")}: </label> {productDetail.priceBar.supplier} </span>
                                        </td>
                                        <td style={{ width: "80%" }}>
                                        </td>
                                    </>
                                }
                            </tr>
                            {/*Rooms*/}
                            {
                                productDetail.rooms.map(room =>
                                    <tr key={room.sequence} className="pb-1 border-top border-light m-2">
                                        <td>
                                            <tr style={{ borderTop: "1px solid #edf0f7" }}>
                                                <td style={{ textAlign: "left" }}>
                                                    <span><label>{t("Print:Voucher:Service")}: </label> {room.roomName}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>
                                                    {room.providerReservationId &&
                                                        <>
                                                            <span><label>{t("Print:Voucher:BookingNumber")}</label> {room.providerReservationId}</span> &nbsp;
                                                        </>
                                                    }
                                                    <span> <label>Ref. Pax:</label> {room.refPax}</span>
                                                </td>
                                            </tr>
                                        </td>
                                    </tr>
                                )
                            }
                        </table>
                    </div>

                    {/*spazio*/}
                    <div style={{ padding: "7px" }}></div>

                    <div className="stepHeader color-text-voucher" style={{ borderRadius: "5px", padding: "7px" }}>
                        <h5>{t("Print:Voucher:BookingDetailReservationNumber")}:&nbsp;{productDetail.priceBar.wsBookingId}</h5>
                    </div>

                    {/*spazio*/}
                    <div style={{ padding: "7px" }}></div>

                    <div style={{ border: "1px solid grey", borderRadius: "5px", }}>
                        <table>
                            <tr className="row">
                                <td style={{ textAlign: "left" }}>
                                    <span><label>{t("Print:Voucher:BookingDays")}: </label> {productDetail.priceBar.numNights} {t("Print:Voucher:Nights")} / {productDetail.rooms.length}  {t("Print:Voucher:Rooms")}</span>
                                </td>
                                <td style={{ textAlign: "left" }}>
                                    <span><label>{t("Print:Voucher:CheckIn")}: </label>{formatDateTime(productDetail.priceBar.startDate, { twoDigits: true })} </span>
                                </td>
                                <td style={{ textAlign: "left" }}>
                                    <span><label>{t("Print:Voucher:CheckOut")}: </label>{formatDateTime(productDetail.priceBar.endDate, { twoDigits: true })}</span>
                                </td>
                            </tr>
                            {/*Rooms Detail*/}
                            {
                                productDetail.rooms.map(room =>
                                    <>
                                        <tr key={room.sequence}>
                                            <td>
                                                <tr>
                                                    <td>
                                                        <h4>{t("Print:Voucher:Room")} {room.sequence}</h4>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>
                                                        <span><label>{t("Print:Voucher:TypeRoom")}: </label>{room.roomName}</span>
                                                    </td>
                                                    <td style={{ textAlign: "left" }}>
                                                        <span><label>{t("Print:Voucher:MealPlan")}: </label>{room.planName}</span>
                                                    </td>
                                                </tr>
                                                {room.passengers.map(pax =>
                                                    <tr key={pax.index} style={{ textAlign: "left" }}>
                                                        <span><label>{pax.index} Pax: </label>{pax.fullName} {pax.paxType !== "Adult" && pax.paxType} </span>
                                                    </tr>
                                                )}
                                            </td>
                                        </tr>
                                        {room.notes !== null && room.notes !== undefined && room.notes.length > 0 &&
                                            <>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>
                                                        <h4>Note</h4>
                                                    </td>
                                                </tr>
                                                <div style={{ maxWidth: "90%" }}>
                                                    <ul>
                                                        {room.notes.map((note, index) =>
                                                            <li key={index}>
                                                                <span className="document-txt-note" dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(note) }} style={{ fontSize: '11px' }}></span>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </>
                                        }
                                    </>
                                )
                            }
                        </table>
                    </div>
                </>
            }
        </>
    );
}