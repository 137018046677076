import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../../Common/Loading';
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { M3Icon } from '../../Common/M3Icon';
import { InputCalendarContainer } from '../../Common/Calendar/InputCalendarContainer';
import { TrainItemList } from '../../Product/Train/components/TrainItemList';
import { TrainSelectedItinerary } from '../../Product/Train/components/TrainSelectedItinerary';
import { TrainItinerySeatMap } from '../../Product/Train/components/TrainItinerySeatMap';
import { formatPrice } from '../../../js/Utils';


export const TrainChangeDateModal = ({ passengers, pnr, trainDetail, openChangeDatePanel }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null)
    const [textColorClass, setTextColorClass] = useState('text-black');
    const [extraOptionsCalendarOutbound, setExtraOptionsCalendarOutbound] = useState({ multiDate: false, numberOfMonths: 1, showTime: true, label: 'SearchEngine:DateFrom', labelPlaceHolder: 'SearchEngine:SingleDate' });
    const [trains, setTrains] = useState([])
    const [operationPostResponse, setOperationPostRepsponse] = useState(null)
    const [selectedPassengers, setSelectedPassengers] = useState([]);
    const [selectedLeg, setSelectedLeg] = useState(null)
    const [evalutatedData, setEvalutatedData] = useState(null)
    const [seatSelections, setSeatSelections] = useState([])
    const [showAllTrains, setShowAllTrains] = useState(false)
    const [selectDirection, setSelectDirection] = useState("Outbound")
    const [changePaxError, setChangePaxError] = useState(null)
    const [changePaxSuccess, setChangePaxSuccess] = useState(null)

    function onClickSelectDates(values) {
        setSelectDates({
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
        });

        setSelectTimes({
            timeFrom: values.timeFrom,
            dateTo: values.timeTo,
        });

    }

    const handlePassengerSelectionChange = (index, isChecked) => {
        if (isChecked) {
            setSelectedPassengers(prev => [...prev, index]);
        } else {
            setSelectedPassengers(prev => prev.filter(id => id !== index));
        }
    };


    const [selectDates, setSelectDates] = useState({
        dateFrom: null
    });
    const [selectTimes, setSelectTimes] = useState({
        timeFrom: null,
    });

    useEffect(() => {

        setChangePaxError(false);
        setChangePaxSuccess(false);

        getPostBookOperations()

        const modal = document.getElementById('change_date')
        const handleModalShown = () => console.log('listening');
        modal.addEventListener('shown.bs.modal', handleModalShown);

        return () => {
            modal.removeEventListener('shown.bs.modal', handleModalShown);
        };
    }, []);


    // Effect hook to set initial values based on trainDetail
    useEffect(() => {
        if (passengers && passengers.length > 0) {
            let arr = [];
            for (let i = 0; i < passengers.length; i++)
                arr.push(i);

            setSelectedPassengers(arr)
        }

    }, [passengers]);

    // Effect hook to set initial values based on trainDetail
    useEffect(() => {
        if (trainDetail?.outbound?.leg?.segments?.[0]?.departureDate) {
            const departureDateTime = new Date(trainDetail.outbound.leg.segments[0].departureDate);
            setSelectDates({
                dateFrom: departureDateTime.toISOString().split('T')[0],
            });
            setSelectTimes({
                timeFrom: departureDateTime.toTimeString().split(' ')[0].substring(0, 5),
            });
        }
        console.log(trainDetail)
    }, [trainDetail]);

    const confirmChangeDates = async () => {
        if (!selectedLeg || !pnr) {
            console.error("Required data for confirmation is missing.");
            return;
        }
        setIsLoading(true);

        // Assuming selectedLeg contains the selected train info including InternalId
        const requestBody = {
            PNR: pnr,
            WsBookingId: pnr, // Already available from props
            InternalId: selectedLeg.uniqueId, // Assuming this is the correct ID
            selectFares: selectedLeg.selectedFares.map(x => x.fareComponents.uniqueId),
            // If you have seat selections to send, include them in the request body as per API requirements
            SeatSelectionRq: { SeatSelections: [...seatSelections] }
        };

        try {
            const response = await fetch(`${configData.Settings.CommonApi_BaseUrl}Train/ConfirmChangeDates`, {
                method: 'POST',
                credentials: 'include', // Assuming cookies are needed
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            if (data.success) {
                setChangePaxError(false);
                setChangePaxSuccess(true);
                console.log("Change confirmed successfully", data);
                // Handle successful confirmation (e.g., updating state, showing a success message)
            } else {
                setChangePaxError(true);
                setChangePaxSuccess(false);
                console.error("Failed to confirm changes", data);
                // Handle failure (e.g., showing an error message)
            }
        } catch (error) {
            setChangePaxError(true);
            setChangePaxSuccess(false);
            console.error("Error confirming changes", error);
            // Handle network errors or other exceptions
        }

        setIsLoading(false);
    };


    async function getPostBookOperations() {
        setIsLoading(true);
        setErrorMsg(null)
        console.log(trainDetail)

        const requestBody = {
            PNR: pnr,
            OperationType: "ChangeDates",
        };

        try {
            const response = await fetch(`${configData.Settings.CommonApi_BaseUrl}Train/GetPostBookOperations`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setIsLoading(false);

            const data = await response.json();
            if (!data.success) {
                return setErrorMsg(`${t('MyBook:operationPostError')}`);
            }

            setOperationPostRepsponse(data)
        } catch (error) {
            console.error('Error:', error);
            // Handle errors, e.g., show error message
        }
    }

    async function onClickSearch(legUniqueId, selectFares, supplierId) {
        let selectedLeg = trains.filter(l => l.leg.uniqueId === legUniqueId && l.leg.supplierId === supplierId)[0];
        selectedLeg.selectedFares = selectFares;
        setSelectedLeg(selectedLeg)
        evaluateChangeDates(selectedLeg.uniqueId, selectFares)
    }

    const evaluateChangeDates = (internalId, selectFares) => {
        // Assuming you have wsBookingId and other required info available
        const requestBody = {
            PNR: pnr,
            selectFares: selectFares.map(x => x.fareComponents.uniqueId),
            WsBookingId: pnr, // You already have `pnr` from props
            InternalId: internalId, // This should be passed to this function when it's called
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}Train/EvaluateChangeDates`, {
            method: 'POST',
            credentials: 'include', // Assuming you need cookies for session management
            headers: {
                'Content-Type': 'application/json',
                // Add any other headers like authorization tokens if needed
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Evaluation success:', data);
                setEvalutatedData(data)
                // Handle successful evaluation here (update state, UI, etc.)
            })
            .catch(error => {
                console.error('Evaluation failed:', error);
                // Handle error condition here (show error message, etc.)
            });
    };


    function onSelectSeatOutbound(paxes) {
        setSeatSelections(paxes)
    }
    function changeDate() {
        setIsLoading(true)

        let arr = selectedPassengers.map(item => item + 1);
        if (!arr || arr.length === 0) {
            let arr = [];
            for (let i = 0; i < passengers.length; i++)
                arr.push(i + 1);
        }

        const changeDatesRequest = {
            WsBookingId: pnr,
            PNR: pnr,
            TravelersIndex: arr,
            NewDate: selectDates.dateFrom,
            NewTime: selectTimes.timeFrom,
            Direction: selectDirection
        };
        fetch(`${configData.Settings.CommonApi_BaseUrl}Train/ChangeDates`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(changeDatesRequest)
        })
            .then(response => response.json())
            .then(data => {
                setTrains(data.trains);
                setIsLoading(false)
                if (!data.success) {
                    setErrorMsg("Operazione non disponibile");
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <div className="" id="change_date" tabIndex="-1" style={{ zIndex: '999', minHeight: '500px' }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{t('MyBook:modalTitleChangeDate')}</h5>
                        <button onClick={() => openChangeDatePanel(false)} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body ml-4">
                        {
                            (changePaxError || changePaxSuccess) && <div>
                                {!changePaxError && changePaxSuccess && <p className="text-succes">{t('MyBook:changeSuccessful')}</p>}
                                {changePaxError && <p className="text-danger">{t('MyBook:changeUnsuccessful')}</p>}
                            </div>
                        }
                        {
                            !(changePaxError || changePaxSuccess) && <>
                                {
                                    isLoading ? (
                                        <Loading textMsg={t('MyBook:Loading')} />
                                    ) : errorMsg ? (
                                        <>
                                            <span className="text-danger">{errorMsg}</span>
                                        </>
                                    ) : (
                                        <>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-check form-check-inline p-0">
                                                        <label className="fw-bold">Seleziona il tuo itinerario:</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">

                                                        <input className="form-check-input"
                                                            type="radio"
                                                            id="OneWay"
                                                            name="ways-radio"
                                                            value="option2"
                                                            checked={selectDirection === "Outbound"}
                                                            onChange={_ => setSelectDirection("Outbound")}
                                                        />

                                                        <data m3ico="OneWay text-gray-600 icon-10">
                                                            <M3Icon iconName="OneWay" externalClass={"icon-10 " + textColorClass} />
                                                        </data>
                                                        <label className={"form-check-label " + textColorClass} htmlFor="OneWay">
                                                            <data m3lab="SearchEngine.OneWay">
                                                                {t('SearchEngine:OneWay')}
                                                            </data>
                                                        </label>
                                                    </div>
                                                    {
                                                        trainDetail?.return && <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                id="Return"
                                                                value="option1"
                                                                name="ways-radio"
                                                                checked={selectDirection === "Return"}
                                                                onChange={_ => setSelectDirection("Return")}
                                                            />

                                                            <data m3ico="RoundTrip text-gray-600 icon-10">
                                                                <M3Icon iconName="RoundTrip" externalClass={"icon-10 " + textColorClass} />
                                                            </data>
                                                            <label className={"form-check-label " + textColorClass} htmlFor="Return">
                                                                <data m3lab="SearchEngine.Roundtrip">
                                                                    {t('SearchEngine:Roundtrip')}
                                                                </data>
                                                            </label>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-12">
                                                    <div className="form-check form-check-inline p-0">
                                                        <label className="fw-bold">Seleziona i passeggeri</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        {passengers.map((pax, index) => (
                                                            <div key={index}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`pax-${index}`}
                                                                    checked={selectedPassengers.includes(index)}
                                                                    onChange={e => handlePassengerSelectionChange(index, e.target.checked)}
                                                                />
                                                                <label htmlFor={`pax-${index}`}>{pax.fullName}</label>
                                                            </div>
                                                        ))}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 pl-0 change-date-calendar">
                                                    <InputCalendarContainer
                                                        dateFrom={selectDates ? selectDates.dateFrom : null}
                                                        timeFrom={selectTimes ? selectTimes.timeFrom : null}
                                                        extraOptions={extraOptionsCalendarOutbound}
                                                        productType={configData.Settings.Products.Train.IdTipoProdotto}
                                                        onClickSelectDates={onClickSelectDates}
                                                        modeView="MODAL"
                                                        onOpenPanelCalendar={() => { }}
                                                        type="m" />
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-end">
                                                <button className="btn bg-gray-300 my-4" onClick={changeDate}>{t('Button:Search')}</button>
                                            </div>
                                            <div>
                                                <div class="h5">{t('Product:Train:SelectItinerary')}</div>

                                                {
                                                    evalutatedData
                                                        ? <h6 className="h5 text-danger">Prezzo cambiato: {formatPrice(evalutatedData.amount, evalutatedData.currency, cultureName)}</h6>
                                                        : <></>
                                                }

                                                {!evalutatedData && <button className="btn btn-small tp-btn-search my-4" onClick={changeDate}>{t('Button:Search')}</button>}
                                            </div>
                                            <div>
                                                <div class="h5">{t('Product:Train:SelectItinerary')}</div>
                                                {

                                                    selectedLeg && <TrainSelectedItinerary
                                                        itinerary={selectedLeg}
                                                        type="SELECTSEAT"
                                                        showPriceBar={true}
                                                        onClickUpdate={_ => confirmChangeDates()} />

                                                }
                                                {
                                                    evalutatedData?.seatMaps && <div className="px-0 my-4">
                                                        <TrainItinerySeatMap
                                                            id="outbound"
                                                            haveErrorSeat={false}
                                                            seatMap={evalutatedData?.seatMaps}
                                                            onSelectSeat={onSelectSeatOutbound}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className="mx-2">
                                                <div class="h5 fw-bold mb-2">{t('MyBook:Alternative')}:</div>
                                                {
                                                    !showAllTrains && trains.length > 3 ? trains.slice(0, 3).map((item, key) => {
                                                        return <TrainItemList key={key}
                                                            onClickSearch={onClickSearch}

                                                            item={item}
                                                            showPriceBar={true}
                                                            showCost={true}
                                                        />
                                                    }) : <>
                                                        {trains.map((item, key) => {
                                                            return <TrainItemList key={key}
                                                                onClickSearch={onClickSearch}

                                                                item={item}
                                                                showPriceBar={true}
                                                                showCost={true}
                                                            />
                                                        })}
                                                    </>
                                                }
                                                {trains.length > 0 && !showAllTrains && < div className="d-flex justify-content-center">

                                                    <button onClick={() => setShowAllTrains(true)} className="btn border">{t('General:More')}</button>
                                                </div>}

                                            </div>
                                        </>
                                    )}
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>

    );
};
