import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { StructureDetailPrint } from '../product/StructureDetailPrint';
import { FlightDetailPrint } from '../product/FlightDetailPrint';
import { ActivityDetailPrint } from '../product/ActivityDetailPrint';
import { CarRentalDetailPrint } from '../product/CarRentalDetailPrint';
import { TransferDetailPrint } from '../product/TransferDetailPrint';
import { CruiseDetailPrint } from '../product/CruiseDetailPrint';
import { TrainDetail } from '../../Reservation/TrainDetail';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { getDifferenceDay } from '../../../js/PrintUtility.js';


export const PrintStepItem = ({ qInfo, isPrintCliente, isTemplate = false, isConfirmPrint = true, isDocViaggio=false, isPrintPdf=true, isNotStep=true }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    let diffDate = "";
    
    if (!qInfo)
        return;

    if (qInfo.productInfo)
        diffDate = getDifferenceDay(qInfo.productInfo.priceBar.startDate, qInfo.productInfo.priceBar.endDate);
    
    return (
        <>
            <div className="borderColor marginBottomPdf">
                {qInfo.productInfo && qInfo.productInfo.priceBar && qInfo.productType !== 'Generic' && ((qInfo.productType !== 'Flight' && !isNotStep) || (isNotStep)) &&
                    <table>
                        <tr>
                            {parseInt(diffDate.replace("+", "")) > 0 ?
                            <>
                                <td>
                                    {t(`Template:StartStep`)} {formatDateTime(qInfo.productInfo.priceBar.startDate, cultureName, { noYear: true })}
                                    &nbsp;
                                    {t(`Template:EndStep`)} {formatDateTime(qInfo.productInfo.priceBar.endDate, cultureName, { noYear: true })}
                                </td>
                            </>
                            :
                            <td colSpan="2">
                                {t(`Template:onDate`)} {formatDateTime(qInfo.productInfo.priceBar.startDate, cultureName, { noYear: true })}
                            </td>
                            }
                        </tr>
                    </table>
                }
                {
                    qInfo.productType === 'Structure' && <StructureDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        isNotStep={isNotStep }
                        isDocViaggio={isDocViaggio }
                        structureDetail={qInfo.productInfo}
                        isPrintCliente={isPrintCliente}
                        isConfirmPrint={isConfirmPrint}
                        isPrintPdf={isPrintPdf}
                    />
                }
                {
                    qInfo.productType === 'Flight' && <FlightDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        isNotStep={isNotStep}
                        flightDetail={qInfo.productInfo}
                        isPrintCliente={isPrintCliente}
                        isConfirmPrint={true}
                    />
                }
                {
                    qInfo.productType === 'Activity' && <ActivityDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        productSubType={qInfo.productSubType }
                        activityDetail={qInfo.productInfo}
                        isNotStep={isNotStep}
                        isDocViaggio={isDocViaggio}
                        isPrintCliente={isPrintCliente}
                        isConfirmPrint={isConfirmPrint}
                    />
                }
                {
                    qInfo.productType === 'Transfer' && <TransferDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        id={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        isNotStep={isNotStep}
                        transferDetail={qInfo.productInfo}
                        isPrintCliente={isPrintCliente}
                        isConfirmPrint={isConfirmPrint}
                    />
                }
                {
                    qInfo.productType === 'CarRental' && <CarRentalDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        isNotStep={isNotStep}
                        carRentalDetail={qInfo.productInfo}
                        isPrintCliente={isPrintCliente}
                        isConfirmPrint={isConfirmPrint}
                    />
                }
                {
                    qInfo.productType === 'Cruise' && <CruiseDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        cruiseDetail={qInfo.productInfo}
                        isNotStep={isNotStep}
                        isDocViaggio={isDocViaggio}
                        isPrintCliente={isPrintCliente}
                        isConfirmPrint={isConfirmPrint}
                    />
                    }
                {/*
                    qInfo.productType === 'Train' && <TrainDetailPrint
                        key={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        id={qInfo.wsBookingId ? qInfo.wsBookingId : qInfo.id}
                        type={qInfo.wsBookingId ? "QuotationTO_MyBook" : "QuotationTO"}
                        showNetPrices={false}
                        trainDetail={qInfo.productInfo }
                        isPrintCliente={isPrintCliente}
                        />
                */}
                <div style={{ borderBottom: "1px solid #dee2e6" }}></div>
            </div>
        </>
    );
}