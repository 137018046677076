import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { PriceBarPrint } from './PriceBarPrint';
import { PassengerDetailPrint } from './PassengerDetailPrint';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { imageOnError } from '../../../js/CDNUtility';
import { getIconUrlToPrint, replaceAllStyleElements } from '../../../js/PrintUtility';

export const FlightDetailPrint = ({ id, type, showNetPrices, flightDetail, isPrintCliente, isConfirmPrint = false }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    function getTotalBag(passengerFareComponents, depAirport, arrAirport) {
        let totalBag = 0;
        let arrBagage = [];

        passengerFareComponents.map((pax, index) => {
            // show bagagge for all trait
            let isTakeB = false;

            pax.fareDetails.map((fare) => {
                if (fare.depAirport === depAirport && fare.arrAirport === arrAirport && fare.baggages != null) {
                    arrBagage = fare.baggages.split(" ");

                    if (arrBagage[1] === 'Pcs' && !isTakeB) {
                        totalBag = totalBag + parseInt(arrBagage[0]);
                        isTakeB = true;
                    }

                }
            });
        });

        if (totalBag === 0)
            totalBag = "--";

        return totalBag;
    }

    function getTotalKgs(passengerFareComponents, depAirport, arrAirport) {
        let totalKg = 0;
        let arrBagage = [];

        passengerFareComponents.map((pax, index) => {
            // show bagagge for all trait
            let isTakeB = false;

            pax.fareDetails.map((fare) => {
                if (fare.depAirport === depAirport && fare.arrAirport === arrAirport && fare.baggages != null) {
                    arrBagage = fare.baggages.split(" ");

                    if (arrBagage[1] === 'Kg') {
                        totalKg = totalKg + parseInt(arrBagage[0]);
                        isTakeB = true;
                    }
                }
            });
        });

        if (totalKg === 0)
            totalKg = "--";

        return totalKg;
    }

    function getTimes(departureTime, arrivalTime) {
        let resTime = "";
        let oneDay = "";
        let timeDep = formatDateTime(departureTime, cultureName, { onlyTime: true });
        let timeArr = formatDateTime(arrivalTime, cultureName, { onlyTime: true });

        if (timeDep > timeArr) {
            oneDay = " (+1)";
        }

        resTime = timeDep + " - " + timeArr + oneDay;

        return resTime;
    }

    return (
        <>
            <div id={'aDetail_' + id}></div>

            {flightDetail ?
                <div style={{ display: 'grid' }}>
                    <table>
                        {/*Flight*/}
                        <tr className="badge-light-customer" style={{ borderBottom: '1px solid black', borderRight: '1px', }} >
                            <td className="borderThead" style={{ width: '6%', maxWidth: '6%', }}>
                                <img src={getIconUrlToPrint('flight')} className="ico-image-large" style={{ left: '5px' }} />
                            </td>
                            <td className="borderThead">
                                <h6>Compagnia</h6>
                            </td>
                            <td className="borderThead" style={{ width: '10%', maxWidth: '10%' }}>
                                <h6>N.Volo</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Da</h6>
                            </td>
                            <td className="borderThead">
                                <h6>A</h6>
                            </td>
                            <td className="borderThead" style={{ width: '8%', maxWidth: '8%' }}>
                                <h6>Classe</h6>
                            </td>
                            <td className="borderThead" style={{ width: '10%', maxWidth: '10%' }}>
                                <h6>Data</h6>
                            </td>
                            <td className="borderThead">
                                <h6>Partenza/arrivo</h6>
                            </td>
                            <td className="borderThead" style={{ width: '5%', maxWidth: '5%' }}>
                                <img src={getIconUrlToPrint('luggage')} className="ico-image" style={{ left: '5px' }} />
                            </td>
                            <td className="borderThead" style={{ width: '5%', maxWidth: '5%' }}>
                                <h6>Kg.</h6>
                            </td>
                        </tr>
                        {flightDetail.legs.map((leg) =>
                            leg.segments.map((segment, key) =>
                                <tr key={key} style={{ borderBottom: '1px solid black', borderRight: '1px dotted grey' }} >
                                    <td className="borderTBody" style={{ width: '6%', maxWidth: '6%' }}>
                                        <img src={`data:image;base64, ` + leg.thumbUrlB} alt={leg.airlineName} style={{ left: '3px' }} className='imgFlight' onError={imageOnError} />
                                    </td>
                                    <td className="borderTBody">
                                        <h6>{segment.airlineName}</h6>
                                    </td>
                                    <td className="borderTBody" style={{ width: '10%', maxWidth: '10%' }}>
                                        <h6>{segment.flightNumber}</h6>
                                    </td>
                                    <td className="borderTBody">
                                        <h6>{segment.departureAirportIATA}</h6>
                                    </td>
                                    <td className="borderTBody">
                                        <h6>{segment.arrivalAirportIATA}</h6>
                                    </td>
                                    <td className="borderTBody" style={{ width: '8%', maxWidth: '8%' }}>
                                        <h6></h6>
                                    </td>
                                    <td className="borderTBody" style={{ width: '10%', maxWidth: '10%' }}>
                                        <h6>{formatDateTime(segment.departureDate, cultureName)}</h6>
                                    </td>
                                    <td className="borderTBody">
                                        <h6>
                                            {getTimes(segment.departureDate, segment.arrivalDate)}
                                        </h6>
                                    </td>
                                    <td className="borderTBody" style={{ width: '5%', maxWidth: '5%' }}>
                                        <h6>{getTotalBag(flightDetail.passengerFareComponents, leg.departureAirportIATA, leg.arrivalAirportIATA)}</h6>
                                    </td>
                                    <td className="borderTBody" style={{ width: '5%', maxWidth: '5%' }}>
                                        <h6>{getTotalKgs(flightDetail.passengerFareComponents, leg.departureAirportIATA, leg.arrivalAirportIATA)}</h6>
                                    </td>
                                </tr>
                            )
                        )}
                    </table>
                    {/*note*/}
                    <table>
                        <tr style={{ borderLeft: '1px dotted grey', borderRight: '1px dotted grey', borderBottom: (isPrintCliente ? '1px dotted grey' : 'none'), padding: '5px' }}>
                            <td style={{ textAlign: 'left' }} >
                                <h5>Note aggiuntive</h5>
                                {flightDetail.quotationDetail != null && flightDetail.quotationDetail.isManualLoading && flightDetail.notes ?
                                    <div> {/* da lascaire per problema split pagina react PDF */}
                                        <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(flightDetail.notes) }} /></p>
                                    </div>
                                    : <></>
                                }
                                {flightDetail.descriptions.length > 0 && isConfirmPrint ?
                                    <div> {/* stampa descrizione con titolo "Documenti di viaggio" */}
                                        <p style={{ textAlign: 'left' }}><span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(flightDetail.descriptions[0].description) }} /></p>
                                    </div>
                                    : <></>
                                }
                            </td>
                        </tr>
                    </table>
                    {/*totale costo*/}
                    {!isPrintCliente &&
                        <table>
                            <tr style={{ border: '2px solid black', padding: '1px' }}>
                                <td className="col-lg-7" style={{ textAlign: 'left' }}>
                                    {isConfirmPrint ?
                                        <h5>{t(`statiPrenotazione:` + flightDetail.priceBar.status)}</h5>
                                        :
                                        <h5>Totale costo in €</h5>
                                    }
                                </td>
                                <td className="col-lg-5" style={{ textAlign: 'right' }}>
                                    <h4>{formatPrice(flightDetail.priceBar.invoicePrice, flightDetail.priceBar.currency, cultureName)}</h4>
                                </td>
                            </tr>
                        </table>
                    }
                    <table>
                        {/*passeggeri*/}
                        {
                            <PassengerDetailPrint
                                passengers={flightDetail.passengers}
                            />
                        }
                    </table>
                </div>
                :
                <></>
            }
        </>
    );
}