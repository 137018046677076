import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { formatDateTime  } from '../../../js/Utils.js';
import { getIconUrlToPrintCustom, replaceAllStyleElements } from '../../../js/PrintUtility';

export const PassengerDetailPrint = ({ passengers }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    var idx = 0;

    function getArrayPassengers() {
        let tempArr = [];
        let arrayPassengers = [];

        passengers = passengers.slice(0, 100);
        passengers.map((passenger, key) => {
            tempArr.push(passenger);

            if (key === 2 || key === 5 || key === 8 || key === 11 || key === 14 ||
                key === 17 || key === 20 || key === 23 || key === 26 || key === 29 ||
                key === 31 || key === 34 || key === 37 || key === 40 || key === 43 ||
                key === 46 || key === 49 || key === 52 || key === 55 || key === 58 ||
                key === 61 || key === 64 || key === 67 || key === 70 || key === 73 ||
                key === 76 || key === 79 || key === 82 || key === 85 || key === 88 ||
                key === 91 || key === 94 || key === 97 || key === 100 || key === 103) { // ogni 3 persone
                arrayPassengers.push(tempArr);
                tempArr = [];
            }
        });

        if (tempArr.length > 0)
            arrayPassengers.push(tempArr);

        return arrayPassengers;
    }

    function getIndexArr() {
        idx = idx + 1;

        return idx;
    }
    
    return (
        <>
            {passengers.length > 0 && passengers[0].fullName != null &&
            getArrayPassengers(passengers).length > 0 && getArrayPassengers(passengers).map((arrPass, key) => 
                <tr key={key} style={{ borderRight: '1px dotted grey', borderLeft: '1px dotted grey', borderBottom: '1px dotted grey', paddingTop: '0px', paddingBottom: '0px' }} >
                    {arrPass.map((passenger, index) =>
                        <>
                            <td className="col-lg-4">
                                <tr>
                                    <td>
                                        <tr>
                                            <td style={{ textAlign: 'left', marginTop: '-5px', width: '100%' }}>
                                                <h6>Passeggero {getIndexArr()}</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'left', marginTop: '-5px' }}>
                                                <label style={{ marginRight: '5px' }}>
                                                    Nome/Cognome 
                                                </label>
                                            </td>
                                            <td style={{ textAlign: 'left', marginTop: '-5px', marginLeft: '-8px' }}>
                                                <label style={{ whiteSpace: 'nowrap'}}>{passenger.fullName}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'left', marginTop: '-5px', minWidth: '30%' }}>
                                                <label style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>
                                                    Data di nascita
                                                </label>
                                            </td>
                                            <td style={{ marginTop: '-5px', textAlign: 'left', marginLeft: '-5px', marginRight: '15px' }}>
                                                <label style={{ whiteSpace: 'nowrap' }}>
                                                    {passenger.infos.find(x => x.paramName === "GST-04") ? passenger.infos.find(x => x.paramName === "GST-04").paramValue : ''}
                                                </label>
                                            </td>
                                            <td style={{ marginTop: '-5px'}}>
                                                <label style={{ marginRight: '5px' }}>
                                                    Genere
                                                </label>
                                            </td>
                                            <td style={{ marginTop: '-5px' }}>
                                                <label style={{ whiteSpace: 'nowrap' }}>
                                                    {passenger.infos.find(x => x.paramName === "GST-05") ? passenger.infos.find(x => x.paramName === "GST-05").paramValue : ''}
                                                </label>
                                            </td>
                                        </tr>
                                    </td>
                                    {index < 2 &&
                                        <td className="pl-3" style={{ width: '10%', maxWidth: '10%' }}>
                                            <img src={getIconUrlToPrintCustom('puntini_oro_verticali')} className="image-puntini-verticali" />
                                        </td>
                                    }
                                </tr>
                            </td>
                        </>
                    )}
                </tr>
            )
        }
        </>
    );
}
