import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import configData from "../../../appsettings.json";
import { getDateObj } from "../../../js/Utils.js";

export const M3Calendar = ({ dateFrom, dateTo, timeFrom, timeTo, extraOptions, validDateRule, onSelectDate, productType, minDateValue }) => {
    const { t } = useTranslation();

    const [dates, setDates] = useState(null);
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date(2099, 12, 31));
    const [numberOfMonths, setNumberOfMonths] = useState(2);
    const [showTime, setShowTime] = useState(false);
    const [locale] = useState('it');

    // settaggi calendario
    const [selectRange, setSelectRange] = useState('range');

    useEffect(() => {
        let dateFromObj = null;
        let dateTOObj = null;

        if (dateFrom)
            dateFromObj = getDateObj(dateFrom, timeFrom);

        if (dateTo)
            dateTOObj = getDateObj(dateTo, timeTo);

        if (!dateFromObj && !dateTOObj)
            setDates(null);
        else
            setDates([dateFromObj, dateTOObj]);

    }, [dateFrom, dateTo, timeFrom, timeTo]);

    useEffect(() => {
        if (!extraOptions || !extraOptions.multiDate) {
            setSelectRange('single');
        }
        else {
            setSelectRange('range');
        }

        if (extraOptions && extraOptions.validDates && extraOptions.validDates.length > 0) {
            let aValidDates = [];
            for (let i = 0; i < extraOptions.validDates.length; i++) {
                aValidDates.push(new Date(extraOptions.validDates[i]));
            }

            aValidDates = aValidDates.sort((a, b) => a - b);

            let minDate = aValidDates[0];
            if (minDate < new Date())
                minDate = new Date();

            setDates(minDate);
            setMinDate(minDate);
            setMaxDate(aValidDates[extraOptions.validDates.length - 1]);
        } else if (validDateRule && validDateRule.minDate) {
            let minDateRule = getDateObj(validDateRule.minDate);
            setMinDate(minDateRule);
        } else {
            /*if (productType === configData.Settings.Products.Structure.IdTipoProdotto ||
                productType === configData.Settings.Products.Activity.IdTipoProdotto) {
                let myDate = new Date();
                myDate.setDate(myDate.getDate() + 1);
                setMinDate(myDate);
            } else {
                setMinDate(new Date());
            }*/

            setMinDate(new Date());

            if (minDateValue)
                setMinDate(minDateValue)
        }

        if (validDateRule && validDateRule.maxDate) {
            let maxDateRule = getDateObj(validDateRule.maxDate);
            setMaxDate(maxDateRule);
        }

        // extraoptions template dates
        let minDateValidDate = getMinDateValidDate();
        let maxDateValidDate = getMaxDateValidDate();

        if (minDateValidDate)
            setMinDate(minDateValidDate);

        if (maxDateValidDate)
            setMaxDate(maxDateValidDate);


        if (extraOptions && extraOptions.numberOfMonths)
            setNumberOfMonths(extraOptions.numberOfMonths);
        else
            setNumberOfMonths(2);


        if (extraOptions && extraOptions.showTime)
            setShowTime(extraOptions.showTime);
        else
            setShowTime(false);

    }, [extraOptions, validDateRule, minDateValue]);

    addLocale('it', {
        firstDayOfWeek: 1,
        dayNames: [t("Calendar:Day:Sunday"), t("Calendar:Day:Monday"), t("Calendar:Day:Tuesday"), t("Calendar:Day:Wedsnday"), t("Calendar:Day:Thursday"), t("Calendar:Day:Friday"), t("Calendar:Day:Saturday")],
        dayNamesShort: [t("Calendar:DayShort:Sunday"), t("Calendar:DayShort:Monday"), t("Calendar:DayShort:Tuesday"), t("Calendar:DayShort:Wedsnday"), t("Calendar:DayShort:Thursday"), t("Calendar:DayShort:Friday"), t("Calendar:DayShort:Saturday")],
        dayNamesMin: [t("Calendar:Day:Sunday")[0], t("Calendar:Day:Monday")[0], t("Calendar:Day:Tuesday")[0], t("Calendar:Day:Wedsnday")[0], t("Calendar:Day:Thursday")[0], t("Calendar:Day:Friday")[0], t("Calendar:Day:Saturday")[0]],
        monthNames: [t("Calendar:Month:January"), t("Calendar:Month:February"), t("Calendar:Month:March"), t("Calendar:Month:April"), t("Calendar:Month:May"), t("Calendar:Month:June"), t("Calendar:Month:July"), t("Calendar:Month:August"), t("Calendar:Month:September"), t("Calendar:Month:October"), t("Calendar:Month:November"), t("Calendar:Month:December")],
        monthNamesShort: [t("Calendar:MonthShort:January"), t("Calendar:MonthShort:February"), t("Calendar:MonthShort:March"), t("Calendar:MonthShort:April"), t("Calendar:MonthShort:May"), t("Calendar:MonthShort:June"), t("Calendar:MonthShort:July"), t("Calendar:MonthShort:August"), t("Calendar:MonthShort:September"), t("Calendar:MonthShort:October"), t("Calendar:MonthShort:November"), t("Calendar:MonthShort:December")],
        today: t("Calendar:Today"),
        clear: t("Calendar:Clear")
    });

    function updateDates(values) {
        if (selectRange === 'range') {
            updateDatesRange(values);
        } else {
            updateDatesSingleValue(values);
        }
    }

    function updateDatesRange(values) {
        let formatDates = {}
        let dateFrom = values[0];
        let dateTo = values[1];

        if (productType === configData.Settings.Products.Structure.IdTipoProdotto && dateFrom && dateTo !== null && getDateToString(dateFrom) === getDateToString(dateTo)) {
            return;
        }

        if (dateFrom) {
            formatDates.dateFrom = getDateToString(dateFrom);
            setMinDate(dateFrom);

            if (extraOptions && extraOptions.showTime)
                formatDates.timeFrom = getDateHourToString(dateFrom);

            // range massimo un mese
            if (productType === configData.Settings.Products.Structure.IdTipoProdotto ||
                productType === configData.Settings.Products.Activity.IdTipoProdotto) {
                let myDate = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
                myDate.setMonth(myDate.getMonth() + 1);
                setMaxDate(myDate);
            }

            if (validDateRule && validDateRule.maxDate)
                setMaxDate(getDateObj(validDateRule.maxDate));
        }

        if (dateTo) {
            formatDates.dateTo = getDateToString(dateTo);

            if (extraOptions && extraOptions.showTime)
                formatDates.timeTo = getDateHourToString(dateTo);
        }


        setDates(values);
        if (dateFrom && dateTo) {

            let minDateValidDate = getMinDateValidDate();
            let maxDateValidDate = getMaxDateValidDate();

            setMinDate(new Date());
            if (validDateRule && validDateRule.minDate)
                setMinDate(getDateObj(validDateRule.minDate));
            if (minDateValidDate)
                setMinDate(minDateValidDate);

            if (validDateRule && validDateRule.maxDate)
                setMaxDate(getDateObj(validDateRule.maxDate));
            if (maxDateValidDate)
                setMaxDate(maxDateValidDate);

            if (!extraOptions || !extraOptions.showTime)
                onSelectDate(formatDates);
        }
    }

    function getMinDateValidDate() {
        if (extraOptions && extraOptions.validDates && extraOptions.validDates.length > 0) {
            let aValidDates = [];
            for (let i = 0; i < extraOptions.validDates.length; i++) {
                aValidDates.push(new Date(extraOptions.validDates[i]).getTime());
            }

            aValidDates = aValidDates.sort();

            return new Date(aValidDates[0]);
        }

        return null;
    }

    function getMaxDateValidDate() {
        if (extraOptions && extraOptions.validDates && extraOptions.validDates.length > 0) {
            let aValidDates = [];
            for (let i = 0; i < extraOptions.validDates.length; i++) {
                aValidDates.push(new Date(extraOptions.validDates[i]).getTime());
            }

            aValidDates = aValidDates.sort();

            return new Date(aValidDates[aValidDates.length - 1]);
        }

        return null;
    }

    function updateDatesSingleValue(values) {
        let formatDates = { dateFrom: null };
        let dateFrom = values;

        if (extraOptions && extraOptions.validDates && extraOptions.validDates.length > 0) {
            let aValidDates = [];
            for (let i = 0; i < extraOptions.validDates.length; i++) {
                aValidDates.push(new Date(extraOptions.validDates[i]).getTime());
            }

            let dateObjTime = dateFrom.getTime();
            if (!aValidDates.includes(dateObjTime))
                return;
        }

        setDates(values);

        if (dateFrom)
            formatDates.dateFrom = getDateToString(dateFrom);

        if (extraOptions && extraOptions.showTime)
            formatDates.timeFrom = getDateHourToString(dateFrom);

        if (!extraOptions || !extraOptions.showTime)
            onSelectDate(formatDates);
    }

    function getDateToString(myDate) {
        let monthStr = myDate.getMonth() + 1;
        let dayStr = myDate.getDate();
        if (monthStr < 10)
            monthStr = "0" + monthStr;

        if (dayStr < 10)
            dayStr = "0" + dayStr;

        return myDate.getFullYear() + "-" + (monthStr) + "-" + dayStr;
    }

    function getDateHourToString(myDate) {
        const hours = myDate.getHours();
        let minutes = myDate.getMinutes();

        if (minutes < 10)
            minutes = "0" + minutes;

        return hours + ":" + minutes;
    }

    function confirmDates() {
        let formatDates = { dateFrom: null };

        let myDate = dates;
        if (Array.isArray(dates))
            myDate = dates[0];

        if (!myDate) {
            myDate = new Date();
            updateDatesSingleValue(myDate);
        }

        formatDates.dateFrom = getDateToString(myDate);

        if (extraOptions && extraOptions.showTime)
            formatDates.timeFrom = getDateHourToString(myDate);

        if (formatDates.dateFrom != null)
            onSelectDate(formatDates);
    }

    const dateTemplate = (date) => {
        let dateObjTime = new Date(date.year, date.month, date.day).getTime();

        if (extraOptions && extraOptions.validDates && extraOptions.validDates.length > 0) {
            let aValidDates = [];
            for (let i = 0; i < extraOptions.validDates.length; i++) {
                aValidDates.push(new Date(extraOptions.validDates[i]).getTime());
            }

            aValidDates = aValidDates.sort();

            if (!aValidDates.includes(dateObjTime)) {
                return (
                    <strong style={{ textDecoration: 'p-disabled', pointerEvents: 'none', textDecoration: 'line-through', color: 'gray', background: 'black', padding: '4px' }}>{date.day}</strong>
                );
            }
        }

        return date.day;
    }

    return (
        <>
            <div className="mt-1">
                <Calendar
                    value={dates}
                    onChange={(e) => updateDates(e.value)}
                    minDate={minDate}
                    maxDate={maxDate}
                    numberOfMonths={numberOfMonths}
                    selectionMode={selectRange}
                    inline
                    showTime={showTime}
                    stepMinute={15}
                    locale={locale}
                    readOnlyInput
                    dateTemplate={dateTemplate}
                    footerTemplate={_ => (!extraOptions || !extraOptions.showTime) ? <></> : <div className="text-right"><button className="tp-btn-confirm py-1 rounded border-0 shadow" onClick={_ => confirmDates()}>{t('Button:Confirm')}</button></div>}
                />
            </div>
        </>
    );
}
