import React, { useState, useEffect } from 'react';
import { TemplateSummary } from './TemplateSummary';
import { imageOnLoad } from '../../../js/CDNUtility';
import { M3Icon } from "../../Common/M3Icon";
import { useTranslation } from 'react-i18next';


export const TemplateHeaderImage = ({ template }) => {
    const { t } = useTranslation();
    const [imageUrl, setImageUrl] = useState('');
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [topTitle, setTopTitle] = useState('');
    const [boxTitle, setBoxTitle] = useState('');

    useEffect(() => {

        if (template) {
            setTitle(template.name);
            setBoxTitle(template.boxTitle);
            setSubTitle(template.boxSubTitle);
            setTopTitle(template.destination);

            if (template.photoGallery && template.photoGallery.items.length > 0) {
                setImageUrl(template.photoGallery.items[0].url);
            } else {
                setImageUrl(imageOnLoad());
            }
        }

    }, [template]);

    function buttonChooseDate() {
        return (
            <>
                <div className="p-2 text-center">
                    <a className="btn btn-sm tp-btn-search" data-bs-toggle="modal" data-bs-target="#EngineModifyTemplate">{t('SearchEngine:ChooseDate') }</a>
                </div>
            </>
        );
    }

    return (
        <>
            <div style={{ backgroundImage: `url("${imageUrl}"), url("${imageOnLoad()}")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: '800px' }}>

                <div className="row text-white pl-4">
                    <div className="col-8">
                        <div className="d-table h-100 w-100">
                            <div className="d-table-row h-100">
                                <div class="d-table-cell align-top pt-5 h-100 text-left">
                                    {topTitle &&
                                        <span class="bg-white btn-sm p-2 px-2 pt-3 text-black">
                                            <>
                                                <span m3ico="Place mr-2 icon-15">
                                                    <M3Icon iconName="Place" externalClass="mr-2 icon-15 text-custom" hasLabel={false} />
                                                </span>
                                                {topTitle}
                                            </>
                                        </span>
                                    }
                                    <div className="h500 text-customGoing fw-bold">{title}</div>
                                    <div className="text-white">
                                        <span className="h2 text-white">
                                            <var dangerouslySetInnerHTML={{ __html: boxTitle }} />
                                            {subTitle}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-3 p-2 mt-6" style={{ backgroundColor: '#FFFFFF' }}>
                        <div className="h4 text-center w-100">
                            <span>{t('SearchEngine:QuoteYourTravel') }</span>
                        </div>
                        {buttonChooseDate()}

                        <div className="card shadow  pt-2">
                            {/*itinerary summary*/}
                            <div className="pl-2">
                                {template.steps && template.steps.length > 0 && <TemplateSummary steps={template.steps} />}
                            </div>

                            {/*button choose date*/}
                            {buttonChooseDate()}
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}