import Cookies from 'universal-cookie';
import configData from "../appsettings.json";
import { getWebsiteId } from "./Utils.js";
export function generateClientFlowId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
}

export async function callGetLastItemsSearch(pType) {

    const requestOption = {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
    };

    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'user/getlastdestinationsearch/' + pType, requestOption);

    if (fetchedRes.ok) {

        const response = await fetchedRes.json();
        if (response && response.length > 0) {
            return response;
        }

    } else {
        console.log("Error get LastItemsSearch");
    }
}

export async function callGetGetProductRules(pType) {

    const requestOption = {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
    };

    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'website/getproductrules/' + pType, requestOption);

    if (fetchedRes.ok) {

        const response = await fetchedRes.json();
        if (response)
            return response;

    } else {
        console.log("Error get GetProductRules");
    }

    return null;
}
// START ACTIVITY

export async function getActivityListLink(activitySearchParameters, clientFlowId) {

    let carpetId = await getActivityCarpetId(activitySearchParameters, clientFlowId);
    if (carpetId) {
        let listUrl = `${configData.Settings.Base_Url}activity/activitylist/?cId=${carpetId}&cfId=${clientFlowId}`;
        return listUrl;
    }
}

export async function getActivityDetailLink(activitySearchParameters, activityId, carpetId, clientFlowId, createCarpetId, extraParam = null) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    if (activityId)
        activitySearchParameters.activityId = activityId;

    let inputData = {
        carpetOperation: "AVL",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        activitySearchParameters: activitySearchParameters,
        websiteId: getWebsiteId(),
        productSubType: 11 // activity
    };

    let carpetAvlId = '';
    if (createCarpetId)
        carpetAvlId = await createActivityCarpet(inputData);

    if (carpetAvlId || !createCarpetId) {
        let cId = '';
        if (carpetId) {
            cId = `cId=${carpetId}&`;
        }

        let caId = '';
        if (carpetAvlId) {
            caId = `caId=${carpetAvlId}&`;
        }

        let extraParamUrl = '';
        if (extraParam) {
            if (extraParam.testRequest)
                extraParamUrl += "&testRequest=1";

            if (extraParam.onRequest)
                extraParamUrl += "&onReq=1";
        } else {
            extraParamUrl += "&onReq=0";
        }

        let detailUrl = `${configData.Settings.Base_Url}activity/activitydetail/?${cId}${caId}&cfId=${clientFlowId}&aId=${activityId}${extraParamUrl}`;
        return detailUrl;

    }
}

export async function createActivityCarpet(inputData) {

    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'activity/createcarpet', requestOption);
    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        return response.carpetId;
    } else {
        console.log(fetchedRes);
    }
}

export async function getActivityCarpetId(activitySearchParameters, clientFlowId) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    let inputData = {
        carpetOperation: "SRC",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        activitySearchParameters: activitySearchParameters,
        websiteId: getWebsiteId(),
        productSubType: 11 // activity
    };

    let carpetId = await createActivityCarpet(inputData);
    return carpetId;
}

export async function getActivityCarpetAvailabilityId(activitySearchParameters, clientFlowId, activityId) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    if (activityId)
        activitySearchParameters.activityId = activityId;

    let inputData = {
        carpetOperation: "AVL",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        hotelSearchParameters: activitySearchParameters,
        googleApiKeyServer: configData.Settings.Google.ApiKeyServer,
        websiteId: getWebsiteId(),
        productSubType: 11 // activity
    };

    let carpetAvlId = await createActivityCarpet(inputData);
    return carpetAvlId;
}
// END ACTIVITY

// START STRUCTURE

export async function getStructureListLink(hotelSearchParameters, clientFlowId, isFromMap) {
    let carpetId = await getStructureCarpetId(hotelSearchParameters, clientFlowId);
    if (carpetId && !isFromMap) {
        let detailUrl = `${configData.Settings.Base_Url}hotel/hotellist/?cId=${carpetId}&cfId=${clientFlowId}`;
        return detailUrl;
    }
    else if (carpetId && isFromMap) {
        let detailUrl = `${configData.Settings.Base_Url}hotel/hotelMap/?cId=${carpetId}&cfId=${clientFlowId}`;
        return detailUrl;
    }
}

export async function getStructureCarpetId(hotelSearchParameters, clientFlowId) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    let inputData = {
        carpetOperation: "SRC",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        hotelSearchParameters: hotelSearchParameters,
        websiteId: getWebsiteId(),
        googleApiKeyServer: configData.Settings.Google.ApiKeyServer,
        productSubType: 1 // structure
    };

    let carpetId = await createStructureCarpet(inputData);
    return carpetId;
}

export async function getStructureDetailLink(hotelSearchParameters, structureId, carpetId, clientFlowId, createCarpetId, extraParam = null) {

    let carpetAvlId = '';
    if (createCarpetId)
        carpetAvlId = await getStructureCarpetAvailabilityId(hotelSearchParameters, clientFlowId, structureId);

    if (carpetAvlId || !createCarpetId) {
        let cId = '';
        if (carpetId) {
            cId = `cId=${carpetId}&`;
        }

        let caId = '';
        if (carpetAvlId) {
            caId = `caId=${carpetAvlId}&`;
        }

        let extraParamUrl = '';
        if (extraParam) {
            if (extraParam.testRequest)
                extraParamUrl += "&testRequest=1";

            if (extraParam.onRequest)
                extraParamUrl += "&onReq=1";
        } else {
            extraParamUrl += "&onReq=0";
        }

        let detailUrl = `${configData.Settings.Base_Url}hotel/hoteldetail/?${cId}${caId}&cfId=${clientFlowId}&sId=${structureId}${extraParamUrl}`;
        return detailUrl;

    }
}

export async function getStructureCarpetAvailabilityId(hotelSearchParameters, clientFlowId, structureId) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    if (structureId)
        hotelSearchParameters.structureId = structureId;

    let inputData = {
        carpetOperation: "AVL",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        hotelSearchParameters: hotelSearchParameters,
        googleApiKeyServer: configData.Settings.Google.ApiKeyServer,
        websiteId: getWebsiteId(),
        productSubType: 1 // structure
    };

    let carpetAvlId = await createStructureCarpet(inputData);
    return carpetAvlId;
}

export async function createStructureCarpet(inputData) {
    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'structure/createcarpet', requestOption);
    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        if (response.success) {
            return response.carpetId;
        } else {
            console.log(fetchedRes);
        }
    }
}

// END STRUCTURE



// START FLIGHT

export function getFlightDates(searchParameters) {
    let updateCalendars = [];
    let flightType = getFlightType(searchParameters);

    if (searchParameters)
        for (let i = 0; i < searchParameters.legsCriteria.length; i++) {
            let depDate = searchParameters.legsCriteria[i].date;
            let updateCalendar = {
                dateFrom: depDate,
                dateTo: null
            };

            if (searchParameters.legsCriteria.length === 2 && flightType !== 3) {
                let arrDate = searchParameters.legsCriteria[i + 1].date;
                updateCalendar.dateTo = arrDate;
            }

            updateCalendars.push(updateCalendar);

            // case oneWay, roundtrip, basta prendere il primo legCriteria
            if (flightType !== 3)
                break;
        }

    return updateCalendars;
}

export function getFlightType(searchParameters) {
    let flightType = 2;

    if (searchParameters)
        for (let i = 0; i < searchParameters.legsCriteria.length; i++) {

            if (searchParameters.legsCriteria.length == 1) {
                flightType = 1;
            }
            else if (searchParameters.legsCriteria.length == 2) {
                let isRoundTrip = false;
                let legCriteria = searchParameters.legsCriteria[i];
                let legCriteriaSecond = searchParameters.legsCriteria[i + 1];

                // verifico che il departure del primo segmente ed l'arrival del secondo segment siano uguali
                isRoundTrip = legCriteria.departureLocation.code === legCriteriaSecond.arrivalLocation.code;
                // verifico che il departure del secondo segment ed l'arrival del primo segment siano uguali
                isRoundTrip = isRoundTrip && legCriteriaSecond.departureLocation.code === legCriteria.arrivalLocation.code;

                if (isRoundTrip)
                    flightType = 2;
                else
                    flightType = 3;

            } else {
                flightType = 3;
            }

            break;
        }

    return flightType;
}

export async function getFlightListLink(flightSearchParameters, clientFlowId) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    let inputData = {
        carpetOperation: "SRC",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        websiteId: getWebsiteId(),
        flightStandardSearchParameters: flightSearchParameters,
        productSubType: 51 // flight
    };

    let carpetId = await createFlightCarpet(inputData);
    if (carpetId) {
        let detailUrl = `${configData.Settings.Base_Url}flight/flightlist/?cId=${carpetId}&cfId=${clientFlowId}`;
        return detailUrl;
    }
}

export async function createFlightCarpet(inputData) {
    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'flight/createcarpet', requestOption);
    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        return response.carpetId;
    }
    else {
        console.log(fetchedRes);
    }
}

export async function createFlightCarpetAvailability(inputData) {
    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'flight/createCarpetAvailability', requestOption);
    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        return {
            success: true, carpetId: response.carpetId
        };
    }
    else {
        return {
            success: false
        };
    }
}

export async function getFlightFare(inputData) {
    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'flight/GetFares', requestOption);

    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        return {
            success: true,
            flight: response.flight,
            permissions: response.permissions
        };
    }
    else {
        return {
            success: false
        };
    }
}

export async function getFlightFareDetails(inputData) {
    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'flight/GetFareDetails', requestOption);

    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        return {
            success: true, flight: response.flight
        };
    }
    else {
        return {
            success: false
        };
    }
}

export async function getFlightFareRules(inputData) {
    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'flight/GetFareRules', requestOption);

    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        return {
            success: true, fareRules: response.fareRules
        };
    }
    else {
        return {
            success: false
        };
    }
}

export async function getFlightAncillaries(inputData) {
    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'flight/GetAncillaries', requestOption);

    if (fetchedRes.ok) {
        const response = await fetchedRes.json();

        let itineraryAncillaries = [];
        let legAncillaries = [];
        let travelerAncillaries = [];

        if (response.flight && response.flight.ancillaries)
            itineraryAncillaries = response.flight.ancillaries;

        if (response.flight && response.flight.legs) {
            for (let i = 0; i < response.flight.legs.length; i++) {
                let anc = response.flight.legs[i].ancillaries;
                if (anc && anc.length > 0)
                    legAncillaries.push(response.flight.legs[i]);
            }
        }


        if (response.flight && response.flight.travelers) {
            for (let i = 0; i < response.flight.travelers.length; i++) {
                let anc = response.flight.travelers[i].ancillaries;
                if (anc && anc.length > 0)
                    travelerAncillaries.push(response.flight.travelers[i]);
            }
        }

        return {
            success: true,
            hasAncillaries: itineraryAncillaries.length > 0 || legAncillaries.length > 0 || travelerAncillaries.length > 0,
            itineraryAncillaries: itineraryAncillaries,
            legAncillaries: legAncillaries,
            travelerAncillaries: travelerAncillaries
        };
    }
    else {
        return {
            success: false
        };
    }
}

export function getAncillarySelectedMapping(ancillaryTypeArr, ancillary, indexAncillary, valueSelected, paxIndex) {
    let index = ancillaryTypeArr.findIndex(x => x.ancillary.id === ancillary.id && x.paxIndex === paxIndex);

    if (index > -1) {

        if (!valueSelected) {
            ancillaryTypeArr = ancillaryTypeArr.filter(x => x.ancillary.id !== ancillary.id && x.paxIndex !== paxIndex);
        }
        else {
            let vvIndex = ancillaryTypeArr[index].valueSelected.findIndex(vv => vv.key === valueSelected.key);
            if (vvIndex > -1) {

                // TODO Gestione ArrayBP
                // let allVv = ancillaryTypeArr[index].valueSelected.filter(vv => vv.key !== valueSelected.key);
                // if (allVv.length > 0)
                //     ancillaryTypeArr[index].valueSelected = allVv;
                // else
                ancillaryTypeArr = ancillaryTypeArr.filter(x => x.ancillary.id !== ancillary.id || x.paxIndex !== paxIndex);

            } else {
                //ancillaryTypeArr[index].valueSelected.push(valueSelected);
                ancillaryTypeArr[index].valueSelected = [valueSelected];
                ancillaryTypeArr[index].index = indexAncillary;
            }

        }

    } else {

        if (!valueSelected)
            ancillaryTypeArr.push({ ancillary: ancillary, valueSelected: [], paxIndex: paxIndex });
        else
            ancillaryTypeArr.push({ ancillary: ancillary, valueSelected: [valueSelected], paxIndex: paxIndex });

    }

    return ancillaryTypeArr;
}

export async function getTotalAncillarySelectedMapping(ancillary) {
    let totalPrice = 0;
    if (ancillary.valueSelected && ancillary.valueSelected.length > 0) {
        totalPrice = ancillary.valueSelected.reduce((partialSum, a) => partialSum + a.value.amount, 0);
    } else {
        totalPrice += ancillary.ancillary.amount.amount;
    }

    return totalPrice;
}

export async function ancillariesCompare(a, b) {
    if (a.description < b.description) {
        return -1;
    }

    if (a.description > b.description) {
        return 1;
    }

    return a.amount - b.amount;
}

// END FLIGHT

// START TRAIN

export async function getTrainListLink(trainSearchParameters, clientFlowId) {
    let carpetId = await getTrainCarpetId(trainSearchParameters, clientFlowId);
    if (carpetId) {
        let detailUrl = `${configData.Settings.Base_Url}train/trainlist/?cId=${carpetId}&cfId=${clientFlowId}`;
        return detailUrl;
    }
}

export async function createTrainCarpet(inputData) {
    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'train/createcarpet', requestOption);
    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        return response.carpetId;
    }
    else {
        console.log(fetchedRes);
    }
}

export async function createTrainCarpetAvailability(inputData, availabilityCarpetId = null) {

    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };

    let baseParam = '';
    if (availabilityCarpetId)
        baseParam = '?availabilityCarpetId=' + availabilityCarpetId;

    const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}train/createcarpetavailability${baseParam}`, requestOption);
    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        return response.carpetId;
    }
    else {
        console.log(fetchedRes);
    }
}

export async function getTrainCarpetId(trainSearchParameters, clientFlowId) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    let inputData = {
        carpetOperation: "SRC",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        websiteId: getWebsiteId(),
        trainSearchParameters: trainSearchParameters,
        productSubType: 110 // train
    };

    let carpetId = await createTrainCarpet(inputData);
    return carpetId;
}


export function openNewWindowTrain(url) {
    window.open(url, 'open_window', 'menubar=no, toolbar=no,scrollbars=yes, resizable=yes,width=640, height=480, left=0, top=0');
}

// END TRAIN

// START CAR
export async function getCarListLink(carRentalSearchParameters, clientFlowId, pickUpTime, dropOffTime) {
    let carpetId = await getCarCarpetId(carRentalSearchParameters, clientFlowId, pickUpTime, dropOffTime);

    if (carpetId) {
        let detailUrl = `${configData.Settings.Base_Url}carrental/carrentallist/?cId=${carpetId}&cfId=${clientFlowId}`;
        return detailUrl;
    }
}


export async function getCarCarpetId(carRentalSearchParameters, clientFlowId, pickUpTime, dropOffTime) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    let inputData = {
        carpetOperation: "SRC",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        websiteId: getWebsiteId(),
        carRentalSearchParameters: carRentalSearchParameters,
        productSubType: 21, // car,
        pickUpTime: pickUpTime,
        dropOffTime: dropOffTime,
        googleApiKeyServer: configData.Settings.Google.ApiKeyServer
    };

    let carpetId = await createCarCarpet(inputData);
    return carpetId;
}

export async function createCarCarpet(inputData) {
    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'carRental/createcarpet', requestOption);
    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        return response.carpetId;
    }
    else {
        console.log(fetchedRes);
    }
}


export async function getCarDetailLink(carRentalSearchParameters, carId, carpetId, clientFlowId, createCarpetId, extraParam = null) {

    let detailUrl = `${configData.Settings.Base_Url}carrental/carrentaldetail/?carId=${carId}&cId=${carpetId}&cfId=${clientFlowId}`;
    return detailUrl;
}

// END CAR


// START TRANSFER

export async function getTransferListLink(transferSearchParameters, clientFlowId) {
    let carpetId = await getTransferCarpetId(transferSearchParameters, clientFlowId);
    if (carpetId) {
        let detailUrl = `${configData.Settings.Base_Url}transfer/transferlist/?cId=${carpetId}&cfId=${clientFlowId}`;
        return detailUrl;
    }
}

export async function createTransferCarpet(inputData) {
    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'transfer/createcarpet', requestOption);
    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        return response.carpetId;
    }
    else {
        console.log(fetchedRes);
    }
}

export async function getTransferCarpetId(transferSearchParameters, clientFlowId) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    let inputData = {
        carpetOperation: "SRC",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        websiteId: getWebsiteId(),
        transferSearchParameters: transferSearchParameters,
        productSubType: 41 // transfer
    };

    let carpetId = await createTransferCarpet(inputData);
    return carpetId;
}

// END TRANSFER

// START TOUR


export async function getTourCarpetForDetail(tourSearchParameters, clientFlowId) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    let inputData = {
        carpetOperation: "AVL",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        tourSearchParameters: tourSearchParameters,
        websiteId: getWebsiteId(),
        productSubType: 12 // tour
    };

    let carpetId = await createTourCarpet(inputData);
    return carpetId;
}

export async function getTourListLink(tourSearchParameters, clientFlowId) {

    let carpetId = await getTourCarpetId(tourSearchParameters, clientFlowId);
    if (carpetId) {
        let listUrl = `${configData.Settings.Base_Url}tour/tourlist/?cId=${carpetId}&cfId=${clientFlowId}`;
        return listUrl;
    }
}

export async function getTourDetailLink(tourSearchParameters, tourId, carpetId, clientFlowId, createCarpetId, extraParam = null, supplier) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    if (tourId)
        tourSearchParameters.tourId = tourId;

    let inputData = {
        carpetOperation: "AVL",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        tourSearchParameters: tourSearchParameters,
        websiteId: getWebsiteId(),
        productSubType: 12 // Tour
    };

    let carpetAvlId = '';
    if (createCarpetId)
        carpetAvlId = await createTourCarpet(inputData);

    if (carpetAvlId || !createCarpetId) {
        let cId = '';
        if (carpetId) {
            cId = `cId=${carpetId}&`;
        }

        let caId = '';
        if (carpetAvlId) {
            caId = `caId=${carpetAvlId}&`;
        }

        let extraParamUrl = '';
        if (extraParam) {
            if (extraParam.testRequest)
                extraParamUrl += "&testRequest=1";

            if (extraParam.onRequest)
                extraParamUrl += "&onReq=1";
        } else {
            extraParamUrl += "&onReq=0";
        }

        let detailUrl = `${configData.Settings.Base_Url}tour/tourdetail/?${cId}${caId}&cfId=${clientFlowId}&aId=${tourId}${extraParamUrl}&supplier=${supplier}`;
        return detailUrl;

    }
}

export async function createTourCarpet(inputData) {

    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'tour/createcarpet', requestOption);
    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        return response.carpetId;
    } else {
        console.log(fetchedRes);
    }
}

export async function getTourCarpetId(tourSearchParameters, clientFlowId) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    let inputData = {
        carpetOperation: "SRC",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        tourSearchParameters: tourSearchParameters,
        websiteId: getWebsiteId(),
        productSubType: 12 // tour
    };

    let carpetId = await createTourCarpet(inputData);
    return carpetId;
}

// END ACTIVITY



// START CRUISE

export async function getCruiseListLink(cruiseSearchParameters, clientFlowId) {
    let carpetId = await getCruiseCarpetId(cruiseSearchParameters, clientFlowId);
    if (carpetId) {
        let listUrl = `${configData.Settings.Base_Url}cruise/cruiselist/?cId=${carpetId}&cfId=${clientFlowId}`;
        return listUrl;
    }
}

export async function getCruiseCarpetId(cruiseSearchParameters, clientFlowId) {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    let inputData = {
        carpetOperation: "SRC",
        clientFlowId: clientFlowId,
        sessionToken: sessionToken,
        cruiseSearchParameters: cruiseSearchParameters,
        websiteId: getWebsiteId(),
        productSubType: 31 // cruise
    };

    let carpetId = await createCruiseCarpet(inputData);
    return carpetId;
}

export async function createCruiseCarpet(inputData) {
    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Cruise/CreateCarpet', requestOption);
    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        if (response.success) {
            return response.carpetId;
        } else {
            console.log(fetchedRes);
        }
    }
}

export async function createCruiseCarpetAvailability(inputData) {
    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
    const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'cruise/createCarpetAvailability', requestOption);
    if (fetchedRes.ok) {
        const response = await fetchedRes.json();
        return {
            success: true, carpetId: response.carpetId
        };
    }
    else {
        return {
            success: false
        };
    }
}

// START CRUISE
