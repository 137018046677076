import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";
import { Loading } from '../../Common/Loading';
import { useAuth } from '../../Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { Error } from '../../Common/Error';
import { TourStaticContainer } from './components/TourStaticContainer';

export const TourDetailStatic = () => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");

    let params = new URLSearchParams(window.location.search);
    let tourId = params.get('aId');
    let testRequest = params.get('testRequest');
    let onRequest = params.get('onReq');
    let { getQuotationInfoSession } = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tourDetail, setTourDetail] = useState({});
    const [searchParam, setSearchParam] = useState(null);
    const [extraParamSearch, setExtraParamSearch] = useState({
        activityId: tourId,
        tourId: tourId,
        testRequest: testRequest,
        onRequest: onRequest
    });

    useEffect(() => {
        getTour();
    }, []);

    useEffect(() => {
        if (getQuotationInfoSession && getQuotationInfoSession.quotationInfoSearchParam) {
            setSearchParam(getQuotationInfoSession.quotationInfoSearchParam.activitySearchParameters);
        }

    }, [getQuotationInfoSession])

    async function getTour() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}tour/GetTourData/${tourId}/${cultureCode}`, requestOption);
        const response = await fetchedRes.json();

        if (response.success) {
            setTourDetail(response.tour);
            setExtraParamSearch({
                ...extraParamSearch,
                productName: response.tour.name,
                touchPoints: response.tour.touchPoints,
            });

            console.log(response.tour);
            setIsLoading(false);
        } else {
            setIsError(true);
            console.error(response.errorMsg);
        }
    }

    return (
        <>
            {isLoading && !isError && <Loading textMsg={t("MyBook:Loading")} />}
            {!isLoading && isError && <Error textMsg={t("MyBook:ErrorLoading")} />}
            {
                !isLoading && !isError && <main className="bg-gray-300">
                    <div className="container mt-5">
                        <TourStaticContainer
                            tourId={tourId}
                            tourDetail={tourDetail}
                            searchParameters={searchParam}
                            extraParamSearch={extraParamSearch} />
                    </div>
                </main >
            }
        </>
    );
}