import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

import { formatPrice } from '../../../js/Utils.js';

export const HomeVilleLista = () => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));



    let params = new URLSearchParams(window.location.search);
    let area = params.get('area');
    let title = params.get('title');
    let subtitle = params.get('subtitle');

    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [ospiti, setOspiti] = useState(0);
    const [camere, setCamere] = useState(0);
    const [bagni, setBagni] = useState(0);
    const [piscina, setPiscina] = useState("");
    const [mare, setMare] = useState("");
    const [tennis, setTennis] = useState("");

    function readJson() {
        let data = require('./Export_Ville.json');
        let tmpData = data.filter(x => x.area.trim().toLowerCase().replace(" ", "") === area.trim().toLowerCase().replace(" ", ""));

        tmpData.sort(function (a, b) {
            return a.name - b.name;
        });

        setOriginalData(tmpData);
        setData(tmpData);
    }

    useEffect(() => {
        readJson();
    }, [])


    useEffect(() => {
        if (originalData.length > 0) {
            let tmp = originalData.filter(x =>
                x.nLetti >= camere &&
                x.nOspiti >= ospiti &&
                x.nBagni >= bagni &&
                (piscina !== "" ? x.piscina === convertBoolean(piscina) : (x.piscina === true || x.piscina === false)) &&
                (mare !== "" ? x.vistaMare === convertBoolean(mare) : (x.vistaMare === true || x.vistaMare === false)) &&
                (tennis !== "" ? x.tennis === convertBoolean(tennis) : (x.tennis === true || x.tennis === false)) 
            );
            setData(tmp);
        }
    }, [ospiti, camere, bagni, piscina, mare, tennis])


    function getImage(villa) {
        return villa.gallerias.length > 0 ? "https://ikhbackend.evinapp.it/Files/Images/" + villa.gallerias[0].immagine : "https://ikhbackend.evinapp.it/Files/Images/22_011.jpg";
    }

    function getPrezzoDa(villa) {
        return villa.prezzoDa === 1.00 ? "Quotazione su richiesta" : "A partire da " + formatPrice(villa.prezzoDa,"EUR" , cultureName)  + " a settimana";
    }

    function convertBoolean(value) {
        return value === "0" ? false : true;
    }


    function onClickVilla(e) {
        let id = e.currentTarget.id;

        window.location.href = '/HomeVillaDettaglio/?villa=' + id;
    }


    return (
        <>
            <div style={{ display: "table", width: "100%" }} >
                <div style={{ display: "table-row", backgroundImage: `url("https://ikh.villas/wp-content/uploads/2022/03/shutterstock_717580867.jpg.webp")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "450px" }} >
                    <div style={{ display: "table-cell", height: "450px", verticalAlign: "middle" }}>
                        <div className="text-white text-center" style={{ fontSize: "3rem", lineHeight: "3.5rem" }}> <br /> <b style={{ fontSize: "3.5rem" }} ></b></div>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row" style={{ marginTop: "60px", marginBottom: "60px" }} >
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 h1">
                        <div>{title }</div>

                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 h6" style={{ lineHeight: "1.5rem" }} >
                        <span style={{ fontWeight: "800" }}>
                            {subtitle}
                        </span>
                    </div>
                </div>

                <div className="row">
                    {/* filters */}
                    <div class="row collapse show" style={{ marginBottom: "1rem" }} id="filterRow">
                        <div class="col-lg-2 col-md-4 col-12 bookparameter-mob">
                            <span class="parameterdefinput withlabel">
                                <label class="labelFilter">Ospiti</label>
                                <select className="form-select border-0 border-bottom" aria-label="Fornitore" onChange={e => setOspiti(e.currentTarget.value)}>
                                    <option value="0"></option>
                                    {Array.from(Array(12), (e, i) =>
                                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                                    )}
                                </select>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-12 bookparameter-mob">
                            <span class="parameterdefinput withlabel">
                                <label class="labelFilter">Camere da letto</label>
                                <select className="form-select border-0 border-bottom" aria-label="Fornitore" onChange={e => setCamere(e.currentTarget.value)}>
                                    <option value="0"></option>
                                    {Array.from(Array(12), (e, i) =>
                                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                                    )}
                                </select>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-12 bookparameter-mob">
                            <span class="parameterdefinput withlabel">
                                <label class="labelFilter">Bagni</label>
                                <select className="form-select border-0 border-bottom" aria-label="Fornitore" onChange={e => setBagni(e.currentTarget.value)}>
                                    <option value="0"></option>
                                    {Array.from(Array(12), (e, i) =>
                                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                                    )}
                                </select>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-12 bookparameter-mob">
                            <span class="parameterdefinput withlabel">
                                <label class="labelFilter">Piscina</label>
                                <select className="form-select border-0 border-bottom" aria-label="Fornitore" onChange={e => setPiscina(e.currentTarget.value)}>
                                    <option value=""></option>
                                    <option key={1} value={1}>Si</option>
                                    <option key={0} value={0}>No</option>
                                </select>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-12 bookparameter-mob">
                            <span class="parameterdefinput withlabel">
                                <label class="labelFilter">Vista Mare</label>
                                <select className="form-select border-0 border-bottom" aria-label="Fornitore" onChange={e => setMare(e.currentTarget.value)}>
                                    <option value=""></option>
                                    <option key={1} value={1}>Si</option>
                                    <option key={0} value={0}>No</option>
                                </select>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-12 bookparameter-mob">
                            <span class="parameterdefinput withlabel">
                                <label class="labelFilter">Tennis</label>
                                <select className="form-select border-0 border-bottom" aria-label="Fornitore" onChange={e => setTennis(e.currentTarget.value)}>
                                    <option value=""></option>
                                    <option key={1} value={1}>Si</option>
                                    <option key={0} value={0}>No</option>
                                </select>
                            </span>
                        </div>
                    </div>

                    {/* ville */ }
                    {data.length > 0 && data.map(villa =>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 zoom-box text-center cursor-pointer mb-4">
                            <div className="card box-villa" id={villa.codice} onClick={(e) => onClickVilla(e) }>
                                <div>
                                    <div style={{ backgroundImage: `url("${getImage(villa)}")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "258px" }} ></div>
                                </div>
                                <h5 className="mt-2 mb-2">{villa.nome.replace("[GOING]", "").replace("(GOING)", "")}</h5>
                                <div className="d-flex" style={{ justifyContent: "center"}}>
                                    <p className="inline mb-1 p-1"><span className="material-icons mr-1" style={{fontSize: "15px"}}>share_location</span>
                                        {villa.citta}
                                    </p>
                                    <p className="inline mb-1 p-1"><span className="material-icons mr-1" style={{ fontSize: "15px" }}>villa</span>
                                        {villa.descCategoria}
                                    </p>
                                    <p className="inline mb-1 p-1"><span className="material-icons mr-1" style={{ fontSize: "15px" }}>people</span>
                                        {villa.nOspiti}
                                    </p>
                                </div>
                                <div className="box-villa-note">
                                    <label>{getPrezzoDa(villa)}</label>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="h6">
                Le migliori proprietà selezionate per te.
            </div>
            <div className="h6 mb-5">
                Sia che il tuo stile preferito sia un'autentica finca con carattere e fascino o una villa di design chic e moderna con piscina, sia che il tuo soggiorno sia una vacanza in famiglia, un viaggio rinfrescante con gli amici, una vacanza all'insegna del golf, un ritiro, il tuo matrimonio luna di miele, un evento aziendale o una grande festa di famiglia troveremo la proprietà perfetta per soddisfare ogni tua esigenza e desiderio.
            </div>
        </>
    );
}